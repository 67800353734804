import React, {Component} from 'react';
import useModal from '../../components/useModal';
import styled from 'styled-components';

const BumperText = styled.div`
    font-size: 24px;
    text-align: right;
`;

const About = React.memo(() => {
    const {show, RenderModal} = useModal();
    return (
        <div className="pageWrap">
            <RenderModal></RenderModal>
            <div className="section" id="aboutus-intro">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-7">
                            <h1>About us</h1>
                            <p>
                                Reactive Betting Ltd. was created by a brilliant team of sports-enthusiastic software
                                developers, which has been successfully developing betting software for well-known
                                sports betting companies for more than 20 years.
                            </p>
                            <p>Reactive Betting has expertise in design, development and operations of</p>
                            <ul>
                                <li>
                                    <span>high performance &amp; high scalability transactional systems</span>
                                </li>
                                <li>
                                    <span>big data preparation &amp; analysis</span>
                                </li>
                                <li>
                                    <span>mobile first B2C web based GUIs with a strong focus on UX</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-xs-1" />
                        <div className="col-xs-4">
                            <div className="logoWrap">
                                <div className="rbpLogo" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section" id="aboutus-mission">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-7">
                            <h1>Mission &amp; Vision</h1>
                            <p>Now we bring our experience to the next level as a full solution provider.</p>
                            <p>
                                We've been working on a full stack Reactive Betting Platform with multiple teams of
                                engineers.
                            </p>
                            <p>
                                Our own agile project management approach ensures fast release cycles with high quality
                                standards.
                            </p>
                            <p>Our goal is nothing less than providing the best iGaming Platform in the industry.</p>
                        </div>
                        <div className="col-xs-5" />
                    </div>
                </div>
            </div>
            <div className="section" id="aboutus-team">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-4">
                            <div className="teamImage left" />
                        </div>
                        <div className="col-xs-4">
                            <div className="teamText">
                                <h1>Passion. Experience. Fun.</h1>
                                <p>
                                    We are 35 highly skilled, loyal and open minded people, with a very low fluctuation.
                                </p>
                                <p>Together we have more than 150 years of full time experience in the industry.</p>
                                <p>
                                    Our team consists of product designers, architects, developers, mathematicians,
                                    statisticians, QAs and DevOps.
                                </p>
                            </div>
                        </div>
                        <div className="col-xs-4">
                            <div className="teamImage right" />
                        </div>
                    </div>
                </div>
            </div>{' '}
            <div className="section" id="contact">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-7">
                            <BumperText>Need more information? Get in touch with us!</BumperText>
                        </div>
                        <div className="col-xs-5">
                            <div className="btnWrapper">
                                <button onClick={show}>Get In Touch</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default About;
