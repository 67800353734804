import React, { Component } from 'react';

class Service extends Component {
  render() {
    return (
        <div className="pageWrap">

            <div className="section" id="service-dataAnalysis">
                <div className="content">
                    <div className="row">
                    <div className="col-xs-4">
                        <h1>Network Access & Business Planning</h1>
                          <ul>
                              <li><span>Get access to our network of marketing agencies, lawyers, PSPs and 3rd party vendors</span></li>
                              <li><span>Let us help you to plan your operations, licencing or marketing</span></li>
                          </ul>
                    </div>
                        <div className="col-xs-4">
                            <h1>Customisation, Development & Operations</h1>
                            <ul>
                                <li><span>Of course we support you to set up anything you need technically.</span></li>
                                <li><span>We'll love to extend our product to your needs and you'll constantly benefit
                                from further development.</span></li>
                                <li><span>We will manage your site completely and protect you against DDOS and other attacks
                                with our partners (Cloudflare)</span></li>
                            </ul>
                        </div>
                        <div className="col-xs-4">
                            <h1>Data Analysis & Reporting</h1>
                              <ul>
                                  <li><span>Data-driven risk management optimisations</span></li>
                                  <li><span>Data-driven customer segmentation</span></li>
                              </ul>
                        </div>
                    </div>
                </div>
            </div>
      </div>
    );
  }
}

export default Service;
