import React, { Component } from 'react';

class About extends Component {
  render() {
    return (
        <div className="pageWrap">

            <div className="section" id="customisation-start">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-4"></div>
                        <div className="col-xs-4 textCenter">
                            <div className="imgWrap">
                                <div className="cmsLogoImg"></div>
                            </div>
                            <p>MAKE IT YOURS!</p>
                        </div>
                        <div className="col-xs-4"></div>
                    </div>
                </div>
            </div>

            <div className="section" id="customisation-philosophy">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-5">
                            <h1>Philosophy</h1>
                              <ul>
                                  <li><span>Direct source code manipulation like a developer</span></li>
                                  <li><span>No database</span></li>
                                  <li><span>Branch management (git backed) for parallel modifications and reviews</span></li>
                                  <li><span>No information loss or inconsistencies</span></li>
                                  <li><span>Guardian approach</span></li>
                              </ul>
                        </div>
                        <div className="col-xs-2">

                        </div>
                        <div className="col-xs-5">
                            <h1>Features</h1>
                            <ul>
                                <li><span>Switch to content editing mode whenever you want</span></li>
                                <li><span>Advanced live editing and customizing of <b>every</b> content
                                within the browser</span></li>
                                <li><span>Bulk editor for all I18N data</span></li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>

            <div className="section" id="customisation-onsiteEditing">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-7">
                            <div className="imgWrap">
                                <div className="onsiteEditingImg"></div>
                            </div>
                        </div>
                        <div className="col-xs-1"></div>
                        <div className="col-xs-4">
                            <div className="vAlign">
                                <h1>Onsite Editing</h1>
                                <p>The content management system (CMS) is integrated into the web client
                                in a way that let you switch between customer use cases and content editing
                                as seamless as possible. The CMS works as an overlay upon the client application.
                                Content is edited in-place.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" id="customisation-combineContent">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-4">
                            <div className="vAlign">
                                <h1>Content management ON version control</h1>
                                <p>The content management system is based directly on the same source code
                                management system used for development, QA and continuous delivery. So versioning
                                your campaign content and create translations for new software features works
                                hand in hand. The same process can be applied to every kind of content change.
                                Branches and merges allow parallelizing content editing with a 4-eyes review process
                                before it goes live.</p>
                            </div>
                        </div>
                        <div className="col-xs-1"></div>
                        <div className="col-xs-7">
                            <div className="imgWrap">
                                <div className="combineContentImg"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" id="customisation-manageTranslations">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-7">
                            <div className="imgWrap">
                                <div className="manageTranslationsImg"></div>
                            </div>
                        </div>
                        <div className="col-xs-1"></div>
                        <div className="col-xs-4">
                            <div className="vAlign">
                                <h1>Manage translations</h1>
                                <p>Organize your translation process with your translation agencies via CON|VERSION.
                                Your are always informed about the progress and use a 4-eye-principle for
                                reviewing. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" id="customisation-contextSensitive">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-4">
                            <div className="vAlign">
                                <h1>Manage translators</h1>
                                <p>Use the user management system to give fine grained access to I18N ressources.
                                Translators working hand-in-hand with developers. Each party can focus on their
                                own "job," easy multi language support.</p>
                            </div>
                        </div>
                        <div className="col-xs-1"></div>
                        <div className="col-xs-7">
                            <div className="imgWrap">
                                <div className="contextSensitiveImg"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" id="customisation-translationStatistics">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-7">
                            <div className="imgWrap">
                                <div className="translationStatisticsImg"></div>
                            </div>
                        </div>
                        <div className="col-xs-1"></div>
                        <div className="col-xs-4">
                            <div className="vAlign">
                                <h1>Translations statistics</h1>
                                <p>The underlying version control system is used to create
                                 detailed user based translation statistics.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" id="customisation-agileCampaign">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-4">
                            <div className="vAlign">
                                <h1>Agile Campaign Management</h1>
                                <p>If you know your customer, the CMS will know your customer, too.
                                If you learn something new about your customer the CMS can easily handle
                                these facts. Present user specific campaign and promotion content.</p>
                            </div>
                        </div>
                        <div className="col-xs-1"></div>
                        <div className="col-xs-7">
                            <div className="imgWrap">
                                <div className="agileCampaignImg"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" id="customisation-userManagement">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-7">
                            <div className="imgWrap">
                                <div className="userManagementImg"></div>
                            </div>
                        </div>
                        <div className="col-xs-1"></div>
                        <div className="col-xs-4">
                            <div className="vAlign">
                                <h1>Role based user management</h1>
                                One tool for the whole I18N process.
                                <ul>
                                <li><span> Product Manager</span></li>
                                <li><span> Editor</span></li>
                                <li><span> Reviewer</span></li>
                                <li><span> Translator</span></li>
                                <li><span> Developer</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" id="customisation-offSiteCom">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-6">
                            <div className="imgWrap">
                                <div className="channelImg"></div>
                            </div>
                        </div>
                        <div className="col-xs-1"></div>
                        <div className="col-xs-5">
                            <h1>Off-site communication</h1>
                              <ul>
                                  <li><span>Omni-channels: Mail, SMS, Push, Facebook, Twitter, WhatsApp</span></li>
                                  <li><span>CMS based template creation</span></li>
                                  <li><span>Customer personalization with placeholders</span></li>
                                  <li><span>From simple HTML templates to "mail applications" containing "complex" components (matchlists, betslip editor)</span></li>
                                  <li><span>Browser view mode</span></li>
                                  <li><span>Connected SMS and mail templates -> point customer to "long" web version in SMS</span></li>
                              </ul>
                        </div>
                    </div>
                </div>
            </div>

      </div>
    );
  }
}

export default About;
