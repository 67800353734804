import React, {Component} from 'react';
import styled from 'styled-components';
import useModal from '../../components/useModal';
import ReactPlayer from 'react-player';

import MatchListVideo from '../../assets/videos/rbp-website_2018_matchList.mp4';
import NavigatorVideo from '../../assets/videos/Navigator.mp4';
import MatrixVideo from '../../assets/videos/rbp-website_2018_matrix.mp4';
import MatchDetailInfo from '../../assets/videos/rbp-website_2018_stats.mp4';
import BetslipVideo from '../../assets/videos/rbp-website_2018_betslip.mp4';
import Search from '../../assets/videos/rbp-website_2018_search.mp4';

import graphOne from '../../assets/Graph_1.svg';
import graphTwo from '../../assets/Graph_2.svg';
import graphThree from '../../assets/graphNew_1.svg';
import graphFour from '../../assets/graphNew_2.svg';

const BumperText = styled.div`
    font-size: 24px;
    text-align: right;
`;

const Sportbook = React.memo(() => {
    const {show} = useModal();
    return (
        <div className="pageWrap">
            <div className="section" id="sportbook-navigator">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-1" />
                        <div className="col-xs-3">
                            <div className="videoWrap">
                                {/* https://github.com/CookPete/react-player */}
                                <ReactPlayer
                                    url={NavigatorVideo}
                                    muted={true}
                                    playing={true}
                                    loop={true}
                                    controls={false}
                                    width={'100%'}
                                    height={'100%'}
                                    // ...
                                />
                            </div>
                        </div>
                        <div className="col-xs-1" />
                        <div className="col-xs-6">
                            <div className="vAlign">
                                <h1>Intelligent Sports Navigation</h1>
                                <ul>
                                    <li>
                                        <span>dynamic in-depth navigation to avoid unnecessary clicks</span>
                                    </li>
                                    <li>
                                        <span>sorted by the most popular bets per country</span>
                                    </li>
                                    <li>
                                        <span>most commonly used filters are pre-defined</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xs-1" />
                    </div>
                </div>
            </div>
            <div className="section" id="sportbook-matchlist">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-6">
                            <div className="vAlign">
                                <h1>Advanced Match Overview</h1>
                                <ul>
                                    <li>
                                        <span>all major betmarkets available directly</span>
                                    </li>
                                    <li>
                                        <span>dedicated to the most balanced / popular bets</span>
                                    </li>
                                    <li>
                                        <span>device dependent navigation via swiping or scrolling</span>
                                    </li>
                                    <li>
                                        <span>user defined watchlist and favorites</span>
                                    </li>
                                    <li>
                                        <span>immediate updates of all match details</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xs-1" />
                        <div className="col-xs-3">
                            <div className="videoWrap">
                                <ReactPlayer
                                    url={MatchListVideo}
                                    muted={true}
                                    playing={true}
                                    loop={true}
                                    controls={false}
                                    width={'100%'}
                                    height={'100%'}
                                />
                            </div>
                        </div>
                        <div className="col-xs-1" />
                    </div>
                </div>
            </div>

            <div className="section" id="sportbook-search">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-2" />
                        <div className="col-xs-6">
                            <div className="vAlign">
                                <h1>Search</h1>
                                <ul>
                                    <li>
                                        <span>completely indexed leagues, teams &amp; players</span>
                                    </li>
                                    <li>
                                        <span>instant search results</span>
                                    </li>
                                    <li>
                                        <span>extensible to index 3rd party products (casino, virtual sports)</span>
                                    </li>
                                    <li>
                                        <span>powered by Apache Lucene</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xs-3">
                            <div className="videoWrap">
                                <ReactPlayer
                                    url={Search}
                                    muted={true}
                                    playing={true}
                                    loop={true}
                                    controls={false}
                                    width={'100%'}
                                    height={'100%'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" id="sportbook-betmatrix">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-6">
                            <div className="vAlign">
                                <h1>Live Action and Statistics</h1>
                                <ul>
                                    <li>
                                        <span>overview of all important match details</span>
                                    </li>
                                    <li>
                                        <span>league tables &amp; statistics</span>
                                    </li>
                                    <li>
                                        <span>detailed list of match history</span>
                                    </li>
                                    <li>
                                        <span>TV channel info</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xs-1" />
                        <div className="col-xs-3">
                            <div className="videoWrap">
                                <ReactPlayer
                                    url={MatchDetailInfo}
                                    muted={true}
                                    playing={true}
                                    loop={true}
                                    controls={false}
                                    width={'100%'}
                                    height={'100%'}
                                />
                            </div>
                        </div>
                        <div className="col-xs-1" />
                    </div>
                </div>
            </div>

            <div className="section" id="sportbook-betmatrix">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-1" />
                        <div className="col-xs-3">
                            <div className="videoWrap">
                                <ReactPlayer
                                    url={MatrixVideo}
                                    muted={true}
                                    playing={true}
                                    loop={true}
                                    controls={false}
                                    width={'100%'}
                                    height={'100%'}
                                />
                            </div>
                        </div>
                        <div className="col-xs-1" />
                        <div className="col-xs-6">
                            <div className="vAlign">
                                <h1>Bet Matrix</h1>
                                <ul>
                                    <li>
                                        <span>unique in betting industry</span>
                                    </li>
                                    <li>
                                        <span>live visualization of betmarket dependencies</span>
                                    </li>
                                    <li>
                                        <span>
                                            help the customer to understand complicated betting types like handicap
                                        </span>
                                    </li>
                                    <li>
                                        <span>powerful feature in combination with personal bet</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xs-1" />
                    </div>
                </div>
            </div>

            <div className="section" id="sportbook-betslip">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-1" />
                        <div className="col-xs-6">
                            <div className="innerRow">
                                <h1>Betslip</h1>
                                <ul>
                                    <li>
                                        <span>LIVE - Always up-to-date</span>
                                    </li>
                                    <li>
                                        <span>No more disturbing error messages while delivering bets</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="innerRow">
                                <h2>OneClickBet</h2>
                                <ul>
                                    <li>
                                        <span>One click bet placement</span>
                                    </li>
                                    <li>
                                        <span>
                                            AI-powered learning tool analyses customer’s three most used types of stakes
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="innerRow">
                                <h2>Personal Bet</h2>
                                <ul>
                                    <li>
                                        <span>customised odds combining multiple outcomes of a single match</span>
                                    </li>
                                    <li>
                                        <span>
                                            allows for arbitrary "and"/"or" combinations of football goal odds (e.g.
                                            "Both Teams To Score - Yes" <i>or</i> "Dortmund to win")
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xs-3">
                            <div className="videoWrap">
                                <ReactPlayer
                                    url={BetslipVideo}
                                    muted={true}
                                    playing={true}
                                    loop={true}
                                    controls={false}
                                    width={'100%'}
                                    height={'100%'}
                                />
                            </div>
                        </div>
                        <div className="col-xs-1" />
                    </div>
                </div>
            </div>

            <div className="section" id="sportbook-liveCashout">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-1" />
                        <div className="col-xs-5">
                            <div className="vAlign">
                                <h1>Live Cashout</h1>
                                <ul>
                                    <li>
                                        <span>increase the speed of buy-ins by providing high availability</span>
                                    </li>
                                    <li>
                                        <span>customer-defined bounds for "safe win" or "stop loss" functionality</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xs-1" />
                        <div className="col-xs-3">
                            <div className="imgWrap">
                                <div className="ownBetsList" />
                            </div>
                        </div>
                        <div className="col-xs-1" />
                    </div>
                    <div className="row">
                        <div className="col-xs-1" />
                        <div className="col-xs-5">
                            <div className="vAlign">
                                <h2>Live cashout value on game progression</h2>
                                <p>
                                    Inspired by financial trading platforms, customers can see their cash-out values
                                    move and see those events that influence the process (goals, red cards, etc).
                                </p>
                            </div>
                        </div>
                        <div className="col-xs-1" />
                        <div className="col-xs-3">
                            <div className="imgWrap">
                                <div className="ownBetsDetail" />
                            </div>
                        </div>
                        <div className="col-xs-1" />
                    </div>

                    <div className="row">
                        <div className="col-xs-1" />
                        <div className="col-xs-5">
                            <div className="vAlign">
                                <h2>Partial cash-out &amp; limits</h2>
                                <ul>
                                    <li>
                                        <span>partial bet amount cash-out</span>
                                    </li>
                                    <li>
                                        <span>
                                            customer-defined limit at which they will be notified when the cashout
                                            reaches the value
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            stop-loss or a safe-win limit when the bet should be automatically cashed
                                            out
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xs-1" />
                        <div className="col-xs-3">
                            <div className="imgWrap">
                                <div className="partialCashout" />
                            </div>
                        </div>
                        <div className="col-xs-1" />
                    </div>
                </div>
            </div>

            <div className="section" id="sportbook-marketAnalytics">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-7">
                            <div className="vAlign">
                                <h1>Market Analytics - Screening &amp; Comparison</h1>
                                <p>
                                    Apart from our main feed from Sporting Solutions several more feeds are imported and
                                    mapped. We also use the following:
                                </p>
                                <ul>
                                    <li>
                                        <span>odds provided by TXOdds &amp; Betbrain</span>
                                    </li>
                                    <li>
                                        <span>odds by Bet365, 888Sports, Betfair, etc</span>
                                    </li>
                                </ul>
                                <p> </p>
                                <h2>Our own heuristic odds models</h2>
                                <ol>
                                    <li>
                                        <span>a football model complete for goal-based odds</span>
                                    </li>
                                    <ul>
                                        <li>
                                            <span>correlated draw-inflated Poisson-Skellam model</span>
                                        </li>
                                        <li>
                                            <span>key variables (totals, supremacy, draw/first half bias)</span>
                                        </li>
                                        <li>
                                            <span>risk/ betting behaviour</span>
                                        </li>
                                    </ul>
                                    <li>
                                        <span>sample ice-hockey model</span>
                                    </li>
                                    <li>
                                        <span>outrights models (conceptualized)</span>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div className="col-xs-5">
                            <div className="imgWrap vAlign">
                                <div className="globeImg" />
                            </div>
                        </div>
                        {/* <div className="col-xs-1" /> */}
                    </div>
                </div>
            </div>

            <div className="section" id="sportbook-oddsPipeline">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-6">
                            <div className="imgWrap vAlign">
                                <svg
                                    version="1.1"
                                    id="Ebene_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 835.4 483.8">
                                    <g>
                                        <polygon
                                            fill="#FFCB0A"
                                            points="539.5,124.1 588.2,208.5 539.5,292.8 296.9,292.8 248.2,208.5 296.9,124.1 	"
                                        />
                                        <polygon
                                            fill="#06C1E5"
                                            points="539.5,315.1 588.2,399.5 539.5,483.8 296.9,483.8 248.2,399.5 296.9,315.1 	"
                                        />
                                        <polygon
                                            fill="#98CA3C"
                                            points="791.8,227.9 835.4,303.5 791.8,379 604.6,379 561,303.5 604.6,227.9 	"
                                        />
                                        <polygon
                                            fill="#EC1B5E"
                                            points="230.8,227.9 274.4,303.5 230.8,379 43.6,379 0,303.5 43.6,227.9 	"
                                        />

                                        <ellipse
                                            id="ellipse"
                                            fill="none"
                                            stroke="#414141"
                                            cx="422.5"
                                            cy="327.8"
                                            rx="226.4"
                                            ry="71.7"
                                        />
                                        <path
                                            id="pathOne"
                                            fill="none"
                                            stroke="#414141"
                                            d="M341,157.2
                              c-80.1,0-145,53.1-145,118.7c0,5.7,0.5,11.8,1.5,16.8"
                                        />
                                        <g>
                                            <rect x="452" y="369.2" fill="#FFFFFF" width="102" height="51" />
                                        </g>
                                        <g>
                                            <path
                                                fill="#FFFFFF"
                                                d="M364.9,198.2c4.8,0,8.6,3.9,8.6,8.7c0,4.8-3.8,8.6-8.6,8.6c-4.8,0-8.6-3.8-8.6-8.6
                                  C356.3,202,360.1,198.2,364.9,198.2z M364.9,212.3c3,0,5.5-2.5,5.5-5.5c0-3-2.5-5.5-5.5-5.5s-5.5,2.5-5.5,5.5
                                  C359.4,209.9,361.9,212.3,364.9,212.3z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M379.7,205.4c1,0,1.7,0.3,2.1,0.4v-6.9c0-0.2,0.2-0.5,0.5-0.5h2c0.2,0,0.5,0.2,0.5,0.5v15.9
                                  c0,0.2-0.2,0.5-0.5,0.5h-0.9c-0.2,0-0.4-0.2-0.5-0.5l-0.2-0.7c0,0-1.1,1.4-3.1,1.4c-2.6,0-4.6-2.3-4.6-5
                                  C374.9,207.6,376.8,205.4,379.7,205.4z M379.9,212.9c1.3,0,1.8-1,2-1.6v-2.8c0,0-0.7-0.6-1.9-0.6c-1.3,0-2.3,1.1-2.3,2.4
                                  S378.6,212.9,379.9,212.9z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M391.7,205.4c1,0,1.7,0.3,2.1,0.4v-6.9c0-0.2,0.2-0.5,0.5-0.5h2c0.2,0,0.5,0.2,0.5,0.5v15.9
                                  c0,0.2-0.2,0.5-0.5,0.5h-0.9c-0.2,0-0.4-0.2-0.5-0.5l-0.2-0.7c0,0-1.1,1.4-3.1,1.4c-2.6,0-4.6-2.3-4.6-5
                                  C386.9,207.6,388.8,205.4,391.7,205.4z M391.9,212.9c1.3,0,1.8-1,2-1.6v-2.8c0,0-0.7-0.6-1.9-0.6c-1.3,0-2.3,1.1-2.3,2.4
                                  S390.6,212.9,391.9,212.9z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M399.1,214.1l0.6-1.3c0.1-0.2,0.3-0.3,0.6-0.2c0,0,1.1,0.6,2.2,0.6c0.5,0,0.8-0.2,0.8-0.6
                                  c0-0.4-0.3-0.7-1.6-1.2c-1.8-0.7-2.7-1.7-2.7-3.2c0-1.5,1.1-2.8,3.5-2.8c1.4,0,2.4,0.4,3,0.7c0.2,0.1,0.3,0.4,0.2,0.6l-0.6,1.2
                                  c-0.1,0.2-0.4,0.2-0.6,0.2c0,0-1.1-0.5-2-0.5c-0.6,0-0.8,0.2-0.8,0.6c0,0.4,0.4,0.6,1.3,0.9c1.8,0.7,3.2,1.5,3.2,3.4
                                  c0,1.6-1.4,2.9-3.8,2.9c-1.6,0-2.7-0.5-3.2-0.9C399.1,214.4,399,214.2,399.1,214.1z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M416.2,198.5c0-0.2,0.2-0.4,0.4-0.4h0.4c0.2,0,0.3,0.1,0.4,0.2l5.3,11.3c0,0,0,0,0.1,0l5.3-11.3
                                  c0.1-0.1,0.2-0.2,0.4-0.2h0.4c0.3,0,0.4,0.2,0.4,0.4l2.8,16.1c0.1,0.3-0.1,0.6-0.4,0.6h-2.2c-0.2,0-0.4-0.2-0.5-0.4l-1.4-9.1
                                  c0,0-0.1,0-0.1,0l-4.2,9.4c0,0.1-0.2,0.3-0.4,0.3h-0.4c-0.2,0-0.3-0.1-0.4-0.3l-4.2-9.4c0,0,0,0-0.1,0l-1.4,9.1
                                  c0,0.2-0.2,0.4-0.4,0.4h-2.2c-0.3,0-0.5-0.2-0.5-0.6L416.2,198.5z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M438.5,205.4c2.7,0,4.9,2.3,4.9,5c0,2.8-2.2,5.1-4.9,5.1c-2.7,0-4.9-2.3-4.9-5.1
                                  C433.6,207.7,435.8,205.4,438.5,205.4z M438.5,212.9c1.3,0,2.3-1.1,2.3-2.5c0-1.3-1-2.4-2.3-2.4c-1.3,0-2.3,1.1-2.3,2.4
                                  C436.2,211.7,437.2,212.9,438.5,212.9z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M449.6,205.4c1,0,1.7,0.3,2.1,0.4v-6.9c0-0.2,0.2-0.5,0.5-0.5h2c0.2,0,0.5,0.2,0.5,0.5v15.9
                                  c0,0.2-0.2,0.5-0.5,0.5h-0.9c-0.2,0-0.4-0.2-0.5-0.5l-0.2-0.7c0,0-1.1,1.4-3.1,1.4c-2.6,0-4.6-2.3-4.6-5
                                  C444.8,207.6,446.7,205.4,449.6,205.4z M449.8,212.9c1.3,0,1.8-1,2-1.6v-2.8c0,0-0.7-0.6-1.9-0.6c-1.3,0-2.3,1.1-2.3,2.4
                                  S448.5,212.9,449.8,212.9z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M461.7,205.4c2.5,0,4.5,1.9,4.5,4.5c0,0.2,0,0.5,0,0.6c0,0.2-0.2,0.4-0.5,0.4h-6.1c0.1,1,0.9,2.1,2.3,2.1
                                  c0.7,0,1.4-0.3,1.9-0.6c0.2-0.1,0.5-0.2,0.6,0l1,1.1c0.2,0.2,0.2,0.4,0,0.6c-0.8,0.7-2,1.3-3.6,1.3c-2.9,0-4.9-2.3-4.9-5
                                  C456.8,207.7,458.9,205.4,461.7,205.4z M463.4,209.2c-0.1-0.9-0.8-1.6-1.8-1.6c-1,0-1.8,0.7-1.9,1.6H463.4z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M468.2,198.9c0-0.2,0.2-0.5,0.5-0.5h2c0.2,0,0.5,0.2,0.5,0.5v15.9c0,0.2-0.2,0.5-0.5,0.5h-2
                                  c-0.2,0-0.5-0.2-0.5-0.5V198.9z"
                                            />
                                        </g>
                                        <g>
                                            <path
                                                fill="#FFFFFF"
                                                d="M329.9,449c0-0.2,0.2-0.5,0.5-0.5h6.9c2.9,0,5.2,2.3,5.2,5.2c0,2.2-1.5,4-3.6,4.8l3.3,6.1
                                  c0.2,0.3,0,0.7-0.4,0.7h-2.5c-0.2,0-0.3-0.1-0.4-0.2l-3.2-6.4H333v6.1c0,0.2-0.2,0.5-0.5,0.5h-2.2c-0.3,0-0.5-0.2-0.5-0.5V449z
                                   M337,456.2c1.3,0,2.4-1.1,2.4-2.4c0-1.3-1.1-2.3-2.4-2.3h-3.9v4.8H337z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M344.4,450.7c0-0.9,0.7-1.7,1.6-1.7c0.9,0,1.7,0.7,1.7,1.7s-0.7,1.7-1.7,1.7
                                  C345.1,452.3,344.4,451.6,344.4,450.7z M344.6,456.2c0-0.3,0.2-0.5,0.5-0.5h2c0.2,0,0.5,0.2,0.5,0.5v8.7c0,0.2-0.2,0.5-0.5,0.5h-2
                                  c-0.2,0-0.5-0.2-0.5-0.5V456.2z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M349.9,464.2l0.6-1.3c0.1-0.2,0.3-0.3,0.6-0.2c0,0,1.1,0.6,2.2,0.6c0.5,0,0.8-0.2,0.8-0.6
                                  c0-0.4-0.3-0.7-1.6-1.2c-1.8-0.7-2.7-1.7-2.7-3.2c0-1.5,1.1-2.8,3.5-2.8c1.4,0,2.4,0.4,3,0.7c0.2,0.1,0.3,0.4,0.2,0.6l-0.6,1.2
                                  c-0.1,0.2-0.4,0.2-0.6,0.2c0,0-1.1-0.5-2-0.5c-0.6,0-0.8,0.2-0.8,0.6c0,0.4,0.4,0.6,1.3,0.9c1.8,0.7,3.2,1.5,3.2,3.4
                                  c0,1.6-1.4,2.9-3.8,2.9c-1.6,0-2.7-0.5-3.2-0.9C349.9,464.6,349.8,464.4,349.9,464.2z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M359.1,449c0-0.2,0.2-0.5,0.5-0.5h2c0.2,0,0.5,0.2,0.5,0.5v10.4l3.3-3.4c0.2-0.1,0.3-0.3,0.6-0.3h2.2
                                  c0.4,0,0.6,0.4,0.3,0.7l-3.6,3.6l4.3,4.6c0.2,0.3,0.1,0.7-0.3,0.7h-2.6c-0.1,0-0.3-0.1-0.4-0.2l-3.8-4.3v4.1
                                  c0,0.2-0.2,0.5-0.5,0.5h-2c-0.2,0-0.5-0.2-0.5-0.5V449z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M378.7,448.7c0-0.2,0.2-0.4,0.4-0.4h0.4c0.2,0,0.3,0.1,0.4,0.2l5.3,11.3c0,0,0,0,0.1,0l5.3-11.3
                                  c0.1-0.1,0.2-0.2,0.4-0.2h0.4c0.3,0,0.4,0.2,0.4,0.4l2.8,16.1c0.1,0.3-0.1,0.6-0.4,0.6H392c-0.2,0-0.4-0.2-0.5-0.4l-1.4-9.1
                                  c0,0-0.1,0-0.1,0l-4.2,9.4c0,0.1-0.2,0.3-0.4,0.3H385c-0.2,0-0.3-0.1-0.4-0.3l-4.2-9.4c0,0,0,0-0.1,0l-1.4,9.1
                                  c0,0.2-0.2,0.4-0.4,0.4h-2.2c-0.3,0-0.5-0.2-0.5-0.6L378.7,448.7z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M400,459.3c1,0,1.8,0.3,1.8,0.3c0-1.2-0.4-1.7-1.2-1.7c-0.9,0-2.1,0.2-2.7,0.4c-0.3,0.1-0.5-0.1-0.6-0.4
                                  l-0.2-1.2c-0.1-0.4,0.1-0.5,0.3-0.6c0.2-0.1,1.7-0.5,3.3-0.5c3,0,3.7,1.6,3.7,4.2v5.2c0,0.2-0.2,0.5-0.5,0.5H403
                                  c-0.2,0-0.3-0.1-0.4-0.3l-0.3-0.6c-0.6,0.5-1.4,1.2-2.9,1.2c-1.9,0-3.2-1.2-3.2-3.3C396.2,460.6,397.6,459.3,400,459.3z
                                   M400.1,463.5c0.8,0,1.5-0.6,1.7-0.9v-1.3c0,0-0.6-0.3-1.3-0.3c-0.9,0-1.6,0.5-1.6,1.3C398.8,463,399.3,463.5,400.1,463.5z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M407.1,456.2c0-0.3,0.2-0.5,0.5-0.5h0.9c0.2,0,0.3,0.1,0.4,0.3l0.3,0.9c0.4-0.4,1.5-1.4,3.4-1.4
                                  c3,0,4.3,2.3,4.3,4.8v4.6c0,0.2-0.2,0.5-0.5,0.5h-2c-0.3,0-0.5-0.2-0.5-0.5v-4.8c0-1.2-0.6-2-1.8-2c-1.2,0-2,0.9-2.3,1.5v5.3
                                  c0,0.3-0.1,0.5-0.6,0.5h-1.8c-0.2,0-0.5-0.2-0.5-0.5V456.2z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M422.7,459.3c1,0,1.8,0.3,1.8,0.3c0-1.2-0.4-1.7-1.2-1.7c-0.9,0-2.1,0.2-2.7,0.4c-0.3,0.1-0.5-0.1-0.6-0.4
                                  l-0.2-1.2c-0.1-0.4,0.1-0.5,0.3-0.6c0.2-0.1,1.7-0.5,3.3-0.5c3,0,3.7,1.6,3.7,4.2v5.2c0,0.2-0.2,0.5-0.5,0.5h-0.9
                                  c-0.2,0-0.3-0.1-0.4-0.3l-0.3-0.6c-0.6,0.5-1.4,1.2-2.9,1.2c-1.9,0-3.2-1.2-3.2-3.3C418.9,460.6,420.3,459.3,422.7,459.3z
                                   M422.8,463.5c0.8,0,1.5-0.6,1.7-0.9v-1.3c0,0-0.6-0.3-1.3-0.3c-0.9,0-1.6,0.5-1.6,1.3C421.5,463,422,463.5,422.8,463.5z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M430.6,461.9c0,0-0.7-0.8-0.7-2.3c0-2.2,1.8-4.2,4.2-4.2h4.4c0.3,0,0.5,0.2,0.5,0.5v0.8
                                  c0,0.2-0.1,0.4-0.3,0.4l-1.3,0.4c0,0,1,0.7,1,2.4c0,1.9-1.6,3.8-4.1,3.8c-0.9,0-1.5-0.2-1.9-0.2c-0.3,0-0.9,0.3-0.9,0.9
                                  c0,0.5,0.4,0.8,1,0.8h3.1c2.2,0,4,1.2,4,3.4c0,2.2-1.8,4.1-5.3,4.1c-3.6,0-4.9-1.8-4.9-3.3c0-1.4,1.2-2.1,1.4-2.3v-0.1
                                  c-0.4-0.1-1.8-0.8-1.8-2.5C428.8,462.8,430.6,461.9,430.6,461.9z M434.1,470.4c1.3,0,2.2-0.6,2.2-1.5c0-0.4-0.3-1.2-1.8-1.2
                                  c-0.5,0-1.1,0-1.6,0.1c-0.3,0.1-1,0.4-1,1.2C431.9,469.8,432.8,470.4,434.1,470.4z M434.1,461.5c1,0,1.7-0.7,1.7-1.7
                                  c0-1-0.7-1.7-1.7-1.7c-1,0-1.7,0.7-1.7,1.7C432.5,460.8,433.2,461.5,434.1,461.5z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M445.1,455.5c2.5,0,4.5,1.9,4.5,4.5c0,0.2,0,0.5,0,0.6c0,0.2-0.2,0.4-0.5,0.4h-6.1c0.1,1,0.9,2.1,2.3,2.1
                                  c0.7,0,1.4-0.3,1.9-0.6c0.2-0.1,0.5-0.2,0.6,0l1,1.1c0.2,0.2,0.2,0.4,0,0.6c-0.8,0.7-2,1.3-3.6,1.3c-2.9,0-4.9-2.3-4.9-5
                                  C440.2,457.8,442.2,455.5,445.1,455.5z M446.8,459.3c-0.1-0.9-0.8-1.6-1.8-1.6c-1,0-1.8,0.7-1.9,1.6H446.8z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M451.6,456.2c0-0.3,0.2-0.5,0.5-0.5h0.9c0.2,0,0.4,0.1,0.4,0.3l0.3,0.9c0.4-0.5,1.5-1.4,3.2-1.4
                                  c1.4,0,2.3,0.5,3.1,1.5c0.4-0.4,1.7-1.5,3.4-1.5c3.1,0,4.2,2.3,4.2,4.8v4.6c0,0.2-0.2,0.5-0.5,0.5h-1.9c-0.3,0-0.5-0.2-0.5-0.5
                                  v-4.8c0-1.2-0.6-2-1.8-2c-1.4,0-2,1-2,1s0,0.5,0,0.9v4.9c0,0.2-0.2,0.5-0.5,0.5h-1.8c-0.3,0-0.5-0.2-0.5-0.5v-4.8
                                  c0-1.2-0.6-2-1.8-2c-1.2,0-1.8,0.9-2,1.5v5.3c0,0.2-0.2,0.5-0.5,0.5h-1.9c-0.2,0-0.5-0.2-0.5-0.5V456.2z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M474.5,455.5c2.5,0,4.5,1.9,4.5,4.5c0,0.2,0,0.5,0,0.6c0,0.2-0.2,0.4-0.5,0.4h-6.1c0.1,1,0.9,2.1,2.3,2.1
                                  c0.7,0,1.4-0.3,1.9-0.6c0.2-0.1,0.5-0.2,0.6,0l1,1.1c0.2,0.2,0.2,0.4,0,0.6c-0.8,0.7-2,1.3-3.6,1.3c-2.9,0-4.9-2.3-4.9-5
                                  C469.6,457.8,471.6,455.5,474.5,455.5z M476.1,459.3c-0.1-0.9-0.8-1.6-1.8-1.6c-1,0-1.8,0.7-1.9,1.6H476.1z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M481,456.2c0-0.3,0.2-0.5,0.5-0.5h0.9c0.2,0,0.3,0.1,0.4,0.3l0.3,0.9c0.4-0.4,1.5-1.4,3.4-1.4
                                  c3,0,4.3,2.3,4.3,4.8v4.6c0,0.2-0.2,0.5-0.5,0.5h-2c-0.3,0-0.5-0.2-0.5-0.5v-4.8c0-1.2-0.6-2-1.8-2c-1.2,0-2,0.9-2.3,1.5v5.3
                                  c0,0.3-0.1,0.5-0.6,0.5h-1.8c-0.2,0-0.5-0.2-0.5-0.5V456.2z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M493.9,458.3H493c-0.2,0-0.4-0.2-0.4-0.5v-1.6c0-0.3,0.2-0.5,0.4-0.5h0.8v-2.7c0-0.2,0.2-0.5,0.5-0.5l2,0
                                  c0.2,0,0.4,0.2,0.4,0.5v2.7h2.1c0.3,0,0.5,0.2,0.5,0.5v1.6c0,0.2-0.2,0.5-0.5,0.5h-2.1v3.8c0,0.7,0.4,0.8,0.7,0.8
                                  c0.4,0,0.9-0.2,1.2-0.3c0.3-0.1,0.5,0,0.5,0.3l0.5,1.5c0.1,0.2,0,0.5-0.3,0.6c-0.1,0.1-1.7,0.6-2.9,0.6c-1.8,0-2.7-1.2-2.7-3.1
                                  V458.3z"
                                            />
                                        </g>
                                        <g>
                                            <path
                                                fill="#FFFFFF"
                                                d="M35.1,263.9c0-0.2,0.2-0.5,0.5-0.5h5.6c3,0,5.4,2.4,5.4,5.3c0,3-2.4,5.4-5.4,5.4h-3v5.6
                                  c0,0.2-0.2,0.5-0.5,0.5h-2.2c-0.3,0-0.5-0.2-0.5-0.5V263.9z M41.1,271.2c1.3,0,2.5-1.1,2.5-2.5c0-1.3-1.1-2.3-2.5-2.3h-2.8v4.8
                                  H41.1z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M48,271.1c0-0.3,0.2-0.5,0.5-0.5h0.9c0.2,0,0.4,0.1,0.4,0.3l0.3,0.9c0.3-0.5,1.2-1.5,2.7-1.5
                                  c1.1,0,2.1,0.4,1.8,1l-0.9,1.6c-0.1,0.2-0.4,0.3-0.6,0.2c-0.1,0-0.4-0.1-0.7-0.1c-1,0-1.5,0.6-1.7,1v5.8c0,0.3-0.2,0.5-0.6,0.5
                                  h-1.8c-0.2,0-0.5-0.2-0.5-0.5V271.1z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M60.2,270.4c2.7,0,4.9,2.3,4.9,5c0,2.8-2.2,5.1-4.9,5.1c-2.7,0-4.9-2.3-4.9-5.1
                                  C55.3,272.7,57.5,270.4,60.2,270.4z M60.2,277.9c1.3,0,2.3-1.1,2.3-2.5c0-1.3-1-2.4-2.3-2.4c-1.3,0-2.3,1.1-2.3,2.4
                                  C57.9,276.7,59,277.9,60.2,277.9z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M66,271.2c-0.1-0.3,0-0.6,0.3-0.6h2.1c0.2,0,0.4,0.1,0.4,0.3l1.9,4.5c0,0,0,0,0,0l2.1-4.5
                                  c0.1-0.2,0.3-0.3,0.6-0.3h1.9c0.4,0,0.5,0.3,0.3,0.6l-4.4,9c-0.1,0.1-0.2,0.2-0.4,0.2h-0.1c-0.2,0-0.3-0.1-0.4-0.2L66,271.2z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M77.1,265.5c0-0.9,0.7-1.7,1.6-1.7c0.9,0,1.7,0.7,1.7,1.7c0,0.9-0.7,1.7-1.7,1.7
                                  C77.8,267.2,77.1,266.5,77.1,265.5z M77.3,271.1c0-0.3,0.2-0.5,0.5-0.5h2c0.2,0,0.5,0.2,0.5,0.5v8.7c0,0.2-0.2,0.5-0.5,0.5h-2
                                  c-0.2,0-0.5-0.2-0.5-0.5V271.1z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M87.2,270.4c1,0,1.7,0.3,2.1,0.4v-6.9c0-0.2,0.2-0.5,0.5-0.5h2c0.2,0,0.5,0.2,0.5,0.5v15.9
                                  c0,0.2-0.2,0.5-0.5,0.5h-0.9c-0.2,0-0.4-0.2-0.5-0.5l-0.2-0.7c0,0-1.1,1.4-3.1,1.4c-2.6,0-4.6-2.3-4.6-5
                                  C82.4,272.6,84.3,270.4,87.2,270.4z M87.4,277.9c1.3,0,1.8-1,2-1.6v-2.8c0,0-0.7-0.6-1.9-0.6c-1.3,0-2.3,1.1-2.3,2.4
                                  C85.2,276.7,86.1,277.9,87.4,277.9z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M99.3,270.4c2.5,0,4.5,1.9,4.5,4.5c0,0.2,0,0.5,0,0.6c0,0.2-0.2,0.4-0.5,0.4h-6.1c0.1,1,0.9,2.1,2.3,2.1
                                  c0.7,0,1.4-0.3,1.9-0.6c0.2-0.1,0.5-0.2,0.6,0l1,1.1c0.2,0.2,0.2,0.4,0,0.6c-0.8,0.7-2,1.3-3.6,1.3c-2.9,0-4.9-2.3-4.9-5
                                  C94.4,272.7,96.4,270.4,99.3,270.4z M101,274.2c-0.1-0.9-0.8-1.6-1.8-1.6c-1,0-1.8,0.7-1.9,1.6H101z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M105.8,271.1c0-0.3,0.2-0.5,0.5-0.5h0.9c0.2,0,0.4,0.1,0.4,0.3l0.3,0.9c0.3-0.5,1.2-1.5,2.7-1.5
                                  c1.1,0,2.1,0.4,1.8,1l-0.9,1.6c-0.1,0.2-0.4,0.3-0.6,0.2c-0.1,0-0.4-0.1-0.7-0.1c-1,0-1.5,0.6-1.7,1v5.8c0,0.3-0.2,0.5-0.6,0.5
                                  h-1.8c-0.2,0-0.5-0.2-0.5-0.5V271.1z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M35.1,292.7c0-0.2,0.2-0.5,0.5-0.5h6.9c2.9,0,5.2,2.3,5.2,5.2c0,2.2-1.5,4-3.6,4.8l3.3,6.1
                                  c0.2,0.3,0,0.7-0.4,0.7h-2.5c-0.2,0-0.3-0.1-0.4-0.2l-3.2-6.4h-2.7v6.1c0,0.2-0.2,0.5-0.5,0.5h-2.2c-0.3,0-0.5-0.2-0.5-0.5V292.7z
                                   M42.2,299.9c1.3,0,2.4-1.1,2.4-2.4c0-1.3-1.1-2.3-2.4-2.3h-3.9v4.8H42.2z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M52.9,302.9c1,0,1.8,0.3,1.8,0.3c0-1.2-0.4-1.7-1.2-1.7c-0.9,0-2.1,0.2-2.7,0.4c-0.3,0.1-0.5-0.1-0.6-0.4
                                  l-0.2-1.2c-0.1-0.4,0.1-0.5,0.3-0.6c0.2-0.1,1.7-0.5,3.3-0.5c3,0,3.7,1.6,3.7,4.2v5.2c0,0.2-0.2,0.5-0.5,0.5H56
                                  c-0.2,0-0.3-0.1-0.4-0.3l-0.3-0.6c-0.6,0.5-1.4,1.2-2.9,1.2c-1.9,0-3.2-1.2-3.2-3.3C49.1,304.3,50.5,302.9,52.9,302.9z M53,307.2
                                  c0.8,0,1.5-0.6,1.7-0.9v-1.3c0,0-0.6-0.3-1.3-0.3c-0.9,0-1.6,0.5-1.6,1.3C51.7,306.7,52.2,307.2,53,307.2z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M60.5,301.9h-0.8c-0.2,0-0.4-0.2-0.4-0.5v-1.6c0-0.3,0.2-0.5,0.4-0.5h0.8v-2.7c0-0.2,0.2-0.5,0.5-0.5l2,0
                                  c0.2,0,0.4,0.2,0.4,0.5v2.7h2.1c0.3,0,0.5,0.2,0.5,0.5v1.6c0,0.2-0.2,0.5-0.5,0.5h-2.1v3.8c0,0.7,0.4,0.8,0.7,0.8
                                  c0.4,0,0.9-0.2,1.2-0.3c0.3-0.1,0.5,0,0.5,0.3l0.5,1.5c0.1,0.2,0,0.5-0.3,0.6c-0.1,0.1-1.7,0.6-2.9,0.6c-1.8,0-2.7-1.2-2.7-3.1
                                  V301.9z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M68.1,294.3c0-0.9,0.7-1.7,1.6-1.7c0.9,0,1.7,0.7,1.7,1.7s-0.7,1.7-1.7,1.7
                                  C68.8,296,68.1,295.3,68.1,294.3z M68.3,299.9c0-0.3,0.2-0.5,0.5-0.5h2c0.2,0,0.5,0.2,0.5,0.5v8.7c0,0.2-0.2,0.5-0.5,0.5h-2
                                  c-0.2,0-0.5-0.2-0.5-0.5V299.9z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M74.1,299.9c0-0.3,0.2-0.5,0.5-0.5h0.9c0.2,0,0.3,0.1,0.4,0.3l0.3,0.9c0.4-0.4,1.5-1.4,3.4-1.4
                                  c3,0,4.3,2.3,4.3,4.8v4.6c0,0.2-0.2,0.5-0.5,0.5h-2c-0.3,0-0.5-0.2-0.5-0.5v-4.8c0-1.2-0.6-2-1.8-2c-1.2,0-2,0.9-2.3,1.5v5.3
                                  c0,0.3-0.1,0.5-0.6,0.5h-1.8c-0.2,0-0.5-0.2-0.5-0.5V299.9z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M87.3,305.6c0,0-0.7-0.8-0.7-2.3c0-2.2,1.8-4.2,4.2-4.2h4.4c0.3,0,0.5,0.2,0.5,0.5v0.8
                                  c0,0.2-0.1,0.4-0.3,0.4l-1.3,0.4c0,0,1,0.7,1,2.4c0,1.9-1.6,3.8-4.1,3.8c-0.9,0-1.5-0.2-1.9-0.2c-0.3,0-0.9,0.3-0.9,0.9
                                  c0,0.5,0.4,0.8,1,0.8h3.1c2.2,0,4,1.2,4,3.4c0,2.2-1.8,4.1-5.3,4.1c-3.6,0-4.9-1.8-4.9-3.3c0-1.4,1.2-2.1,1.4-2.3v-0.1
                                  c-0.4-0.1-1.8-0.8-1.8-2.5C85.5,306.4,87.3,305.6,87.3,305.6z M90.8,314c1.3,0,2.2-0.6,2.2-1.5c0-0.4-0.3-1.2-1.8-1.2
                                  c-0.5,0-1.1,0-1.6,0.1c-0.3,0.1-1,0.4-1,1.2C88.7,313.5,89.5,314,90.8,314z M90.9,305.2c1,0,1.7-0.7,1.7-1.7c0-1-0.7-1.7-1.7-1.7
                                  c-1,0-1.7,0.7-1.7,1.7C89.2,304.5,89.9,305.2,90.9,305.2z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M36.9,321.1c0-0.2,0.2-0.4,0.4-0.4h0.4c0.2,0,0.3,0.1,0.4,0.2l5.3,11.3c0,0,0,0,0.1,0l5.3-11.3
                                  c0.1-0.1,0.2-0.2,0.4-0.2h0.4c0.3,0,0.4,0.2,0.4,0.4l2.8,16.1c0.1,0.3-0.1,0.6-0.4,0.6h-2.2c-0.2,0-0.4-0.2-0.5-0.4l-1.4-9.1
                                  c0,0-0.1,0-0.1,0l-4.2,9.4c0,0.1-0.2,0.3-0.4,0.3h-0.4c-0.2,0-0.3-0.1-0.4-0.3l-4.2-9.4c0,0,0,0-0.1,0l-1.4,9.1
                                  c0,0.2-0.2,0.4-0.4,0.4h-2.2c-0.3,0-0.5-0.2-0.5-0.6L36.9,321.1z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M59.2,328c2.7,0,4.9,2.3,4.9,5c0,2.8-2.2,5.1-4.9,5.1c-2.7,0-4.9-2.3-4.9-5.1
                                  C54.3,330.3,56.5,328,59.2,328z M59.2,335.5c1.3,0,2.3-1.1,2.3-2.5c0-1.3-1-2.4-2.3-2.4c-1.3,0-2.3,1.1-2.3,2.4
                                  C56.9,334.3,57.9,335.5,59.2,335.5z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M70.3,328c1,0,1.7,0.3,2.1,0.4v-6.9c0-0.2,0.2-0.5,0.5-0.5h2c0.2,0,0.5,0.2,0.5,0.5v15.9
                                  c0,0.2-0.2,0.5-0.5,0.5H74c-0.2,0-0.4-0.2-0.5-0.5l-0.2-0.7c0,0-1.1,1.4-3.1,1.4c-2.6,0-4.6-2.3-4.6-5
                                  C65.6,330.2,67.4,328,70.3,328z M70.5,335.5c1.3,0,1.8-1,2-1.6v-2.8c0,0-0.7-0.6-1.9-0.6c-1.3,0-2.3,1.1-2.3,2.4
                                  C68.3,334.3,69.2,335.5,70.5,335.5z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M82.4,328c2.5,0,4.5,1.9,4.5,4.5c0,0.2,0,0.5,0,0.6c0,0.2-0.2,0.4-0.5,0.4h-6.1c0.1,1,0.9,2.1,2.3,2.1
                                  c0.7,0,1.4-0.3,1.9-0.6c0.2-0.1,0.5-0.2,0.6,0l1,1.1c0.2,0.2,0.2,0.4,0,0.6c-0.8,0.7-2,1.3-3.6,1.3c-2.9,0-4.9-2.3-4.9-5
                                  C77.6,330.3,79.6,328,82.4,328z M84.1,331.8c-0.1-0.9-0.8-1.6-1.8-1.6c-1,0-1.8,0.7-1.9,1.6H84.1z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M89,321.5c0-0.2,0.2-0.5,0.5-0.5h2c0.2,0,0.5,0.2,0.5,0.5v15.9c0,0.2-0.2,0.5-0.5,0.5h-2
                                  c-0.2,0-0.5-0.2-0.5-0.5V321.5z"
                                            />
                                        </g>
                                        <g>
                                            <path
                                                fill="#FFFFFF"
                                                d="M705.5,305.9l0.9-1.5c0.2-0.3,0.6-0.3,0.8-0.2c0.1,0.1,2.1,1.5,3.6,1.5c1.2,0,2.2-0.8,2.2-1.8
                                  c0-1.2-1-2.1-3-2.9c-2.3-0.9-4.5-2.4-4.5-5.2c0-2.1,1.6-4.6,5.4-4.6c2.4,0,4.3,1.2,4.8,1.6c0.2,0.1,0.3,0.6,0.1,0.8l-0.9,1.4
                                  c-0.2,0.3-0.6,0.5-0.8,0.3c-0.2-0.1-2-1.3-3.3-1.3c-1.4,0-2.1,0.9-2.1,1.7c0,1.1,0.9,1.9,2.8,2.7c2.3,0.9,5,2.3,5,5.4
                                  c0,2.5-2.1,4.8-5.5,4.8c-3,0-4.8-1.4-5.3-1.9C705.4,306.4,705.3,306.2,705.5,305.9z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M721.4,314.9c0,0.3-0.1,0.5-0.6,0.5h-1.9c-0.2,0-0.5-0.2-0.5-0.5v-15.9c0-0.3,0.2-0.5,0.5-0.5h0.9
                                  c0.2,0,0.3,0.1,0.4,0.3l0.3,0.8c0.4-0.5,1.5-1.3,3.1-1.3c2.6,0,4.6,2.3,4.6,5c0,2.8-2.2,5-4.8,5c-0.9,0-1.8-0.3-2.1-0.5V314.9z
                                   M723.2,305.9c1.3,0,2.3-1.1,2.3-2.4c0-1.3-0.9-2.4-2.2-2.4c-1.2,0-1.8,1-2,1.5v2.9C721.3,305.3,722,305.9,723.2,305.9z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M734.6,298.4c2.7,0,4.9,2.3,4.9,5c0,2.8-2.2,5.1-4.9,5.1c-2.7,0-4.9-2.3-4.9-5.1
                                  C729.7,300.7,731.9,298.4,734.6,298.4z M734.6,305.9c1.3,0,2.3-1.1,2.3-2.5c0-1.3-1-2.4-2.3-2.4c-1.3,0-2.3,1.1-2.3,2.4
                                  C732.3,304.7,733.3,305.9,734.6,305.9z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M741.7,299.1c0-0.3,0.2-0.5,0.5-0.5h0.9c0.2,0,0.4,0.1,0.4,0.3l0.3,0.9c0.3-0.5,1.2-1.5,2.7-1.5
                                  c1.1,0,2.1,0.4,1.8,1l-0.9,1.6c-0.1,0.2-0.4,0.3-0.6,0.2c-0.1,0-0.4-0.1-0.7-0.1c-1,0-1.5,0.6-1.7,1v5.8c0,0.3-0.2,0.5-0.6,0.5
                                  h-1.8c-0.2,0-0.5-0.2-0.5-0.5V299.1z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M750.4,301.1h-0.8c-0.2,0-0.4-0.2-0.4-0.5v-1.6c0-0.3,0.2-0.5,0.4-0.5h0.8v-2.7c0-0.2,0.2-0.5,0.5-0.5l2,0
                                  c0.2,0,0.4,0.2,0.4,0.5v2.7h2.1c0.3,0,0.5,0.2,0.5,0.5v1.6c0,0.2-0.2,0.5-0.5,0.5h-2.1v3.8c0,0.7,0.4,0.8,0.7,0.8
                                  c0.4,0,0.9-0.2,1.2-0.3s0.5,0,0.5,0.3l0.5,1.5c0.1,0.2,0,0.5-0.3,0.6c-0.1,0.1-1.7,0.6-2.9,0.6c-1.8,0-2.7-1.2-2.7-3.1V301.1z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M757.6,307.1l0.6-1.3c0.1-0.2,0.3-0.3,0.6-0.2c0,0,1.1,0.6,2.2,0.6c0.5,0,0.8-0.2,0.8-0.6
                                  c0-0.4-0.3-0.7-1.6-1.2c-1.8-0.7-2.7-1.7-2.7-3.2s1.1-2.8,3.5-2.8c1.4,0,2.4,0.4,3,0.7c0.2,0.1,0.3,0.4,0.2,0.6l-0.6,1.2
                                  c-0.1,0.2-0.4,0.2-0.6,0.2c0,0-1.1-0.5-2-0.5c-0.6,0-0.8,0.2-0.8,0.6c0,0.4,0.4,0.6,1.3,0.9c1.8,0.7,3.2,1.5,3.2,3.4
                                  c0,1.6-1.4,2.9-3.8,2.9c-1.6,0-2.7-0.5-3.2-0.9C757.6,307.4,757.5,307.2,757.6,307.1z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M766.9,291.9c0-0.2,0.2-0.5,0.5-0.5h2c0.2,0,0.5,0.2,0.5,0.5v6.9c0.4-0.2,1.2-0.4,2.1-0.4
                                  c2.9,0,4.8,2.2,4.8,5c0,2.8-2,5-4.6,5c-2,0-3.1-1.4-3.1-1.4l-0.2,0.7c-0.1,0.3-0.2,0.5-0.5,0.5h-0.9c-0.2,0-0.5-0.2-0.5-0.5V291.9
                                  z M771.7,305.9c1.3,0,2.2-1.1,2.2-2.4c0-1.3-1-2.4-2.3-2.4c-1.2,0-1.9,0.6-1.9,0.6v2.8C769.9,304.9,770.5,305.9,771.7,305.9z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M783.1,298.4c2.7,0,4.9,2.3,4.9,5c0,2.8-2.2,5.1-4.9,5.1c-2.7,0-4.9-2.3-4.9-5.1
                                  C778.1,300.7,780.3,298.4,783.1,298.4z M783.1,305.9c1.3,0,2.3-1.1,2.3-2.5c0-1.3-1-2.4-2.3-2.4c-1.3,0-2.3,1.1-2.3,2.4
                                  C780.7,304.7,781.8,305.9,783.1,305.9z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M794.3,298.4c2.7,0,4.9,2.3,4.9,5c0,2.8-2.2,5.1-4.9,5.1c-2.7,0-4.9-2.3-4.9-5.1
                                  C789.4,300.7,791.6,298.4,794.3,298.4z M794.3,305.9c1.3,0,2.3-1.1,2.3-2.5c0-1.3-1-2.4-2.3-2.4c-1.3,0-2.3,1.1-2.3,2.4
                                  C792,304.7,793,305.9,794.3,305.9z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M801.3,291.9c0-0.2,0.2-0.5,0.5-0.5h2c0.2,0,0.5,0.2,0.5,0.5v10.4l3.3-3.4c0.2-0.1,0.3-0.3,0.6-0.3h2.2
                                  c0.4,0,0.6,0.4,0.3,0.7l-3.6,3.6l4.3,4.6c0.2,0.3,0.1,0.7-0.3,0.7h-2.6c-0.1,0-0.3-0.1-0.4-0.2l-3.8-4.3v4.1
                                  c0,0.2-0.2,0.5-0.5,0.5h-2c-0.2,0-0.5-0.2-0.5-0.5V291.9z"
                                            />
                                        </g>
                                        <g>
                                            <path
                                                fill="#414141"
                                                d="M462.2,387.6l0.6-1c0.1-0.2,0.4-0.2,0.5-0.1c0.1,0,1.4,1,2.4,1c0.8,0,1.5-0.5,1.5-1.2c0-0.8-0.7-1.4-2-1.9
                                  c-1.5-0.6-3-1.6-3-3.5c0-1.4,1.1-3.1,3.6-3.1c1.6,0,2.9,0.8,3.2,1.1c0.2,0.1,0.2,0.4,0.1,0.5l-0.6,0.9c-0.1,0.2-0.4,0.3-0.6,0.2
                                  c-0.1-0.1-1.3-0.9-2.2-0.9c-0.9,0-1.4,0.6-1.4,1.1c0,0.8,0.6,1.3,1.9,1.8c1.6,0.6,3.3,1.6,3.3,3.6c0,1.6-1.4,3.2-3.7,3.2
                                  c-2,0-3.2-0.9-3.5-1.2C462.1,388,462.1,387.9,462.2,387.6z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M471.1,384.5h-0.5c-0.2,0-0.3-0.1-0.3-0.3v-1.1c0-0.2,0.1-0.3,0.3-0.3h0.5V381c0-0.2,0.1-0.3,0.3-0.3
                                  l1.3,0c0.2,0,0.3,0.1,0.3,0.3v1.8h1.4c0.2,0,0.3,0.1,0.3,0.3v1.1c0,0.2-0.1,0.3-0.3,0.3H473v2.6c0,0.4,0.2,0.5,0.5,0.5
                                  c0.3,0,0.6-0.1,0.8-0.2c0.2-0.1,0.3,0,0.4,0.2l0.3,1c0.1,0.2,0,0.3-0.2,0.4c-0.1,0-1.1,0.4-1.9,0.4c-1.2,0-1.8-0.8-1.8-2.1V384.5z
                                  "
                                            />
                                            <path
                                                fill="#414141"
                                                d="M478.4,385.2c0.6,0,1.2,0.2,1.2,0.2c0-0.8-0.2-1.1-0.8-1.1c-0.6,0-1.4,0.1-1.8,0.2
                                  c-0.2,0.1-0.3-0.1-0.4-0.3l-0.1-0.8c0-0.2,0.1-0.4,0.2-0.4c0.1,0,1.2-0.3,2.2-0.3c2,0,2.5,1,2.5,2.8v3.5c0,0.2-0.1,0.3-0.3,0.3
                                  h-0.6c-0.1,0-0.2,0-0.3-0.2l-0.2-0.4c-0.4,0.3-1,0.8-2,0.8c-1.3,0-2.2-0.8-2.2-2.2C475.9,386,476.8,385.2,478.4,385.2z M478.5,388
                                  c0.5,0,1-0.4,1.1-0.6v-0.9c0,0-0.4-0.2-0.9-0.2c-0.6,0-1,0.4-1,0.9C477.6,387.7,478,388,478.5,388z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M483.2,378.3c0-0.2,0.1-0.3,0.3-0.3h1.4c0.2,0,0.3,0.1,0.3,0.3v6.9l2.2-2.2c0.1-0.1,0.2-0.2,0.4-0.2h1.5
                                  c0.3,0,0.4,0.3,0.2,0.5l-2.4,2.4l2.8,3.1c0.2,0.2,0.1,0.4-0.2,0.4h-1.7c-0.1,0-0.2-0.1-0.3-0.1l-2.5-2.9v2.7
                                  c0,0.2-0.1,0.3-0.3,0.3h-1.4c-0.2,0-0.3-0.1-0.3-0.3V378.3z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M493.6,382.6c1.6,0,3,1.2,3,3c0,0.1,0,0.3,0,0.4c0,0.2-0.2,0.3-0.3,0.3h-4.1c0,0.7,0.6,1.4,1.5,1.4
                                  c0.5,0,1-0.2,1.2-0.4c0.2-0.1,0.3-0.1,0.4,0l0.7,0.8c0.1,0.1,0.1,0.3,0,0.4c-0.5,0.5-1.3,0.9-2.4,0.9c-1.9,0-3.3-1.5-3.3-3.4
                                  C490.4,384.2,491.7,382.6,493.6,382.6z M494.8,385.2c0-0.6-0.6-1.1-1.2-1.1c-0.6,0-1.2,0.5-1.2,1.1H494.8z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M497.6,388.4l0.4-0.9c0.1-0.2,0.2-0.2,0.4-0.1c0,0,0.7,0.4,1.5,0.4c0.3,0,0.5-0.1,0.5-0.4
                                  c0-0.3-0.2-0.5-1.1-0.8c-1.2-0.5-1.8-1.1-1.8-2.1s0.7-1.9,2.4-1.9c0.9,0,1.6,0.3,2,0.5c0.1,0.1,0.2,0.3,0.1,0.4l-0.4,0.8
                                  c-0.1,0.1-0.3,0.2-0.4,0.1c0,0-0.7-0.3-1.3-0.3c-0.4,0-0.5,0.2-0.5,0.4c0,0.3,0.3,0.4,0.9,0.6c1.2,0.5,2.2,1,2.2,2.3
                                  c0,1.1-0.9,2-2.5,2c-1.1,0-1.8-0.3-2.1-0.6C497.6,388.7,497.6,388.6,497.6,388.4z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M503.7,385.8v-1.1c0-0.2,0.1-0.3,0.3-0.3h3.6c0.2,0,0.3,0.1,0.3,0.3v1.1c0,0.2-0.1,0.3-0.3,0.3H504
                                  C503.8,386.1,503.7,386,503.7,385.8z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M509.7,378.3c0-0.2,0.1-0.3,0.3-0.3h1.4c0.2,0,0.3,0.1,0.3,0.3v4.6c0.3-0.1,0.8-0.3,1.4-0.3
                                  c1.9,0,3.2,1.5,3.2,3.4c0,1.8-1.3,3.4-3.1,3.4c-1.3,0-2.1-1-2.1-1l-0.2,0.5c0,0.2-0.2,0.3-0.3,0.3H510c-0.2,0-0.3-0.1-0.3-0.3
                                  V378.3z M512.9,387.6c0.8,0,1.5-0.7,1.5-1.6s-0.7-1.6-1.6-1.6c-0.8,0-1.2,0.4-1.2,0.4v1.9C511.7,387,512.1,387.6,512.9,387.6z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M519.8,385.2c0.6,0,1.2,0.2,1.2,0.2c0-0.8-0.2-1.1-0.8-1.1c-0.6,0-1.4,0.1-1.8,0.2
                                  c-0.2,0.1-0.3-0.1-0.4-0.3l-0.1-0.8c0-0.2,0.1-0.4,0.2-0.4c0.1,0,1.2-0.3,2.2-0.3c2,0,2.5,1,2.5,2.8v3.5c0,0.2-0.1,0.3-0.3,0.3
                                  h-0.6c-0.1,0-0.2,0-0.3-0.2l-0.2-0.4c-0.4,0.3-1,0.8-2,0.8c-1.3,0-2.2-0.8-2.2-2.2C517.2,386,518.2,385.2,519.8,385.2z M519.8,388
                                  c0.5,0,1-0.4,1.1-0.6v-0.9c0,0-0.4-0.2-0.9-0.2c-0.6,0-1,0.4-1,0.9C519,387.7,519.3,388,519.8,388z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M524.2,388.4l0.4-0.9c0.1-0.2,0.2-0.2,0.4-0.1c0,0,0.7,0.4,1.5,0.4c0.3,0,0.5-0.1,0.5-0.4
                                  c0-0.3-0.2-0.5-1.1-0.8c-1.2-0.5-1.8-1.1-1.8-2.1s0.7-1.9,2.4-1.9c0.9,0,1.6,0.3,2,0.5c0.1,0.1,0.2,0.3,0.1,0.4l-0.4,0.8
                                  c-0.1,0.1-0.3,0.2-0.4,0.1c0,0-0.7-0.3-1.3-0.3c-0.4,0-0.5,0.2-0.5,0.4c0,0.3,0.3,0.4,0.9,0.6c1.2,0.5,2.2,1,2.2,2.3
                                  c0,1.1-0.9,2-2.5,2c-1.1,0-1.8-0.3-2.1-0.6C524.2,388.7,524.1,388.6,524.2,388.4z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M533.1,382.6c1.6,0,3,1.2,3,3c0,0.1,0,0.3,0,0.4c0,0.2-0.2,0.3-0.3,0.3h-4.1c0,0.7,0.6,1.4,1.5,1.4
                                  c0.5,0,1-0.2,1.2-0.4c0.2-0.1,0.3-0.1,0.4,0l0.7,0.8c0.1,0.1,0.1,0.3,0,0.4c-0.5,0.5-1.3,0.9-2.4,0.9c-1.9,0-3.3-1.5-3.3-3.4
                                  C529.9,384.2,531.2,382.6,533.1,382.6z M534.3,385.2c0-0.6-0.6-1.1-1.2-1.1c-0.6,0-1.2,0.5-1.2,1.1H534.3z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M540.2,382.6c0.6,0,1.1,0.2,1.4,0.3v-4.6c0-0.2,0.1-0.3,0.3-0.3h1.4c0.2,0,0.3,0.1,0.3,0.3v10.6
                                  c0,0.2-0.1,0.3-0.3,0.3h-0.6c-0.1,0-0.3-0.1-0.3-0.3l-0.2-0.5c0,0-0.8,1-2.1,1c-1.7,0-3.1-1.5-3.1-3.4
                                  C537,384.1,538.3,382.6,540.2,382.6z M540.3,387.6c0.8,0,1.2-0.7,1.3-1v-1.9c0,0-0.4-0.4-1.2-0.4c-0.9,0-1.6,0.7-1.6,1.6
                                  S539.5,387.6,540.3,387.6z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M490.5,397.5c0-0.2,0.1-0.3,0.3-0.3h4.6c1.9,0,3.5,1.5,3.5,3.4c0,1.5-1,2.7-2.4,3.2l2.2,4.1
                                  c0.1,0.2,0,0.5-0.3,0.5h-1.7c-0.1,0-0.2-0.1-0.3-0.1l-2.1-4.2h-1.8v4.1c0,0.2-0.1,0.3-0.3,0.3h-1.5c-0.2,0-0.3-0.1-0.3-0.3V397.5z
                                   M495.2,402.3c0.8,0,1.6-0.7,1.6-1.6c0-0.8-0.7-1.6-1.6-1.6h-2.6v3.2H495.2z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M500.2,398.6c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1s-0.5,1.1-1.1,1.1
                                  C500.7,399.7,500.2,399.2,500.2,398.6z M500.3,402.3c0-0.2,0.1-0.3,0.3-0.3h1.3c0.2,0,0.3,0.1,0.3,0.3v5.8c0,0.2-0.1,0.3-0.3,0.3
                                  h-1.3c-0.2,0-0.3-0.1-0.3-0.3V402.3z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M503.8,407.6l0.4-0.9c0.1-0.2,0.2-0.2,0.4-0.1c0,0,0.7,0.4,1.5,0.4c0.3,0,0.5-0.1,0.5-0.4
                                  c0-0.3-0.2-0.5-1.1-0.8c-1.2-0.5-1.8-1.1-1.8-2.1s0.7-1.9,2.4-1.9c0.9,0,1.6,0.3,2,0.5c0.1,0.1,0.2,0.3,0.1,0.4l-0.4,0.8
                                  c-0.1,0.1-0.3,0.2-0.4,0.1c0,0-0.7-0.3-1.3-0.3c-0.4,0-0.5,0.2-0.5,0.4c0,0.3,0.3,0.4,0.9,0.6c1.2,0.5,2.2,1,2.2,2.3
                                  c0,1.1-0.9,2-2.5,2c-1.1,0-1.8-0.3-2.1-0.6C503.8,407.9,503.8,407.8,503.8,407.6z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M510,397.5c0-0.2,0.1-0.3,0.3-0.3h1.4c0.2,0,0.3,0.1,0.3,0.3v6.9l2.2-2.2c0.1-0.1,0.2-0.2,0.4-0.2h1.5
                                  c0.3,0,0.4,0.3,0.2,0.5l-2.4,2.4l2.8,3.1c0.2,0.2,0.1,0.4-0.2,0.4h-1.7c-0.1,0-0.2-0.1-0.3-0.1l-2.5-2.9v2.7
                                  c0,0.2-0.1,0.3-0.3,0.3h-1.4c-0.2,0-0.3-0.1-0.3-0.3V397.5z"
                                            />
                                        </g>
                                        <g>
                                            <rect x="282" y="370" fill="#FFFFFF" width="101" height="50.2" />
                                            <g>
                                                <path
                                                    fill="#414141"
                                                    d="M292.1,378.3c0-0.2,0.1-0.3,0.3-0.3h6.5c0.2,0,0.3,0.1,0.3,0.3v1.3c0,0.2-0.1,0.3-0.3,0.3h-4.7v2.6h4
                                      c0.2,0,0.3,0.1,0.3,0.3v1.3c0,0.2-0.1,0.3-0.3,0.3h-4v2.8h4.7c0.2,0,0.3,0.1,0.3,0.3v1.3c0,0.2-0.1,0.3-0.3,0.3h-6.5
                                      c-0.2,0-0.3-0.1-0.3-0.3V378.3z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M300.2,388.7l2.1-2.8l-2-2.7c-0.2-0.2,0-0.5,0.2-0.5h1.3c0.2,0,0.4,0,0.5,0.2l1,1.5h0l1.1-1.5
                                      c0.1-0.2,0.3-0.2,0.5-0.2h1.4c0.3,0,0.4,0.3,0.2,0.5l-2,2.7l2,2.8c0.1,0.2,0.1,0.5-0.2,0.5H305c-0.2,0-0.3-0.1-0.4-0.3l-1.1-1.6
                                      h0l-1.2,1.7c0,0.1-0.2,0.1-0.3,0.1h-1.5C300.2,389.2,300.1,388.9,300.2,388.7z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M310,393.7c0,0.2-0.1,0.3-0.4,0.3h-1.3c-0.2,0-0.3-0.1-0.3-0.3v-10.6c0-0.2,0.1-0.3,0.3-0.3h0.6
                                      c0.1,0,0.2,0.1,0.3,0.2l0.2,0.5c0.2-0.3,1-0.9,2.1-0.9c1.8,0,3.1,1.5,3.1,3.4c0,1.9-1.4,3.4-3.2,3.4c-0.6,0-1.2-0.2-1.4-0.4
                                      V393.7z M311.2,387.6c0.9,0,1.6-0.7,1.6-1.6s-0.6-1.6-1.5-1.6c-0.8,0-1.2,0.6-1.3,1v1.9C309.9,387.3,310.4,387.6,311.2,387.6z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M318.8,382.6c1.6,0,3,1.2,3,3c0,0.1,0,0.3,0,0.4c0,0.2-0.2,0.3-0.3,0.3h-4.1c0,0.7,0.6,1.4,1.5,1.4
                                      c0.5,0,1-0.2,1.2-0.4c0.2-0.1,0.3-0.1,0.4,0l0.7,0.8c0.1,0.1,0.1,0.3,0,0.4c-0.5,0.5-1.3,0.9-2.4,0.9c-1.9,0-3.3-1.5-3.3-3.4
                                      C315.5,384.2,316.9,382.6,318.8,382.6z M319.9,385.2c0-0.6-0.6-1.1-1.2-1.1c-0.6,0-1.2,0.5-1.2,1.1H319.9z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M326.1,382.6c1,0,1.8,0.4,2.4,1.1c0.1,0.1,0.1,0.3-0.1,0.4l-0.7,0.7c-0.1,0.1-0.3,0-0.4,0
                                      c-0.3-0.3-0.7-0.5-1.1-0.5c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6c0.7,0,1-0.3,1.3-0.6c0.1-0.1,0.3-0.1,0.4,0l0.7,0.6
                                      c0.2,0.1,0.2,0.3,0.1,0.4c-0.5,0.8-1.4,1.3-2.5,1.3c-1.8,0-3.4-1.4-3.4-3.4C322.6,384.1,324.3,382.6,326.1,382.6z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M330.3,384.5h-0.5c-0.2,0-0.3-0.1-0.3-0.3v-1.1c0-0.2,0.1-0.3,0.3-0.3h0.5V381c0-0.2,0.1-0.3,0.3-0.3
                                      l1.3,0c0.2,0,0.3,0.1,0.3,0.3v1.8h1.4c0.2,0,0.3,0.1,0.3,0.3v1.1c0,0.2-0.1,0.3-0.3,0.3h-1.4v2.6c0,0.4,0.2,0.5,0.5,0.5
                                      c0.3,0,0.6-0.1,0.8-0.2c0.2-0.1,0.3,0,0.4,0.2l0.3,1c0.1,0.2,0,0.3-0.2,0.4c-0.1,0-1.1,0.4-1.9,0.4c-1.2,0-1.8-0.8-1.8-2.1V384.5
                                      z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M337.6,385.2c0.6,0,1.2,0.2,1.2,0.2c0-0.8-0.2-1.1-0.8-1.1c-0.6,0-1.4,0.1-1.8,0.2
                                      c-0.2,0.1-0.3-0.1-0.4-0.3l-0.1-0.8c0-0.2,0.1-0.4,0.2-0.4c0.1,0,1.2-0.3,2.2-0.3c2,0,2.5,1,2.5,2.8v3.5c0,0.2-0.1,0.3-0.3,0.3
                                      h-0.6c-0.1,0-0.2,0-0.3-0.2l-0.2-0.4c-0.4,0.3-1,0.8-2,0.8c-1.3,0-2.2-0.8-2.2-2.2C335,386,335.9,385.2,337.6,385.2z M337.6,388
                                      c0.5,0,1-0.4,1.1-0.6v-0.9c0,0-0.4-0.2-0.9-0.2c-0.6,0-1,0.4-1,0.9C336.8,387.7,337.1,388,337.6,388z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M342.3,383.1c0-0.2,0.1-0.3,0.3-0.3h0.6c0.1,0,0.2,0.1,0.3,0.2l0.2,0.6c0.2-0.3,1-0.9,2.3-0.9
                                      c2,0,2.8,1.5,2.8,3.2v3.1c0,0.2-0.1,0.3-0.3,0.3h-1.3c-0.2,0-0.3-0.1-0.3-0.3v-3.2c0-0.8-0.4-1.4-1.2-1.4c-0.8,0-1.3,0.6-1.5,1
                                      v3.5c0,0.2-0.1,0.3-0.4,0.3h-1.2c-0.2,0-0.3-0.1-0.3-0.3V383.1z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M353.5,382.6c1,0,1.8,0.4,2.4,1.1c0.1,0.1,0.1,0.3-0.1,0.4l-0.7,0.7c-0.1,0.1-0.3,0-0.4,0
                                      c-0.3-0.3-0.7-0.5-1.1-0.5c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6c0.7,0,1-0.3,1.3-0.6c0.1-0.1,0.3-0.1,0.4,0l0.7,0.6
                                      c0.2,0.1,0.2,0.3,0.1,0.4c-0.5,0.8-1.4,1.3-2.5,1.3c-1.8,0-3.4-1.4-3.4-3.4C350.1,384.1,351.7,382.6,353.5,382.6z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M357.3,379.4c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1s-0.5,1.1-1.1,1.1
                                      C357.8,380.5,357.3,380,357.3,379.4z M357.4,383.1c0-0.2,0.1-0.3,0.3-0.3h1.3c0.2,0,0.3,0.1,0.3,0.3v5.8c0,0.2-0.1,0.3-0.3,0.3
                                      h-1.3c-0.2,0-0.3-0.1-0.3-0.3V383.1z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M364.1,382.6c1.6,0,3,1.2,3,3c0,0.1,0,0.3,0,0.4c0,0.2-0.2,0.3-0.3,0.3h-4.1c0,0.7,0.6,1.4,1.5,1.4
                                      c0.5,0,1-0.2,1.2-0.4c0.2-0.1,0.3-0.1,0.4,0l0.7,0.8c0.1,0.1,0.1,0.3,0,0.4c-0.5,0.5-1.3,0.9-2.4,0.9c-1.9,0-3.3-1.5-3.3-3.4
                                      C360.8,384.2,362.2,382.6,364.1,382.6z M365.2,385.2c0-0.6-0.6-1.1-1.2-1.1c-0.6,0-1.2,0.5-1.2,1.1H365.2z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M368.1,388.4l0.4-0.9c0.1-0.2,0.2-0.2,0.4-0.1c0,0,0.7,0.4,1.5,0.4c0.3,0,0.5-0.1,0.5-0.4
                                      c0-0.3-0.2-0.5-1.1-0.8c-1.2-0.5-1.8-1.1-1.8-2.1s0.7-1.9,2.4-1.9c0.9,0,1.6,0.3,2,0.5c0.1,0.1,0.2,0.3,0.1,0.4l-0.4,0.8
                                      c-0.1,0.1-0.3,0.2-0.4,0.1c0,0-0.7-0.3-1.3-0.3c-0.4,0-0.5,0.2-0.5,0.4c0,0.3,0.3,0.4,0.9,0.6c1.2,0.5,2.2,1,2.2,2.3
                                      c0,1.1-0.9,2-2.5,2c-1.1,0-1.8-0.3-2.1-0.6C368.1,388.7,368,388.6,368.1,388.4z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M291.5,408l5-10.8c0-0.1,0.1-0.2,0.3-0.2h0.2c0.2,0,0.2,0.1,0.3,0.2l4.9,10.8c0.1,0.2,0,0.4-0.3,0.4h-1.4
                                      c-0.2,0-0.4-0.1-0.5-0.3l-0.8-1.7h-4.8l-0.8,1.7c-0.1,0.2-0.2,0.3-0.5,0.3h-1.4C291.5,408.4,291.4,408.2,291.5,408z M298.4,404.6
                                      l-1.6-3.5h0l-1.6,3.5H298.4z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M305.6,401.8c0.6,0,1.1,0.2,1.4,0.3v-4.6c0-0.2,0.1-0.3,0.3-0.3h1.4c0.2,0,0.3,0.1,0.3,0.3v10.6
                                      c0,0.2-0.1,0.3-0.3,0.3h-0.6c-0.1,0-0.3-0.1-0.3-0.3l-0.2-0.5c0,0-0.8,1-2.1,1c-1.7,0-3.1-1.5-3.1-3.4
                                      C302.5,403.3,303.7,401.8,305.6,401.8z M305.8,406.8c0.8,0,1.2-0.7,1.3-1v-1.9c0,0-0.4-0.4-1.2-0.4c-0.9,0-1.6,0.7-1.6,1.6
                                      S304.9,406.8,305.8,406.8z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M310.9,402.3c0-0.2,0.1-0.3,0.3-0.3h1.3c0.2,0,0.3,0.1,0.3,0.3v7.8c0,2.1-1.3,3.2-3,3.2
                                      c-0.8,0-1.6-0.4-1.9-0.6c-0.1-0.1-0.1-0.3-0.1-0.4l0.5-1c0.1-0.1,0.2-0.2,0.4-0.1c0.2,0.1,0.6,0.3,0.9,0.3c0.7,0,1.1-0.5,1.1-1.5
                                      V402.3z M310.8,398.6c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1s-0.5,1.1-1.1,1.1C311.3,399.7,310.8,399.2,310.8,398.6z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M314.7,402.3c0-0.2,0.1-0.3,0.3-0.3h1.3c0.2,0,0.3,0.1,0.3,0.3v3.2c0,0.8,0.4,1.4,1.2,1.4
                                      c0.7,0,1.2-0.6,1.3-1.2v-3.3c0-0.2,0.1-0.3,0.4-0.3h1.2c0.2,0,0.3,0.1,0.3,0.3v5.8c0,0.2-0.1,0.3-0.3,0.3H320
                                      c-0.2,0-0.3-0.1-0.3-0.2l-0.2-0.5c-0.3,0.3-0.9,0.9-2.1,0.9c-2,0-2.7-1.5-2.7-3.2V402.3z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M322.5,407.6l0.4-0.9c0.1-0.2,0.2-0.2,0.4-0.1c0,0,0.7,0.4,1.5,0.4c0.3,0,0.5-0.1,0.5-0.4
                                      c0-0.3-0.2-0.5-1.1-0.8c-1.2-0.5-1.8-1.1-1.8-2.1s0.7-1.9,2.4-1.9c0.9,0,1.6,0.3,2,0.5c0.1,0.1,0.2,0.3,0.1,0.4l-0.4,0.8
                                      c-0.1,0.1-0.3,0.2-0.4,0.1c0,0-0.7-0.3-1.3-0.3c-0.4,0-0.5,0.2-0.5,0.4c0,0.3,0.3,0.4,0.9,0.6c1.2,0.5,2.2,1,2.2,2.3
                                      c0,1.1-0.9,2-2.5,2c-1.1,0-1.8-0.3-2.1-0.6C322.5,407.9,322.4,407.8,322.5,407.6z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M329,403.7h-0.5c-0.2,0-0.3-0.1-0.3-0.3v-1.1c0-0.2,0.1-0.3,0.3-0.3h0.5v-1.8c0-0.2,0.1-0.3,0.3-0.3
                                      l1.3,0c0.2,0,0.3,0.1,0.3,0.3v1.8h1.4c0.2,0,0.3,0.1,0.3,0.3v1.1c0,0.2-0.1,0.3-0.3,0.3h-1.4v2.6c0,0.4,0.2,0.5,0.5,0.5
                                      c0.3,0,0.6-0.1,0.8-0.2c0.2-0.1,0.3,0,0.4,0.2l0.3,1c0.1,0.2,0,0.3-0.2,0.4c-0.1,0-1.1,0.4-1.9,0.4c-1.2,0-1.8-0.8-1.8-2.1V403.7
                                      z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M334.2,402.3c0-0.2,0.1-0.3,0.3-0.3h0.6c0.1,0,0.2,0.1,0.3,0.2l0.2,0.6c0.3-0.3,1-0.9,2.1-0.9
                                      c0.9,0,1.6,0.4,2.1,1c0.3-0.3,1.1-1,2.3-1c2,0,2.8,1.5,2.8,3.2v3.1c0,0.2-0.1,0.3-0.3,0.3h-1.3c-0.2,0-0.3-0.1-0.3-0.3v-3.2
                                      c0-0.8-0.4-1.4-1.2-1.4c-0.9,0-1.3,0.7-1.3,0.7s0,0.3,0,0.6v3.2c0,0.2-0.1,0.3-0.3,0.3h-1.2c-0.2,0-0.3-0.1-0.3-0.3v-3.2
                                      c0-0.8-0.4-1.4-1.2-1.4c-0.8,0-1.2,0.6-1.3,1v3.5c0,0.2-0.1,0.3-0.3,0.3h-1.3c-0.2,0-0.3-0.1-0.3-0.3V402.3z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M349.4,401.8c1.6,0,3,1.2,3,3c0,0.1,0,0.3,0,0.4c0,0.2-0.2,0.3-0.3,0.3H348c0,0.7,0.6,1.4,1.5,1.4
                                      c0.5,0,1-0.2,1.2-0.4c0.2-0.1,0.3-0.1,0.4,0l0.7,0.8c0.1,0.1,0.1,0.3,0,0.4c-0.5,0.5-1.3,0.9-2.4,0.9c-1.9,0-3.3-1.5-3.3-3.4
                                      C346.2,403.4,347.5,401.8,349.4,401.8z M350.6,404.4c0-0.6-0.6-1.1-1.2-1.1c-0.6,0-1.2,0.5-1.2,1.1H350.6z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M353.8,402.3c0-0.2,0.1-0.3,0.3-0.3h0.6c0.1,0,0.2,0.1,0.3,0.2l0.2,0.6c0.2-0.3,1-0.9,2.3-0.9
                                      c2,0,2.8,1.5,2.8,3.2v3.1c0,0.2-0.1,0.3-0.3,0.3h-1.3c-0.2,0-0.3-0.1-0.3-0.3v-3.2c0-0.8-0.4-1.4-1.2-1.4c-0.8,0-1.3,0.6-1.5,1
                                      v3.5c0,0.2-0.1,0.3-0.4,0.3h-1.2c-0.2,0-0.3-0.1-0.3-0.3V402.3z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M362.4,403.7h-0.5c-0.2,0-0.3-0.1-0.3-0.3v-1.1c0-0.2,0.1-0.3,0.3-0.3h0.5v-1.8c0-0.2,0.1-0.3,0.3-0.3
                                      l1.3,0c0.2,0,0.3,0.1,0.3,0.3v1.8h1.4c0.2,0,0.3,0.1,0.3,0.3v1.1c0,0.2-0.1,0.3-0.3,0.3h-1.4v2.6c0,0.4,0.2,0.5,0.5,0.5
                                      c0.3,0,0.6-0.1,0.8-0.2c0.2-0.1,0.3,0,0.4,0.2l0.3,1c0.1,0.2,0,0.3-0.2,0.4c-0.1,0-1.1,0.4-1.9,0.4c-1.2,0-1.8-0.8-1.8-2.1V403.7
                                      z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M367.2,407.6l0.4-0.9c0.1-0.2,0.2-0.2,0.4-0.1c0,0,0.7,0.4,1.5,0.4c0.3,0,0.5-0.1,0.5-0.4
                                      c0-0.3-0.2-0.5-1.1-0.8c-1.2-0.5-1.8-1.1-1.8-2.1s0.7-1.9,2.4-1.9c0.9,0,1.6,0.3,2,0.5c0.1,0.1,0.2,0.3,0.1,0.4l-0.4,0.8
                                      c-0.1,0.1-0.3,0.2-0.4,0.1c0,0-0.7-0.3-1.3-0.3c-0.4,0-0.5,0.2-0.5,0.4c0,0.3,0.3,0.4,0.9,0.6c1.2,0.5,2.2,1,2.2,2.3
                                      c0,1.1-0.9,2-2.5,2c-1.1,0-1.8-0.3-2.1-0.6C367.2,407.9,367.1,407.8,367.2,407.6z"
                                                />
                                            </g>
                                        </g>
                                        <g>
                                            <rect x="145.2" y="276.2" fill="#FFFFFF" width="101.7" height="51.7" />
                                            <g>
                                                <path
                                                    fill="#414141"
                                                    d="M170.9,287.3c0-0.2,0.1-0.3,0.3-0.3h3.8c2,0,3.6,1.6,3.6,3.5c0,2-1.6,3.6-3.6,3.6h-2v3.8
                                      c0,0.2-0.1,0.3-0.3,0.3h-1.5c-0.2,0-0.3-0.1-0.3-0.3V287.3z M174.8,292.2c0.9,0,1.6-0.7,1.6-1.7c0-0.9-0.8-1.6-1.6-1.6H173v3.2
                                      H174.8z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M179.4,292.1c0-0.2,0.1-0.3,0.3-0.3h0.6c0.1,0,0.2,0.1,0.3,0.2l0.2,0.6c0.2-0.3,0.8-1,1.8-1
                                      c0.8,0,1.4,0.2,1.2,0.6l-0.6,1c-0.1,0.1-0.2,0.2-0.4,0.1c0,0-0.3-0.1-0.5-0.1c-0.6,0-1,0.4-1.1,0.7v3.9c0,0.2-0.1,0.3-0.4,0.3
                                      h-1.2c-0.2,0-0.3-0.1-0.3-0.3V292.1z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M187.6,291.6c1.8,0,3.3,1.5,3.3,3.3c0,1.8-1.5,3.4-3.3,3.4c-1.8,0-3.3-1.5-3.3-3.4
                                      C184.3,293.2,185.8,291.6,187.6,291.6z M187.6,296.6c0.8,0,1.5-0.7,1.5-1.6c0-0.9-0.7-1.6-1.5-1.6c-0.9,0-1.5,0.7-1.5,1.6
                                      C186.1,295.9,186.8,296.6,187.6,296.6z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M191.5,292.2c-0.1-0.2,0-0.4,0.2-0.4h1.4c0.2,0,0.2,0.1,0.3,0.2l1.3,3c0,0,0,0,0,0l1.4-3
                                      c0.1-0.2,0.2-0.2,0.4-0.2h1.2c0.2,0,0.3,0.2,0.2,0.4l-2.9,6c0,0.1-0.1,0.2-0.3,0.2h-0.1c-0.1,0-0.2-0.1-0.3-0.2L191.5,292.2z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M198.9,288.4c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1s-0.5,1.1-1.1,1.1
                                      C199.4,289.5,198.9,289,198.9,288.4z M199,292.1c0-0.2,0.1-0.3,0.3-0.3h1.3c0.2,0,0.3,0.1,0.3,0.3v5.8c0,0.2-0.1,0.3-0.3,0.3
                                      h-1.3c-0.2,0-0.3-0.1-0.3-0.3V292.1z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M205.6,291.6c0.6,0,1.1,0.2,1.4,0.3v-4.6c0-0.2,0.1-0.3,0.3-0.3h1.4c0.2,0,0.3,0.1,0.3,0.3v10.6
                                      c0,0.2-0.1,0.3-0.3,0.3H208c-0.1,0-0.3-0.1-0.3-0.3l-0.2-0.5c0,0-0.8,1-2.1,1c-1.7,0-3.1-1.5-3.1-3.4
                                      C202.4,293.1,203.7,291.6,205.6,291.6z M205.7,296.6c0.8,0,1.2-0.7,1.3-1v-1.9c0,0-0.4-0.4-1.2-0.4c-0.9,0-1.6,0.7-1.6,1.6
                                      S204.9,296.6,205.7,296.6z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M213.7,291.6c1.6,0,3,1.2,3,3c0,0.1,0,0.3,0,0.4c0,0.2-0.2,0.3-0.3,0.3h-4.1c0,0.7,0.6,1.4,1.5,1.4
                                      c0.5,0,1-0.2,1.2-0.4c0.2-0.1,0.3-0.1,0.4,0l0.7,0.8c0.1,0.1,0.1,0.3,0,0.4c-0.5,0.5-1.3,0.9-2.4,0.9c-1.9,0-3.3-1.5-3.3-3.4
                                      C210.4,293.2,211.8,291.6,213.7,291.6z M214.8,294.2c0-0.6-0.6-1.1-1.2-1.1c-0.6,0-1.2,0.5-1.2,1.1H214.8z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M218,292.1c0-0.2,0.1-0.3,0.3-0.3h0.6c0.1,0,0.2,0.1,0.3,0.2l0.2,0.6c0.2-0.3,0.8-1,1.8-1
                                      c0.8,0,1.4,0.2,1.2,0.6l-0.6,1c-0.1,0.1-0.2,0.2-0.4,0.1c0,0-0.3-0.1-0.5-0.1c-0.6,0-1,0.4-1.1,0.7v3.9c0,0.2-0.1,0.3-0.4,0.3
                                      h-1.2c-0.2,0-0.3-0.1-0.3-0.3V292.1z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M156.1,306.5c0-0.2,0.1-0.3,0.3-0.3h6.5c0.2,0,0.3,0.1,0.3,0.3v1.3c0,0.2-0.1,0.3-0.3,0.3h-4.7v2.6h4
                                      c0.2,0,0.3,0.1,0.3,0.3v1.3c0,0.2-0.1,0.3-0.3,0.3h-4v2.8h4.7c0.2,0,0.3,0.1,0.3,0.3v1.3c0,0.2-0.1,0.3-0.3,0.3h-6.5
                                      c-0.2,0-0.3-0.1-0.3-0.3V306.5z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M164.2,316.9l2.1-2.8l-2-2.7c-0.2-0.2,0-0.5,0.2-0.5h1.3c0.2,0,0.4,0,0.5,0.2l1,1.5h0l1.1-1.5
                                      c0.1-0.2,0.3-0.2,0.5-0.2h1.4c0.3,0,0.4,0.3,0.2,0.5l-2,2.7l2,2.8c0.1,0.2,0.1,0.5-0.2,0.5H169c-0.2,0-0.3-0.1-0.4-0.3l-1.1-1.6
                                      h0l-1.2,1.7c0,0.1-0.2,0.1-0.3,0.1h-1.5C164.2,317.4,164.1,317.1,164.2,316.9z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M174,321.9c0,0.2-0.1,0.3-0.4,0.3h-1.3c-0.2,0-0.3-0.1-0.3-0.3v-10.6c0-0.2,0.1-0.3,0.3-0.3h0.6
                                      c0.1,0,0.2,0.1,0.3,0.2l0.2,0.5c0.2-0.3,1-0.9,2.1-0.9c1.8,0,3.1,1.5,3.1,3.4c0,1.9-1.4,3.4-3.2,3.4c-0.6,0-1.2-0.2-1.4-0.4
                                      V321.9z M175.2,315.8c0.9,0,1.6-0.7,1.6-1.6s-0.6-1.6-1.5-1.6c-0.8,0-1.2,0.6-1.3,1v1.9C173.9,315.5,174.4,315.8,175.2,315.8z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M182.8,310.8c1.6,0,3,1.2,3,3c0,0.1,0,0.3,0,0.4c0,0.2-0.2,0.3-0.3,0.3h-4.1c0,0.7,0.6,1.4,1.5,1.4
                                      c0.5,0,1-0.2,1.2-0.4c0.2-0.1,0.3-0.1,0.4,0l0.7,0.8c0.1,0.1,0.1,0.3,0,0.4c-0.5,0.5-1.3,0.9-2.4,0.9c-1.9,0-3.3-1.5-3.3-3.4
                                      C179.5,312.4,180.9,310.8,182.8,310.8z M183.9,313.4c0-0.6-0.6-1.1-1.2-1.1c-0.6,0-1.2,0.5-1.2,1.1H183.9z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M190.1,310.8c1,0,1.8,0.4,2.4,1.1c0.1,0.1,0.1,0.3-0.1,0.4l-0.7,0.7c-0.1,0.1-0.3,0-0.4,0
                                      c-0.3-0.3-0.7-0.5-1.1-0.5c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6c0.7,0,1-0.3,1.3-0.6c0.1-0.1,0.3-0.1,0.4,0l0.7,0.6
                                      c0.2,0.1,0.2,0.3,0.1,0.4c-0.5,0.8-1.4,1.3-2.5,1.3c-1.8,0-3.4-1.4-3.4-3.4C186.6,312.3,188.3,310.8,190.1,310.8z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M194.3,312.7h-0.5c-0.2,0-0.3-0.1-0.3-0.3v-1.1c0-0.2,0.1-0.3,0.3-0.3h0.5v-1.8c0-0.2,0.1-0.3,0.3-0.3
                                      l1.3,0c0.2,0,0.3,0.1,0.3,0.3v1.8h1.4c0.2,0,0.3,0.1,0.3,0.3v1.1c0,0.2-0.1,0.3-0.3,0.3h-1.4v2.6c0,0.4,0.2,0.5,0.5,0.5
                                      c0.3,0,0.6-0.1,0.8-0.2c0.2-0.1,0.3,0,0.4,0.2l0.3,1c0.1,0.2,0,0.3-0.2,0.4c-0.1,0-1.1,0.4-1.9,0.4c-1.2,0-1.8-0.8-1.8-2.1V312.7
                                      z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M201.6,313.4c0.6,0,1.2,0.2,1.2,0.2c0-0.8-0.2-1.1-0.8-1.1c-0.6,0-1.4,0.1-1.8,0.2
                                      c-0.2,0.1-0.3-0.1-0.4-0.3l-0.1-0.8c0-0.2,0.1-0.4,0.2-0.4c0.1,0,1.2-0.3,2.2-0.3c2,0,2.5,1,2.5,2.8v3.5c0,0.2-0.1,0.3-0.3,0.3
                                      h-0.6c-0.1,0-0.2,0-0.3-0.2l-0.2-0.4c-0.4,0.3-1,0.8-2,0.8c-1.3,0-2.2-0.8-2.2-2.2C199,314.2,199.9,313.4,201.6,313.4z
                                       M201.6,316.2c0.5,0,1-0.4,1.1-0.6v-0.9c0,0-0.4-0.2-0.9-0.2c-0.6,0-1,0.4-1,0.9C200.8,315.9,201.1,316.2,201.6,316.2z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M206.3,311.3c0-0.2,0.1-0.3,0.3-0.3h0.6c0.1,0,0.2,0.1,0.3,0.2l0.2,0.6c0.2-0.3,1-0.9,2.3-0.9
                                      c2,0,2.8,1.5,2.8,3.2v3.1c0,0.2-0.1,0.3-0.3,0.3h-1.3c-0.2,0-0.3-0.1-0.3-0.3v-3.2c0-0.8-0.4-1.4-1.2-1.4c-0.8,0-1.3,0.6-1.5,1
                                      v3.5c0,0.2-0.1,0.3-0.4,0.3h-1.2c-0.2,0-0.3-0.1-0.3-0.3V311.3z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M217.5,310.8c1,0,1.8,0.4,2.4,1.1c0.1,0.1,0.1,0.3-0.1,0.4l-0.7,0.7c-0.1,0.1-0.3,0-0.4,0
                                      c-0.3-0.3-0.7-0.5-1.1-0.5c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6c0.7,0,1-0.3,1.3-0.6c0.1-0.1,0.3-0.1,0.4,0l0.7,0.6
                                      c0.2,0.1,0.2,0.3,0.1,0.4c-0.5,0.8-1.4,1.3-2.5,1.3c-1.8,0-3.4-1.4-3.4-3.4C214.1,312.3,215.7,310.8,217.5,310.8z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M221.3,307.6c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1s-0.5,1.1-1.1,1.1
                                      C221.8,308.7,221.3,308.2,221.3,307.6z M221.4,311.3c0-0.2,0.1-0.3,0.3-0.3h1.3c0.2,0,0.3,0.1,0.3,0.3v5.8c0,0.2-0.1,0.3-0.3,0.3
                                      h-1.3c-0.2,0-0.3-0.1-0.3-0.3V311.3z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M228.1,310.8c1.6,0,3,1.2,3,3c0,0.1,0,0.3,0,0.4c0,0.2-0.2,0.3-0.3,0.3h-4.1c0,0.7,0.6,1.4,1.5,1.4
                                      c0.5,0,1-0.2,1.2-0.4c0.2-0.1,0.3-0.1,0.4,0l0.7,0.8c0.1,0.1,0.1,0.3,0,0.4c-0.5,0.5-1.3,0.9-2.4,0.9c-1.9,0-3.3-1.5-3.3-3.4
                                      C224.8,312.4,226.2,310.8,228.1,310.8z M229.2,313.4c0-0.6-0.6-1.1-1.2-1.1c-0.6,0-1.2,0.5-1.2,1.1H229.2z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M232.1,316.6l0.4-0.9c0.1-0.2,0.2-0.2,0.4-0.1c0,0,0.7,0.4,1.5,0.4c0.3,0,0.5-0.1,0.5-0.4
                                      c0-0.3-0.2-0.5-1.1-0.8c-1.2-0.5-1.8-1.1-1.8-2.1s0.7-1.9,2.4-1.9c0.9,0,1.6,0.3,2,0.5c0.1,0.1,0.2,0.3,0.1,0.4l-0.4,0.8
                                      c-0.1,0.1-0.3,0.2-0.4,0.1c0,0-0.7-0.3-1.3-0.3c-0.4,0-0.5,0.2-0.5,0.4c0,0.3,0.3,0.4,0.9,0.6c1.2,0.5,2.2,1,2.2,2.3
                                      c0,1.1-0.9,2-2.5,2c-1.1,0-1.8-0.3-2.1-0.6C232.1,316.9,232,316.8,232.1,316.6z"
                                                />
                                            </g>
                                        </g>
                                        <g>
                                            <rect x="589" y="278" fill="#FFFFFF" width="101" height="50.2" />
                                            <g>
                                                <path
                                                    fill="#414141"
                                                    d="M612,297.6l0.6-1c0.1-0.2,0.4-0.2,0.5-0.1c0.1,0,1.4,1,2.4,1c0.8,0,1.5-0.5,1.5-1.2c0-0.8-0.7-1.4-2-1.9
                                      c-1.5-0.6-3-1.6-3-3.5c0-1.4,1.1-3.1,3.6-3.1c1.6,0,2.9,0.8,3.2,1.1c0.2,0.1,0.2,0.4,0.1,0.5l-0.6,0.9c-0.1,0.2-0.4,0.3-0.6,0.2
                                      c-0.1-0.1-1.3-0.9-2.2-0.9c-0.9,0-1.4,0.6-1.4,1.1c0,0.8,0.6,1.3,1.9,1.8c1.6,0.6,3.3,1.6,3.3,3.6c0,1.6-1.4,3.2-3.7,3.2
                                      c-2,0-3.2-0.9-3.5-1.2C611.9,298,611.8,297.9,612,297.6z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M622.6,303.7c0,0.2-0.1,0.3-0.4,0.3h-1.3c-0.2,0-0.3-0.1-0.3-0.3v-10.6c0-0.2,0.1-0.3,0.3-0.3h0.6
                                      c0.1,0,0.2,0.1,0.3,0.2l0.2,0.5c0.2-0.3,1-0.9,2.1-0.9c1.8,0,3.1,1.5,3.1,3.4c0,1.9-1.4,3.4-3.2,3.4c-0.6,0-1.2-0.2-1.4-0.4
                                      V303.7z M623.7,297.6c0.9,0,1.6-0.7,1.6-1.6s-0.6-1.6-1.5-1.6c-0.8,0-1.2,0.6-1.3,1v1.9C622.5,297.3,622.9,297.6,623.7,297.6z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M631.4,292.6c1.8,0,3.3,1.5,3.3,3.3c0,1.8-1.5,3.4-3.3,3.4c-1.8,0-3.3-1.5-3.3-3.4
                                      C628.1,294.2,629.6,292.6,631.4,292.6z M631.4,297.6c0.8,0,1.5-0.7,1.5-1.6c0-0.9-0.7-1.6-1.5-1.6c-0.9,0-1.5,0.7-1.5,1.6
                                      C629.9,296.9,630.5,297.6,631.4,297.6z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M636.1,293.1c0-0.2,0.1-0.3,0.3-0.3h0.6c0.1,0,0.2,0.1,0.3,0.2l0.2,0.6c0.2-0.3,0.8-1,1.8-1
                                      c0.8,0,1.4,0.2,1.2,0.6l-0.6,1c-0.1,0.1-0.2,0.2-0.4,0.1c0,0-0.3-0.1-0.5-0.1c-0.6,0-1,0.4-1.1,0.7v3.9c0,0.2-0.1,0.3-0.4,0.3
                                      h-1.2c-0.2,0-0.3-0.1-0.3-0.3V293.1z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M641.9,294.5h-0.5c-0.2,0-0.3-0.1-0.3-0.3v-1.1c0-0.2,0.1-0.3,0.3-0.3h0.5V291c0-0.2,0.1-0.3,0.3-0.3
                                      l1.3,0c0.2,0,0.3,0.1,0.3,0.3v1.8h1.4c0.2,0,0.3,0.1,0.3,0.3v1.1c0,0.2-0.1,0.3-0.3,0.3h-1.4v2.6c0,0.4,0.2,0.5,0.5,0.5
                                      c0.3,0,0.6-0.1,0.8-0.2c0.2-0.1,0.3,0,0.4,0.2l0.3,1c0.1,0.2,0,0.3-0.2,0.4c-0.1,0-1.1,0.4-1.9,0.4c-1.2,0-1.8-0.8-1.8-2.1V294.5
                                      z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M647.1,288.3c0-0.2,0.1-0.3,0.3-0.3h1.4c0.2,0,0.3,0.1,0.3,0.3v4.6c0.3-0.1,0.8-0.3,1.4-0.3
                                      c1.9,0,3.2,1.5,3.2,3.4c0,1.8-1.3,3.4-3.1,3.4c-1.3,0-2.1-1-2.1-1l-0.2,0.5c0,0.2-0.2,0.3-0.3,0.3h-0.6c-0.2,0-0.3-0.1-0.3-0.3
                                      V288.3z M650.3,297.6c0.8,0,1.5-0.7,1.5-1.6s-0.7-1.6-1.6-1.6c-0.8,0-1.2,0.4-1.2,0.4v1.9C649.1,297,649.5,297.6,650.3,297.6z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M657.9,292.6c1.6,0,3,1.2,3,3c0,0.1,0,0.3,0,0.4c0,0.2-0.2,0.3-0.3,0.3h-4.1c0,0.7,0.6,1.4,1.5,1.4
                                      c0.5,0,1-0.2,1.2-0.4c0.2-0.1,0.3-0.1,0.4,0l0.7,0.8c0.1,0.1,0.1,0.3,0,0.4c-0.5,0.5-1.3,0.9-2.4,0.9c-1.9,0-3.3-1.5-3.3-3.4
                                      C654.6,294.2,656,292.6,657.9,292.6z M659,295.2c0-0.6-0.6-1.1-1.2-1.1c-0.6,0-1.2,0.5-1.2,1.1H659z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M662.5,294.5H662c-0.2,0-0.3-0.1-0.3-0.3v-1.1c0-0.2,0.1-0.3,0.3-0.3h0.5V291c0-0.2,0.1-0.3,0.3-0.3
                                      l1.3,0c0.2,0,0.3,0.1,0.3,0.3v1.8h1.4c0.2,0,0.3,0.1,0.3,0.3v1.1c0,0.2-0.1,0.3-0.3,0.3h-1.4v2.6c0,0.4,0.2,0.5,0.5,0.5
                                      c0.3,0,0.6-0.1,0.8-0.2c0.2-0.1,0.3,0,0.4,0.2l0.3,1c0.1,0.2,0,0.3-0.2,0.4c-0.1,0-1.1,0.4-1.9,0.4c-1.2,0-1.8-0.8-1.8-2.1V294.5
                                      z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M613.4,307.5c0-0.2,0.1-0.3,0.3-0.3h3.8c2,0,3.6,1.6,3.6,3.5c0,2-1.6,3.6-3.6,3.6h-2v3.8
                                      c0,0.2-0.1,0.3-0.3,0.3h-1.5c-0.2,0-0.3-0.1-0.3-0.3V307.5z M617.3,312.4c0.9,0,1.6-0.7,1.6-1.7c0-0.9-0.8-1.6-1.6-1.6h-1.9v3.2
                                      H617.3z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M621.9,312.3c0-0.2,0.1-0.3,0.3-0.3h0.6c0.1,0,0.2,0.1,0.3,0.2l0.2,0.6c0.2-0.3,0.8-1,1.8-1
                                      c0.8,0,1.4,0.2,1.2,0.6l-0.6,1c-0.1,0.1-0.2,0.2-0.4,0.1c0,0-0.3-0.1-0.5-0.1c-0.6,0-1,0.4-1.1,0.7v3.9c0,0.2-0.1,0.3-0.4,0.3
                                      h-1.2c-0.2,0-0.3-0.1-0.3-0.3V312.3z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M630.1,311.8c1.8,0,3.3,1.5,3.3,3.3c0,1.8-1.5,3.4-3.3,3.4c-1.8,0-3.3-1.5-3.3-3.4
                                      C626.8,313.4,628.3,311.8,630.1,311.8z M630.1,316.8c0.8,0,1.5-0.7,1.5-1.6c0-0.9-0.7-1.6-1.5-1.6c-0.9,0-1.5,0.7-1.5,1.6
                                      C628.6,316.1,629.3,316.8,630.1,316.8z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M635.3,316.1c0,0-0.5-0.5-0.5-1.5c0-1.5,1.2-2.8,2.8-2.8h2.9c0.2,0,0.3,0.1,0.3,0.3v0.5
                                      c0,0.1-0.1,0.2-0.2,0.3l-0.9,0.3c0,0,0.6,0.5,0.6,1.6c0,1.3-1.1,2.5-2.7,2.5c-0.6,0-1-0.1-1.3-0.1c-0.2,0-0.6,0.2-0.6,0.6
                                      c0,0.3,0.3,0.5,0.6,0.5h2c1.5,0,2.6,0.8,2.6,2.2c0,1.5-1.2,2.7-3.5,2.7c-2.4,0-3.3-1.2-3.3-2.2c0-1,0.8-1.4,1-1.5v0
                                      c-0.3-0.1-1.2-0.5-1.2-1.7C634.1,316.7,635.3,316.1,635.3,316.1z M637.6,321.8c0.9,0,1.5-0.4,1.5-1c0-0.3-0.2-0.8-1.2-0.8
                                      c-0.4,0-0.7,0-1,0.1c-0.2,0.1-0.7,0.3-0.7,0.8C636.2,321.4,636.8,321.8,637.6,321.8z M637.7,315.9c0.7,0,1.1-0.5,1.1-1.2
                                      c0-0.7-0.4-1.2-1.1-1.2c-0.6,0-1.1,0.5-1.1,1.2C636.6,315.4,637.1,315.9,637.7,315.9z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M642.2,312.3c0-0.2,0.1-0.3,0.3-0.3h0.6c0.1,0,0.2,0.1,0.3,0.2l0.2,0.6c0.2-0.3,0.8-1,1.8-1
                                      c0.8,0,1.4,0.2,1.2,0.6l-0.6,1c-0.1,0.1-0.2,0.2-0.4,0.1c0,0-0.3-0.1-0.5-0.1c-0.6,0-1,0.4-1.1,0.7v3.9c0,0.2-0.1,0.3-0.4,0.3
                                      h-1.2c-0.2,0-0.3-0.1-0.3-0.3V312.3z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M649.8,314.4c0.6,0,1.2,0.2,1.2,0.2c0-0.8-0.2-1.1-0.8-1.1c-0.6,0-1.4,0.1-1.8,0.2
                                      c-0.2,0.1-0.3-0.1-0.4-0.3l-0.1-0.8c0-0.2,0.1-0.4,0.2-0.4c0.1,0,1.2-0.3,2.2-0.3c2,0,2.5,1,2.5,2.8v3.5c0,0.2-0.1,0.3-0.3,0.3
                                      h-0.6c-0.1,0-0.2,0-0.3-0.2l-0.2-0.4c-0.4,0.3-1,0.8-2,0.8c-1.3,0-2.2-0.8-2.2-2.2C647.2,315.2,648.1,314.4,649.8,314.4z
                                       M649.8,317.2c0.5,0,1-0.4,1.1-0.6v-0.9c0,0-0.4-0.2-0.9-0.2c-0.6,0-1,0.4-1,0.9C649,316.9,649.3,317.2,649.8,317.2z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M654.5,312.3c0-0.2,0.1-0.3,0.3-0.3h0.6c0.1,0,0.2,0.1,0.3,0.2l0.2,0.6c0.3-0.3,1-0.9,2.1-0.9
                                      c0.9,0,1.6,0.4,2.1,1c0.3-0.3,1.1-1,2.3-1c2,0,2.8,1.5,2.8,3.2v3.1c0,0.2-0.1,0.3-0.3,0.3h-1.3c-0.2,0-0.3-0.1-0.3-0.3v-3.2
                                      c0-0.8-0.4-1.4-1.2-1.4c-0.9,0-1.3,0.7-1.3,0.7s0,0.3,0,0.6v3.2c0,0.2-0.1,0.3-0.3,0.3h-1.2c-0.2,0-0.3-0.1-0.3-0.3v-3.2
                                      c0-0.8-0.4-1.4-1.2-1.4c-0.8,0-1.2,0.6-1.3,1v3.5c0,0.2-0.1,0.3-0.3,0.3h-1.3c-0.2,0-0.3-0.1-0.3-0.3V312.3z"
                                                />
                                            </g>
                                        </g>
                                        <g>
                                            <rect x="365" y="231" fill="#FFFFFF" width="101" height="50.2" />
                                            <g>
                                                <path
                                                    fill="#414141"
                                                    d="M377.8,241.3c0-0.2,0.1-0.3,0.3-0.3h1.5c0.2,0,0.3,0.1,0.3,0.3v4.2h5.2v-4.2c0-0.2,0.1-0.3,0.3-0.3h1.5
                                      c0.2,0,0.3,0.1,0.3,0.3v10.6c0,0.2-0.1,0.3-0.3,0.3h-1.5c-0.2,0-0.3-0.1-0.3-0.3v-4.4h-5.2v4.4c0,0.2-0.1,0.3-0.3,0.3h-1.5
                                      c-0.2,0-0.3-0.1-0.3-0.3V241.3z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M391.3,248.2c0.6,0,1.2,0.2,1.2,0.2c0-0.8-0.2-1.1-0.8-1.1c-0.6,0-1.4,0.1-1.8,0.2
                                      c-0.2,0.1-0.3-0.1-0.4-0.3l-0.1-0.8c0-0.2,0.1-0.4,0.2-0.4c0.1,0,1.2-0.3,2.2-0.3c2,0,2.5,1,2.5,2.8v3.5c0,0.2-0.1,0.3-0.3,0.3
                                      h-0.6c-0.1,0-0.2,0-0.3-0.2l-0.2-0.4c-0.4,0.3-1,0.8-2,0.8c-1.3,0-2.2-0.8-2.2-2.2C388.7,249,389.7,248.2,391.3,248.2z
                                       M391.3,251c0.5,0,1-0.4,1.1-0.6v-0.9c0,0-0.4-0.2-0.9-0.2c-0.6,0-1,0.4-1,0.9C390.5,250.7,390.8,251,391.3,251z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M396,246.1c0-0.2,0.1-0.3,0.3-0.3h0.6c0.1,0,0.2,0.1,0.3,0.2l0.2,0.6c0.2-0.3,0.8-1,1.8-1
                                      c0.8,0,1.4,0.2,1.2,0.6l-0.6,1c-0.1,0.1-0.2,0.2-0.4,0.1c0,0-0.3-0.1-0.5-0.1c-0.6,0-1,0.4-1.1,0.7v3.9c0,0.2-0.1,0.3-0.4,0.3
                                      h-1.2c-0.2,0-0.3-0.1-0.3-0.3V246.1z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M401.5,246.1c0-0.2,0.1-0.3,0.3-0.3h0.6c0.1,0,0.2,0.1,0.3,0.2l0.2,0.6c0.3-0.3,1-0.9,2.1-0.9
                                      c0.9,0,1.6,0.4,2.1,1c0.3-0.3,1.1-1,2.3-1c2,0,2.8,1.5,2.8,3.2v3.1c0,0.2-0.1,0.3-0.3,0.3h-1.3c-0.2,0-0.3-0.1-0.3-0.3v-3.2
                                      c0-0.8-0.4-1.4-1.2-1.4c-0.9,0-1.3,0.7-1.3,0.7s0,0.3,0,0.6v3.2c0,0.2-0.1,0.3-0.3,0.3h-1.2c-0.2,0-0.3-0.1-0.3-0.3v-3.2
                                      c0-0.8-0.4-1.4-1.2-1.4c-0.8,0-1.2,0.6-1.3,1v3.5c0,0.2-0.1,0.3-0.3,0.3h-1.3c-0.2,0-0.3-0.1-0.3-0.3V246.1z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M416.7,245.6c1.8,0,3.3,1.5,3.3,3.3c0,1.8-1.5,3.4-3.3,3.4c-1.8,0-3.3-1.5-3.3-3.4
                                      C413.4,247.2,414.9,245.6,416.7,245.6z M416.7,250.6c0.8,0,1.5-0.7,1.5-1.6c0-0.9-0.7-1.6-1.5-1.6c-0.9,0-1.5,0.7-1.5,1.6
                                      C415.2,249.9,415.9,250.6,416.7,250.6z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M421.4,246.1c0-0.2,0.1-0.3,0.3-0.3h0.6c0.1,0,0.2,0.1,0.3,0.2l0.2,0.6c0.2-0.3,1-0.9,2.3-0.9
                                      c2,0,2.8,1.5,2.8,3.2v3.1c0,0.2-0.1,0.3-0.3,0.3h-1.3c-0.2,0-0.3-0.1-0.3-0.3v-3.2c0-0.8-0.4-1.4-1.2-1.4c-0.8,0-1.3,0.6-1.5,1
                                      v3.5c0,0.2-0.1,0.3-0.4,0.3h-1.2c-0.2,0-0.3-0.1-0.3-0.3V246.1z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M429.6,242.4c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1s-0.5,1.1-1.1,1.1
                                      C430.1,243.5,429.6,243,429.6,242.4z M429.7,246.1c0-0.2,0.1-0.3,0.3-0.3h1.3c0.2,0,0.3,0.1,0.3,0.3v5.8c0,0.2-0.1,0.3-0.3,0.3
                                      H430c-0.2,0-0.3-0.1-0.3-0.3V246.1z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M433.1,251.7l2.3-4.2v0h-1.8c-0.2,0-0.3-0.1-0.3-0.3v-1.1c0-0.2,0.1-0.3,0.3-0.3h4.2
                                      c0.2,0,0.4,0.2,0.3,0.4l-2.3,4.2v0h1.7c0.2,0,0.3,0.1,0.3,0.3v1.1c0,0.2-0.1,0.3-0.3,0.3h-4c-0.2,0-0.3-0.1-0.3-0.3V251.7z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M442.2,245.6c1.6,0,3,1.2,3,3c0,0.1,0,0.3,0,0.4c0,0.2-0.2,0.3-0.3,0.3h-4.1c0,0.7,0.6,1.4,1.5,1.4
                                      c0.5,0,1-0.2,1.2-0.4c0.2-0.1,0.3-0.1,0.4,0l0.7,0.8c0.1,0.1,0.1,0.3,0,0.4c-0.5,0.5-1.3,0.9-2.4,0.9c-1.9,0-3.3-1.5-3.3-3.4
                                      C439,247.2,440.3,245.6,442.2,245.6z M443.4,248.2c0-0.6-0.6-1.1-1.2-1.1c-0.6,0-1.2,0.5-1.2,1.1H443.4z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M449.3,245.6c0.6,0,1.1,0.2,1.4,0.3v-4.6c0-0.2,0.1-0.3,0.3-0.3h1.4c0.2,0,0.3,0.1,0.3,0.3v10.6
                                      c0,0.2-0.1,0.3-0.3,0.3h-0.6c-0.1,0-0.3-0.1-0.3-0.3l-0.2-0.5c0,0-0.8,1-2.1,1c-1.7,0-3.1-1.5-3.1-3.4
                                      C446.1,247.1,447.4,245.6,449.3,245.6z M449.4,250.6c0.8,0,1.2-0.7,1.3-1v-1.9c0,0-0.4-0.4-1.2-0.4c-0.9,0-1.6,0.7-1.6,1.6
                                      S448.6,250.6,449.4,250.6z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M375.1,260.5c0-0.2,0.1-0.3,0.3-0.3h6.5c0.2,0,0.3,0.1,0.3,0.3v1.3c0,0.2-0.1,0.3-0.3,0.3h-4.7v2.6h4
                                      c0.2,0,0.3,0.1,0.3,0.3v1.3c0,0.2-0.1,0.3-0.3,0.3h-4v2.8h4.7c0.2,0,0.3,0.1,0.3,0.3v1.3c0,0.2-0.1,0.3-0.3,0.3h-6.5
                                      c-0.2,0-0.3-0.1-0.3-0.3V260.5z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M383.2,270.9l2.1-2.8l-2-2.7c-0.2-0.2,0-0.5,0.2-0.5h1.3c0.2,0,0.4,0,0.5,0.2l1,1.5h0l1.1-1.5
                                      c0.1-0.2,0.3-0.2,0.5-0.2h1.4c0.3,0,0.4,0.3,0.2,0.5l-2,2.7l2,2.8c0.1,0.2,0.1,0.5-0.2,0.5H388c-0.2,0-0.3-0.1-0.4-0.3l-1.1-1.6
                                      h0l-1.2,1.7c0,0.1-0.2,0.1-0.3,0.1h-1.5C383.2,271.4,383.1,271.1,383.2,270.9z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M393,275.9c0,0.2-0.1,0.3-0.4,0.3h-1.3c-0.2,0-0.3-0.1-0.3-0.3v-10.6c0-0.2,0.1-0.3,0.3-0.3h0.6
                                      c0.1,0,0.2,0.1,0.3,0.2l0.2,0.5c0.2-0.3,1-0.9,2.1-0.9c1.8,0,3.1,1.5,3.1,3.4c0,1.9-1.4,3.4-3.2,3.4c-0.6,0-1.2-0.2-1.4-0.4
                                      V275.9z M394.2,269.8c0.9,0,1.6-0.7,1.6-1.6s-0.6-1.6-1.5-1.6c-0.8,0-1.2,0.6-1.3,1v1.9C392.9,269.5,393.4,269.8,394.2,269.8z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M401.8,264.8c1.6,0,3,1.2,3,3c0,0.1,0,0.3,0,0.4c0,0.2-0.2,0.3-0.3,0.3h-4.1c0,0.7,0.6,1.4,1.5,1.4
                                      c0.5,0,1-0.2,1.2-0.4c0.2-0.1,0.3-0.1,0.4,0l0.7,0.8c0.1,0.1,0.1,0.3,0,0.4c-0.5,0.5-1.3,0.9-2.4,0.9c-1.9,0-3.3-1.5-3.3-3.4
                                      C398.5,266.4,399.9,264.8,401.8,264.8z M402.9,267.4c0-0.6-0.6-1.1-1.2-1.1c-0.6,0-1.2,0.5-1.2,1.1H402.9z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M409.1,264.8c1,0,1.8,0.4,2.4,1.1c0.1,0.1,0.1,0.3-0.1,0.4l-0.7,0.7c-0.1,0.1-0.3,0-0.4,0
                                      c-0.3-0.3-0.7-0.5-1.1-0.5c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6c0.7,0,1-0.3,1.3-0.6c0.1-0.1,0.3-0.1,0.4,0l0.7,0.6
                                      c0.2,0.1,0.2,0.3,0.1,0.4c-0.5,0.8-1.4,1.3-2.5,1.3c-1.8,0-3.4-1.4-3.4-3.4C405.6,266.3,407.3,264.8,409.1,264.8z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M413.3,266.7h-0.5c-0.2,0-0.3-0.1-0.3-0.3v-1.1c0-0.2,0.1-0.3,0.3-0.3h0.5v-1.8c0-0.2,0.1-0.3,0.3-0.3
                                      l1.3,0c0.2,0,0.3,0.1,0.3,0.3v1.8h1.4c0.2,0,0.3,0.1,0.3,0.3v1.1c0,0.2-0.1,0.3-0.3,0.3h-1.4v2.6c0,0.4,0.2,0.5,0.5,0.5
                                      c0.3,0,0.6-0.1,0.8-0.2c0.2-0.1,0.3,0,0.4,0.2l0.3,1c0.1,0.2,0,0.3-0.2,0.4c-0.1,0-1.1,0.4-1.9,0.4c-1.2,0-1.8-0.8-1.8-2.1V266.7
                                      z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M420.6,267.4c0.6,0,1.2,0.2,1.2,0.2c0-0.8-0.2-1.1-0.8-1.1c-0.6,0-1.4,0.1-1.8,0.2
                                      c-0.2,0.1-0.3-0.1-0.4-0.3l-0.1-0.8c0-0.2,0.1-0.4,0.2-0.4c0.1,0,1.2-0.3,2.2-0.3c2,0,2.5,1,2.5,2.8v3.5c0,0.2-0.1,0.3-0.3,0.3
                                      h-0.6c-0.1,0-0.2,0-0.3-0.2l-0.2-0.4c-0.4,0.3-1,0.8-2,0.8c-1.3,0-2.2-0.8-2.2-2.2C418,268.2,418.9,267.4,420.6,267.4z
                                       M420.6,270.2c0.5,0,1-0.4,1.1-0.6v-0.9c0,0-0.4-0.2-0.9-0.2c-0.6,0-1,0.4-1,0.9C419.8,269.9,420.1,270.2,420.6,270.2z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M425.3,265.3c0-0.2,0.1-0.3,0.3-0.3h0.6c0.1,0,0.2,0.1,0.3,0.2l0.2,0.6c0.2-0.3,1-0.9,2.3-0.9
                                      c2,0,2.8,1.5,2.8,3.2v3.1c0,0.2-0.1,0.3-0.3,0.3h-1.3c-0.2,0-0.3-0.1-0.3-0.3v-3.2c0-0.8-0.4-1.4-1.2-1.4c-0.8,0-1.3,0.6-1.5,1
                                      v3.5c0,0.2-0.1,0.3-0.4,0.3h-1.2c-0.2,0-0.3-0.1-0.3-0.3V265.3z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M436.5,264.8c1,0,1.8,0.4,2.4,1.1c0.1,0.1,0.1,0.3-0.1,0.4l-0.7,0.7c-0.1,0.1-0.3,0-0.4,0
                                      c-0.3-0.3-0.7-0.5-1.1-0.5c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6c0.7,0,1-0.3,1.3-0.6c0.1-0.1,0.3-0.1,0.4,0l0.7,0.6
                                      c0.2,0.1,0.2,0.3,0.1,0.4c-0.5,0.8-1.4,1.3-2.5,1.3c-1.8,0-3.4-1.4-3.4-3.4C433.1,266.3,434.7,264.8,436.5,264.8z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M440.3,261.6c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1s-0.5,1.1-1.1,1.1
                                      C440.8,262.7,440.3,262.2,440.3,261.6z M440.4,265.3c0-0.2,0.1-0.3,0.3-0.3h1.3c0.2,0,0.3,0.1,0.3,0.3v5.8c0,0.2-0.1,0.3-0.3,0.3
                                      h-1.3c-0.2,0-0.3-0.1-0.3-0.3V265.3z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M447.1,264.8c1.6,0,3,1.2,3,3c0,0.1,0,0.3,0,0.4c0,0.2-0.2,0.3-0.3,0.3h-4.1c0,0.7,0.6,1.4,1.5,1.4
                                      c0.5,0,1-0.2,1.2-0.4c0.2-0.1,0.3-0.1,0.4,0l0.7,0.8c0.1,0.1,0.1,0.3,0,0.4c-0.5,0.5-1.3,0.9-2.4,0.9c-1.9,0-3.3-1.5-3.3-3.4
                                      C443.8,266.4,445.2,264.8,447.1,264.8z M448.2,267.4c0-0.6-0.6-1.1-1.2-1.1c-0.6,0-1.2,0.5-1.2,1.1H448.2z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M451.1,270.6l0.4-0.9c0.1-0.2,0.2-0.2,0.4-0.1c0,0,0.7,0.4,1.5,0.4c0.3,0,0.5-0.1,0.5-0.4
                                      c0-0.3-0.2-0.5-1.1-0.8c-1.2-0.5-1.8-1.1-1.8-2.1s0.7-1.9,2.4-1.9c0.9,0,1.6,0.3,2,0.5c0.1,0.1,0.2,0.3,0.1,0.4l-0.4,0.8
                                      c-0.1,0.1-0.3,0.2-0.4,0.1c0,0-0.7-0.3-1.3-0.3c-0.4,0-0.5,0.2-0.5,0.4c0,0.3,0.3,0.4,0.9,0.6c1.2,0.5,2.2,1,2.2,2.3
                                      c0,1.1-0.9,2-2.5,2c-1.1,0-1.8-0.3-2.1-0.6C451.1,270.9,451,270.8,451.1,270.6z"
                                                />
                                            </g>
                                        </g>
                                        <g>
                                            <rect x="338" y="136" fill="#FFFFFF" width="148" height="50.2" />
                                            <g>
                                                <path
                                                    fill="#414141"
                                                    d="M350.9,146.1c0-0.1,0.1-0.2,0.3-0.2h0.3c0.1,0,0.2,0.1,0.3,0.2l3.5,7.6c0,0,0,0,0,0l3.5-7.6
                                      c0-0.1,0.1-0.2,0.3-0.2h0.3c0.2,0,0.3,0.1,0.3,0.2l1.9,10.8c0,0.2-0.1,0.4-0.3,0.4h-1.5c-0.1,0-0.3-0.1-0.3-0.2l-0.9-6.1
                                      c0,0,0,0,0,0l-2.8,6.3c0,0.1-0.1,0.2-0.3,0.2h-0.3c-0.1,0-0.2-0.1-0.3-0.2l-2.8-6.3c0,0,0,0,0,0L351,157c0,0.1-0.1,0.2-0.3,0.2
                                      h-1.4c-0.2,0-0.3-0.1-0.3-0.4L350.9,146.1z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M365.1,153.2c0.6,0,1.2,0.2,1.2,0.2c0-0.8-0.2-1.1-0.8-1.1c-0.6,0-1.4,0.1-1.8,0.2
                                      c-0.2,0.1-0.3-0.1-0.4-0.3l-0.1-0.8c0-0.2,0.1-0.4,0.2-0.4c0.1,0,1.2-0.3,2.2-0.3c2,0,2.5,1,2.5,2.8v3.5c0,0.2-0.1,0.3-0.3,0.3
                                      h-0.6c-0.1,0-0.2,0-0.3-0.2l-0.2-0.4c-0.4,0.3-1,0.8-2,0.8c-1.3,0-2.2-0.8-2.2-2.2C362.5,154,363.5,153.2,365.1,153.2z
                                       M365.1,156c0.5,0,1-0.4,1.1-0.6v-0.9c0,0-0.4-0.2-0.9-0.2c-0.6,0-1,0.4-1,0.9C364.3,155.7,364.6,156,365.1,156z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M370.2,152.5h-0.5c-0.2,0-0.3-0.1-0.3-0.3v-1.1c0-0.2,0.1-0.3,0.3-0.3h0.5V149c0-0.2,0.1-0.3,0.3-0.3
                                      l1.3,0c0.2,0,0.3,0.1,0.3,0.3v1.8h1.4c0.2,0,0.3,0.1,0.3,0.3v1.1c0,0.2-0.1,0.3-0.3,0.3h-1.4v2.6c0,0.4,0.2,0.5,0.5,0.5
                                      c0.3,0,0.6-0.1,0.8-0.2c0.2-0.1,0.3,0,0.4,0.2l0.3,1c0.1,0.2,0,0.3-0.2,0.4c-0.1,0-1.1,0.4-1.9,0.4c-1.2,0-1.8-0.8-1.8-2.1V152.5
                                      z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M378.3,150.6c1,0,1.8,0.4,2.4,1.1c0.1,0.1,0.1,0.3-0.1,0.4l-0.7,0.7c-0.1,0.1-0.3,0-0.4,0
                                      c-0.3-0.3-0.7-0.5-1.1-0.5c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6c0.7,0,1-0.3,1.3-0.6c0.1-0.1,0.3-0.1,0.4,0l0.7,0.6
                                      c0.2,0.1,0.2,0.3,0.1,0.4c-0.5,0.8-1.4,1.3-2.5,1.3c-1.8,0-3.4-1.4-3.4-3.4C374.9,152.1,376.5,150.6,378.3,150.6z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M382.2,146.3c0-0.2,0.1-0.3,0.3-0.3h1.4c0.2,0,0.3,0.1,0.3,0.3v4.9c0.3-0.2,0.9-0.5,1.7-0.5
                                      c2,0,2.9,1.6,2.9,3.2v3.1c0,0.2-0.1,0.3-0.3,0.3h-1.3c-0.2,0-0.3-0.1-0.3-0.3v-3.1c0-0.9-0.5-1.4-1.3-1.4c-0.7,0-1.2,0.5-1.4,1.1
                                      v3.4c0,0.2-0.1,0.3-0.3,0.3h-1.3c-0.2,0-0.3-0.1-0.3-0.3V146.3z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M394.5,146.3c0-0.2,0.1-0.3,0.3-0.3h6.5c0.2,0,0.3,0.1,0.3,0.3v1.3c0,0.2-0.1,0.3-0.3,0.3h-4.7v2.6h4
                                      c0.2,0,0.3,0.1,0.3,0.3v1.3c0,0.2-0.1,0.3-0.3,0.3h-4v2.8h4.7c0.2,0,0.3,0.1,0.3,0.3v1.3c0,0.2-0.1,0.3-0.3,0.3h-6.5
                                      c-0.2,0-0.3-0.1-0.3-0.3V146.3z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M402.6,156.7l2.1-2.8l-2-2.7c-0.2-0.2,0-0.5,0.2-0.5h1.3c0.2,0,0.4,0,0.5,0.2l1,1.5h0l1.1-1.5
                                      c0.1-0.2,0.3-0.2,0.5-0.2h1.4c0.3,0,0.4,0.3,0.2,0.5l-2,2.7l2,2.8c0.1,0.2,0.1,0.5-0.2,0.5h-1.4c-0.2,0-0.3-0.1-0.4-0.3l-1.1-1.6
                                      h0l-1.2,1.7c0,0.1-0.2,0.1-0.3,0.1h-1.5C402.6,157.2,402.5,156.9,402.6,156.7z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M412.4,161.7c0,0.2-0.1,0.3-0.4,0.3h-1.3c-0.2,0-0.3-0.1-0.3-0.3v-10.6c0-0.2,0.1-0.3,0.3-0.3h0.6
                                      c0.1,0,0.2,0.1,0.3,0.2l0.2,0.5c0.2-0.3,1-0.9,2.1-0.9c1.8,0,3.1,1.5,3.1,3.4c0,1.9-1.4,3.4-3.2,3.4c-0.6,0-1.2-0.2-1.4-0.4
                                      V161.7z M413.6,155.6c0.9,0,1.6-0.7,1.6-1.6s-0.6-1.6-1.5-1.6c-0.8,0-1.2,0.6-1.3,1v1.9C412.3,155.3,412.8,155.6,413.6,155.6z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M421.2,150.6c1.6,0,3,1.2,3,3c0,0.1,0,0.3,0,0.4c0,0.2-0.2,0.3-0.3,0.3h-4.1c0,0.7,0.6,1.4,1.5,1.4
                                      c0.5,0,1-0.2,1.2-0.4c0.2-0.1,0.3-0.1,0.4,0l0.7,0.8c0.1,0.1,0.1,0.3,0,0.4c-0.5,0.5-1.3,0.9-2.4,0.9c-1.9,0-3.3-1.5-3.3-3.4
                                      C418,152.2,419.3,150.6,421.2,150.6z M422.3,153.2c0-0.6-0.6-1.1-1.2-1.1c-0.6,0-1.2,0.5-1.2,1.1H422.3z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M428.5,150.6c1,0,1.8,0.4,2.4,1.1c0.1,0.1,0.1,0.3-0.1,0.4l-0.7,0.7c-0.1,0.1-0.3,0-0.4,0
                                      c-0.3-0.3-0.7-0.5-1.1-0.5c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6c0.7,0,1-0.3,1.3-0.6c0.1-0.1,0.3-0.1,0.4,0l0.7,0.6
                                      c0.2,0.1,0.2,0.3,0.1,0.4c-0.5,0.8-1.4,1.3-2.5,1.3c-1.8,0-3.4-1.4-3.4-3.4C425.1,152.1,426.7,150.6,428.5,150.6z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M432.7,152.5h-0.5c-0.2,0-0.3-0.1-0.3-0.3v-1.1c0-0.2,0.1-0.3,0.3-0.3h0.5V149c0-0.2,0.1-0.3,0.3-0.3
                                      l1.3,0c0.2,0,0.3,0.1,0.3,0.3v1.8h1.4c0.2,0,0.3,0.1,0.3,0.3v1.1c0,0.2-0.1,0.3-0.3,0.3h-1.4v2.6c0,0.4,0.2,0.5,0.5,0.5
                                      c0.3,0,0.6-0.1,0.8-0.2c0.2-0.1,0.3,0,0.4,0.2l0.3,1c0.1,0.2,0,0.3-0.2,0.4c-0.1,0-1.1,0.4-1.9,0.4c-1.2,0-1.8-0.8-1.8-2.1V152.5
                                      z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M440,153.2c0.6,0,1.2,0.2,1.2,0.2c0-0.8-0.2-1.1-0.8-1.1c-0.6,0-1.4,0.1-1.8,0.2
                                      c-0.2,0.1-0.3-0.1-0.4-0.3l-0.1-0.8c0-0.2,0.1-0.4,0.2-0.4c0.1,0,1.2-0.3,2.2-0.3c2,0,2.5,1,2.5,2.8v3.5c0,0.2-0.1,0.3-0.3,0.3
                                      H442c-0.1,0-0.2,0-0.3-0.2l-0.2-0.4c-0.4,0.3-1,0.8-2,0.8c-1.3,0-2.2-0.8-2.2-2.2C437.4,154,438.4,153.2,440,153.2z M440,156
                                      c0.5,0,1-0.4,1.1-0.6v-0.9c0,0-0.4-0.2-0.9-0.2c-0.6,0-1,0.4-1,0.9C439.2,155.7,439.5,156,440,156z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M444.7,151.1c0-0.2,0.1-0.3,0.3-0.3h0.6c0.1,0,0.2,0.1,0.3,0.2l0.2,0.6c0.2-0.3,1-0.9,2.3-0.9
                                      c2,0,2.8,1.5,2.8,3.2v3.1c0,0.2-0.1,0.3-0.3,0.3h-1.3c-0.2,0-0.3-0.1-0.3-0.3v-3.2c0-0.8-0.4-1.4-1.2-1.4c-0.8,0-1.3,0.6-1.5,1
                                      v3.5c0,0.2-0.1,0.3-0.4,0.3H445c-0.2,0-0.3-0.1-0.3-0.3V151.1z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M455.9,150.6c1,0,1.8,0.4,2.4,1.1c0.1,0.1,0.1,0.3-0.1,0.4l-0.7,0.7c-0.1,0.1-0.3,0-0.4,0
                                      c-0.3-0.3-0.7-0.5-1.1-0.5c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6c0.7,0,1-0.3,1.3-0.6c0.1-0.1,0.3-0.1,0.4,0l0.7,0.6
                                      c0.2,0.1,0.2,0.3,0.1,0.4c-0.5,0.8-1.4,1.3-2.5,1.3c-1.8,0-3.4-1.4-3.4-3.4C452.5,152.1,454.2,150.6,455.9,150.6z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M459.7,147.4c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1s-0.5,1.1-1.1,1.1
                                      C460.2,148.5,459.7,148,459.7,147.4z M459.8,151.1c0-0.2,0.1-0.3,0.3-0.3h1.3c0.2,0,0.3,0.1,0.3,0.3v5.8c0,0.2-0.1,0.3-0.3,0.3
                                      h-1.3c-0.2,0-0.3-0.1-0.3-0.3V151.1z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M466.5,150.6c1.6,0,3,1.2,3,3c0,0.1,0,0.3,0,0.4c0,0.2-0.2,0.3-0.3,0.3H465c0,0.7,0.6,1.4,1.5,1.4
                                      c0.5,0,1-0.2,1.2-0.4c0.2-0.1,0.3-0.1,0.4,0l0.7,0.8c0.1,0.1,0.1,0.3,0,0.4c-0.5,0.5-1.3,0.9-2.4,0.9c-1.9,0-3.3-1.5-3.3-3.4
                                      C463.2,152.2,464.6,150.6,466.5,150.6z M467.6,153.2c0-0.6-0.6-1.1-1.2-1.1c-0.6,0-1.2,0.5-1.2,1.1H467.6z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M470.5,156.4l0.4-0.9c0.1-0.2,0.2-0.2,0.4-0.1c0,0,0.7,0.4,1.5,0.4c0.3,0,0.5-0.1,0.5-0.4
                                      c0-0.3-0.2-0.5-1.1-0.8c-1.2-0.5-1.8-1.1-1.8-2.1s0.7-1.9,2.4-1.9c0.9,0,1.6,0.3,2,0.5c0.1,0.1,0.2,0.3,0.1,0.4l-0.4,0.8
                                      c-0.1,0.1-0.3,0.2-0.4,0.1c0,0-0.7-0.3-1.3-0.3c-0.4,0-0.5,0.2-0.5,0.4c0,0.3,0.3,0.4,0.9,0.6c1.2,0.5,2.2,1,2.2,2.3
                                      c0,1.1-0.9,2-2.5,2c-1.1,0-1.8-0.3-2.1-0.6C470.5,156.7,470.4,156.6,470.5,156.4z"
                                                />
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path
                                                    fill="#414141"
                                                    d="M359.5,0.3c0-0.2,0.1-0.3,0.3-0.3h6.5c0.2,0,0.3,0.1,0.3,0.3v1.3c0,0.2-0.1,0.3-0.3,0.3h-4.7v2.6h4
                                      c0.2,0,0.3,0.1,0.3,0.3v1.3c0,0.2-0.1,0.3-0.3,0.3h-4v2.8h4.7c0.2,0,0.3,0.1,0.3,0.3v1.3c0,0.2-0.1,0.3-0.3,0.3h-6.5
                                      c-0.2,0-0.3-0.1-0.3-0.3V0.3z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M367.6,10.7l2.1-2.8l-2-2.7c-0.2-0.2,0-0.5,0.2-0.5h1.3c0.2,0,0.4,0,0.5,0.2l1,1.5h0l1.1-1.5
                                      c0.1-0.2,0.3-0.2,0.5-0.2h1.4c0.3,0,0.4,0.3,0.2,0.5l-2,2.7l2,2.8c0.1,0.2,0.1,0.5-0.2,0.5h-1.4c-0.2,0-0.3-0.1-0.4-0.3l-1.1-1.6
                                      h0l-1.2,1.7c0,0.1-0.2,0.1-0.3,0.1h-1.5C367.5,11.2,367.4,10.9,367.6,10.7z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M375.7,6.5h-0.5c-0.2,0-0.3-0.1-0.3-0.3V5.1c0-0.2,0.1-0.3,0.3-0.3h0.5V3c0-0.2,0.1-0.3,0.3-0.3l1.3,0
                                      c0.2,0,0.3,0.1,0.3,0.3v1.8h1.4c0.2,0,0.3,0.1,0.3,0.3v1.1c0,0.2-0.1,0.3-0.3,0.3h-1.4V9c0,0.4,0.2,0.5,0.5,0.5
                                      c0.3,0,0.6-0.1,0.8-0.2c0.2-0.1,0.3,0,0.4,0.2l0.3,1c0.1,0.2,0,0.3-0.2,0.4c-0.1,0-1.1,0.4-1.9,0.4c-1.2,0-1.8-0.8-1.8-2.1V6.5z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M383.7,4.6c1.6,0,3,1.2,3,3c0,0.1,0,0.3,0,0.4c0,0.2-0.2,0.3-0.3,0.3h-4.1c0,0.7,0.6,1.4,1.5,1.4
                                      c0.5,0,1-0.2,1.2-0.4c0.2-0.1,0.3-0.1,0.4,0l0.7,0.8c0.1,0.1,0.1,0.3,0,0.4c-0.5,0.5-1.3,0.9-2.4,0.9c-1.9,0-3.3-1.5-3.3-3.4
                                      C380.4,6.2,381.8,4.6,383.7,4.6z M384.8,7.2c0-0.6-0.6-1.1-1.2-1.1c-0.6,0-1.2,0.5-1.2,1.1H384.8z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M388,5.1c0-0.2,0.1-0.3,0.3-0.3h0.6c0.1,0,0.2,0.1,0.3,0.2l0.2,0.6c0.2-0.3,0.8-1,1.8-1
                                      c0.8,0,1.4,0.2,1.2,0.6l-0.6,1c-0.1,0.1-0.2,0.2-0.4,0.1c0,0-0.3-0.1-0.5-0.1c-0.6,0-1,0.4-1.1,0.7v3.9c0,0.2-0.1,0.3-0.4,0.3
                                      h-1.2c-0.2,0-0.3-0.1-0.3-0.3V5.1z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M393.5,5.1c0-0.2,0.1-0.3,0.3-0.3h0.6c0.1,0,0.2,0.1,0.3,0.2l0.2,0.6c0.2-0.3,1-0.9,2.3-0.9
                                      c2,0,2.8,1.5,2.8,3.2v3.1c0,0.2-0.1,0.3-0.3,0.3h-1.3c-0.2,0-0.3-0.1-0.3-0.3V7.7c0-0.8-0.4-1.4-1.2-1.4c-0.8,0-1.3,0.6-1.5,1
                                      v3.5c0,0.2-0.1,0.3-0.4,0.3h-1.2c-0.2,0-0.3-0.1-0.3-0.3V5.1z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M403.9,7.2c0.6,0,1.2,0.2,1.2,0.2c0-0.8-0.2-1.1-0.8-1.1c-0.6,0-1.4,0.1-1.8,0.2
                                      c-0.2,0.1-0.3-0.1-0.4-0.3l-0.1-0.8c0-0.2,0.1-0.4,0.2-0.4c0.1,0,1.2-0.3,2.2-0.3c2,0,2.5,1,2.5,2.8v3.5c0,0.2-0.1,0.3-0.3,0.3
                                      h-0.6c-0.1,0-0.2,0-0.3-0.2l-0.2-0.4c-0.4,0.3-1,0.8-2,0.8c-1.3,0-2.2-0.8-2.2-2.2C401.3,8,402.2,7.2,403.9,7.2z M403.9,10
                                      c0.5,0,1-0.4,1.1-0.6V8.5c0,0-0.4-0.2-0.9-0.2c-0.6,0-1,0.4-1,0.9C403.1,9.7,403.4,10,403.9,10z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M408.6,0.3c0-0.2,0.1-0.3,0.3-0.3h1.3c0.2,0,0.3,0.1,0.3,0.3v10.6c0,0.2-0.1,0.3-0.3,0.3h-1.3
                                      c-0.2,0-0.3-0.1-0.3-0.3V0.3z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M416.5,0.3c0-0.2,0.1-0.3,0.3-0.3h3.8c2,0,3.6,1.6,3.6,3.5c0,2-1.6,3.6-3.6,3.6h-2v3.8
                                      c0,0.2-0.1,0.3-0.3,0.3h-1.5c-0.2,0-0.3-0.1-0.3-0.3V0.3z M420.4,5.2c0.9,0,1.6-0.7,1.6-1.7c0-0.9-0.8-1.6-1.6-1.6h-1.9v3.2
                                      H420.4z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M425,5.1c0-0.2,0.1-0.3,0.3-0.3h0.6c0.1,0,0.2,0.1,0.3,0.2l0.2,0.6c0.2-0.3,0.8-1,1.8-1
                                      c0.8,0,1.4,0.2,1.2,0.6l-0.6,1c-0.1,0.1-0.2,0.2-0.4,0.1c0,0-0.3-0.1-0.5-0.1c-0.6,0-1,0.4-1.1,0.7v3.9c0,0.2-0.1,0.3-0.4,0.3
                                      h-1.2c-0.2,0-0.3-0.1-0.3-0.3V5.1z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M433.2,4.6c1.8,0,3.3,1.5,3.3,3.3c0,1.8-1.5,3.4-3.3,3.4c-1.8,0-3.3-1.5-3.3-3.4
                                      C430,6.2,431.4,4.6,433.2,4.6z M433.2,9.6c0.8,0,1.5-0.7,1.5-1.6c0-0.9-0.7-1.6-1.5-1.6c-0.9,0-1.5,0.7-1.5,1.6
                                      C431.7,8.9,432.4,9.6,433.2,9.6z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M437.1,5.2c-0.1-0.2,0-0.4,0.2-0.4h1.4c0.2,0,0.2,0.1,0.3,0.2l1.3,3c0,0,0,0,0,0l1.4-3
                                      c0.1-0.2,0.2-0.2,0.4-0.2h1.2c0.2,0,0.3,0.2,0.2,0.4l-2.9,6c0,0.1-0.1,0.2-0.3,0.2h-0.1c-0.1,0-0.2-0.1-0.3-0.2L437.1,5.2z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M444.5,1.4c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1s-0.5,1.1-1.1,1.1C445,2.5,444.5,2,444.5,1.4z
                                       M444.6,5.1c0-0.2,0.1-0.3,0.3-0.3h1.3c0.2,0,0.3,0.1,0.3,0.3v5.8c0,0.2-0.1,0.3-0.3,0.3h-1.3c-0.2,0-0.3-0.1-0.3-0.3V5.1z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M451.2,4.6c0.6,0,1.1,0.2,1.4,0.3V0.3c0-0.2,0.1-0.3,0.3-0.3h1.4c0.2,0,0.3,0.1,0.3,0.3v10.6
                                      c0,0.2-0.1,0.3-0.3,0.3h-0.6c-0.1,0-0.3-0.1-0.3-0.3l-0.2-0.5c0,0-0.8,1-2.1,1c-1.7,0-3.1-1.5-3.1-3.4
                                      C448,6.1,449.3,4.6,451.2,4.6z M451.3,9.6c0.8,0,1.2-0.7,1.3-1V6.7c0,0-0.4-0.4-1.2-0.4c-0.9,0-1.6,0.7-1.6,1.6
                                      S450.5,9.6,451.3,9.6z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M459.3,4.6c1.6,0,3,1.2,3,3c0,0.1,0,0.3,0,0.4c0,0.2-0.2,0.3-0.3,0.3h-4.1c0,0.7,0.6,1.4,1.5,1.4
                                      c0.5,0,1-0.2,1.2-0.4c0.2-0.1,0.3-0.1,0.4,0l0.7,0.8c0.1,0.1,0.1,0.3,0,0.4c-0.5,0.5-1.3,0.9-2.4,0.9c-1.9,0-3.3-1.5-3.3-3.4
                                      C456,6.2,457.4,4.6,459.3,4.6z M460.4,7.2c0-0.6-0.6-1.1-1.2-1.1c-0.6,0-1.2,0.5-1.2,1.1H460.4z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M463.6,5.1c0-0.2,0.1-0.3,0.3-0.3h0.6c0.1,0,0.2,0.1,0.3,0.2l0.2,0.6c0.2-0.3,0.8-1,1.8-1
                                      c0.8,0,1.4,0.2,1.2,0.6l-0.6,1c-0.1,0.1-0.2,0.2-0.4,0.1c0,0-0.3-0.1-0.5-0.1c-0.6,0-1,0.4-1.1,0.7v3.9c0,0.2-0.1,0.3-0.4,0.3
                                      h-1.2c-0.2,0-0.3-0.1-0.3-0.3V5.1z"
                                                />
                                                <path
                                                    fill="#414141"
                                                    d="M468.7,10.4l0.4-0.9c0.1-0.2,0.2-0.2,0.4-0.1c0,0,0.7,0.4,1.5,0.4c0.3,0,0.5-0.1,0.5-0.4
                                      c0-0.3-0.2-0.5-1.1-0.8c-1.2-0.5-1.8-1.1-1.8-2.1s0.7-1.9,2.4-1.9c0.9,0,1.6,0.3,2,0.5c0.1,0.1,0.2,0.3,0.1,0.4l-0.4,0.8
                                      c-0.1,0.1-0.3,0.2-0.4,0.1c0,0-0.7-0.3-1.3-0.3c-0.4,0-0.5,0.2-0.5,0.4c0,0.3,0.3,0.4,0.9,0.6c1.2,0.5,2.2,1,2.2,2.3
                                      c0,1.1-0.9,2-2.5,2c-1.1,0-1.8-0.3-2.1-0.6C468.7,10.7,468.6,10.6,468.7,10.4z"
                                                />
                                            </g>
                                        </g>
                                        <rect x="320.2" y="24.1" fill="#A64786" width="50" height="50" />
                                        <rect x="461.2" y="24.1" fill="#A64786" width="50" height="50" />
                                        <rect x="391" y="24.1" fill="#A64786" width="50" height="50" />

                                        <line
                                            id="pathTwo"
                                            fill="none"
                                            stroke="#414141"
                                            x1="345.2"
                                            y1="74.1"
                                            x2="345.2"
                                            y2="124.1"
                                        />

                                        <line
                                            id="pathThree"
                                            fill="none"
                                            stroke="#414141"
                                            x1="416"
                                            y1="74.1"
                                            x2="416"
                                            y2="124.1"
                                        />

                                        <line
                                            id="pathFour"
                                            fill="none"
                                            stroke="#414141"
                                            x1="486.2"
                                            y1="74.1"
                                            x2="486.2"
                                            y2="124.1"
                                        />
                                        <g>
                                            <path
                                                fill="#FFFFFF"
                                                d="M344.1,45.3l-2.1,0.5c-0.2,0-0.5-0.1-0.5-0.3l-0.1-1.2c0-0.2,0-0.3,0.1-0.4l3.5-2.9h1.7
                                  c0.2,0,0.4,0.2,0.4,0.5v15.4c0,0.2-0.2,0.5-0.4,0.5h-2.1c-0.3,0-0.5-0.2-0.5-0.5V45.3z"
                                            />
                                        </g>
                                        <g>
                                            <path
                                                fill="#FFFFFF"
                                                d="M410.5,56.2c1.6-1.9,4.6-5.9,6.2-8c0.7-0.9,1.2-1.8,1.2-2.7c0-1.1-0.8-2.2-2.5-2.2c-1.2,0-2.1,0.8-2.7,1.4
                                  c-0.2,0.2-0.5,0.2-0.6,0l-1.2-1.2c-0.1-0.1-0.2-0.4,0-0.6c0,0,1.9-2.4,4.7-2.4c3.4,0,5.4,2.2,5.4,4.8c0,1.6-0.7,2.5-1.6,3.9
                                  c-1,1.5-2.9,3.7-4.1,5.3h5.2c0.2,0,0.5,0.2,0.5,0.5v1.7c0,0.2-0.2,0.5-0.5,0.5h-9.5c-0.3,0-0.5-0.2-0.5-0.5V56.2z"
                                            />
                                        </g>
                                        <g>
                                            <path
                                                fill="#FFFFFF"
                                                d="M481.3,53.5c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2S480.2,53.5,481.3,53.5z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M487,53.5c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2S485.9,53.5,487,53.5z"
                                            />
                                            <path
                                                fill="#FFFFFF"
                                                d="M492.6,53.5c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2S491.5,53.5,492.6,53.5z"
                                            />
                                        </g>
                                        <g>
                                            <path
                                                fill="#414141"
                                                d="M391.5,385.1h-2.1c-0.2,0-0.3-0.1-0.3-0.3v-1.1c0-0.1,0.1-0.3,0.3-0.3h6.1c0.2,0,0.3,0.1,0.3,0.3v1.1
                                  c0,0.1-0.1,0.3-0.3,0.3h-2.1v7.9c0,0.1-0.1,0.3-0.3,0.3h-1.3c-0.1,0-0.3-0.1-0.3-0.3V385.1z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M396,387.9c0-0.2,0.1-0.3,0.3-0.3h0.5c0.1,0,0.2,0.1,0.3,0.2l0.2,0.5c0.2-0.3,0.7-0.9,1.6-0.9
                                  c0.7,0,1.2,0.2,1.1,0.6l-0.5,0.9c-0.1,0.1-0.2,0.2-0.3,0.1c0,0-0.3-0.1-0.4-0.1c-0.6,0-0.9,0.4-1,0.6v3.4c0,0.2-0.1,0.3-0.3,0.3
                                  h-1.1c-0.1,0-0.3-0.1-0.3-0.3V387.9z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M402.6,389.7c0.6,0,1,0.2,1,0.2c0-0.7-0.2-1-0.7-1c-0.5,0-1.2,0.1-1.6,0.2c-0.2,0.1-0.3-0.1-0.3-0.3
                                  l-0.1-0.7c0-0.2,0.1-0.3,0.2-0.3c0.1,0,1-0.3,1.9-0.3c1.8,0,2.2,0.9,2.2,2.4v3c0,0.1-0.1,0.3-0.3,0.3h-0.5c-0.1,0-0.2,0-0.2-0.2
                                  l-0.2-0.4c-0.3,0.3-0.8,0.7-1.7,0.7c-1.1,0-1.9-0.7-1.9-1.9C400.3,390.4,401.1,389.7,402.6,389.7z M402.6,392.2
                                  c0.4,0,0.9-0.4,1-0.5v-0.8c0,0-0.4-0.2-0.8-0.2c-0.5,0-0.9,0.3-0.9,0.8C401.9,391.8,402.2,392.2,402.6,392.2z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M406.7,387.9c0-0.2,0.1-0.3,0.3-0.3h0.5c0.1,0,0.2,0.1,0.2,0.2l0.2,0.5c0.2-0.2,0.9-0.8,2-0.8
                                  c1.8,0,2.5,1.3,2.5,2.8v2.7c0,0.1-0.1,0.3-0.3,0.3H411c-0.2,0-0.3-0.1-0.3-0.3v-2.8c0-0.7-0.4-1.2-1.1-1.2c-0.7,0-1.2,0.5-1.3,0.9
                                  v3.1c0,0.2-0.1,0.3-0.4,0.3h-1c-0.1,0-0.3-0.1-0.3-0.3V387.9z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M413.7,392.5l0.4-0.8c0.1-0.1,0.2-0.2,0.3-0.1c0,0,0.6,0.3,1.3,0.3c0.3,0,0.5-0.1,0.5-0.4
                                  c0-0.3-0.2-0.4-0.9-0.7c-1.1-0.4-1.6-1-1.6-1.8c0-0.9,0.6-1.7,2.1-1.7c0.8,0,1.4,0.2,1.7,0.4c0.1,0.1,0.2,0.2,0.1,0.4l-0.4,0.7
                                  c-0.1,0.1-0.2,0.1-0.3,0.1c0,0-0.6-0.3-1.2-0.3c-0.3,0-0.5,0.1-0.5,0.3c0,0.3,0.3,0.3,0.8,0.5c1.1,0.4,1.9,0.9,1.9,2
                                  c0,0.9-0.8,1.7-2.2,1.7c-0.9,0-1.6-0.3-1.9-0.5C413.7,392.8,413.6,392.6,413.7,392.5z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M419.4,389.1h-0.5c-0.2,0-0.3-0.1-0.3-0.3v-0.9c0-0.2,0.1-0.3,0.3-0.3h0.5v-1.5c0-1.8,1.1-2.8,2.6-2.8
                                  c0.7,0,1.4,0.3,1.7,0.5c0.1,0.1,0.1,0.2,0.1,0.3l-0.5,0.9c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2-0.1-0.5-0.3-0.8-0.3
                                  c-0.6,0-0.9,0.5-0.9,1.3v1.5h1.4c0.2,0,0.3,0.1,0.3,0.3v0.9c0,0.1-0.1,0.3-0.3,0.3h-1.4v3.9c0,0.1-0.1,0.3-0.3,0.3h-1.2
                                  c-0.1,0-0.3-0.1-0.3-0.3V389.1z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M426.3,387.5c1.6,0,2.9,1.3,2.9,2.9c0,1.6-1.3,3-2.9,3s-2.9-1.3-2.9-3
                                  C423.4,388.8,424.7,387.5,426.3,387.5z M426.3,391.8c0.7,0,1.3-0.6,1.3-1.4c0-0.8-0.6-1.4-1.3-1.4c-0.8,0-1.3,0.6-1.3,1.4
                                  C425,391.2,425.6,391.8,426.3,391.8z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M430.4,387.9c0-0.2,0.1-0.3,0.3-0.3h0.5c0.1,0,0.2,0.1,0.3,0.2l0.2,0.5c0.2-0.3,0.7-0.9,1.6-0.9
                                  c0.7,0,1.2,0.2,1.1,0.6l-0.5,0.9c-0.1,0.1-0.2,0.2-0.3,0.1c0,0-0.3-0.1-0.4-0.1c-0.6,0-0.9,0.4-1,0.6v3.4c0,0.2-0.1,0.3-0.3,0.3
                                  h-1.1c-0.1,0-0.3-0.1-0.3-0.3V387.9z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M435.2,387.9c0-0.2,0.1-0.3,0.3-0.3h0.5c0.1,0,0.2,0.1,0.2,0.2l0.2,0.5c0.2-0.3,0.9-0.8,1.9-0.8
                                  c0.8,0,1.4,0.3,1.8,0.9c0.2-0.3,1-0.9,2-0.9c1.8,0,2.5,1.3,2.5,2.8v2.7c0,0.1-0.1,0.3-0.3,0.3h-1.1c-0.2,0-0.3-0.1-0.3-0.3v-2.8
                                  c0-0.7-0.4-1.2-1.1-1.2c-0.8,0-1.2,0.6-1.2,0.6s0,0.3,0,0.5v2.8c0,0.1-0.1,0.3-0.3,0.3h-1.1c-0.2,0-0.3-0.1-0.3-0.3v-2.8
                                  c0-0.7-0.3-1.2-1-1.2c-0.7,0-1,0.5-1.2,0.9v3.1c0,0.1-0.1,0.3-0.3,0.3h-1.1c-0.1,0-0.3-0.1-0.3-0.3V387.9z"
                                            />
                                        </g>
                                        <g>
                                            <path
                                                fill="#414141"
                                                d="M597.2,382.5c0-0.1,0.1-0.2,0.3-0.2h0.2c0.1,0,0.2,0.1,0.2,0.1l3.1,6.6c0,0,0,0,0,0l3.1-6.6
                                  c0-0.1,0.1-0.1,0.2-0.1h0.2c0.2,0,0.2,0.1,0.3,0.2l1.7,9.4c0,0.2-0.1,0.3-0.3,0.3H605c-0.1,0-0.2-0.1-0.3-0.2l-0.8-5.3
                                  c0,0,0,0,0,0l-2.5,5.5c0,0.1-0.1,0.2-0.2,0.2h-0.3c-0.1,0-0.2-0.1-0.2-0.2l-2.5-5.5c0,0,0,0,0,0l-0.8,5.3c0,0.1-0.1,0.2-0.3,0.2
                                  h-1.3c-0.2,0-0.3-0.1-0.3-0.3L597.2,382.5z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M610.2,386.5c1.6,0,2.9,1.3,2.9,2.9c0,1.6-1.3,3-2.9,3c-1.6,0-2.9-1.3-2.9-3
                                  C607.4,387.8,608.7,386.5,610.2,386.5z M610.2,390.8c0.7,0,1.3-0.6,1.3-1.4c0-0.8-0.6-1.4-1.3-1.4c-0.8,0-1.3,0.6-1.3,1.4
                                  C608.9,390.2,609.5,390.8,610.2,390.8z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M614.4,386.9c0-0.2,0.1-0.3,0.3-0.3h0.5c0.1,0,0.2,0.1,0.2,0.2l0.2,0.5c0.2-0.2,0.9-0.8,2-0.8
                                  c1.8,0,2.5,1.3,2.5,2.8v2.7c0,0.1-0.1,0.3-0.3,0.3h-1.1c-0.2,0-0.3-0.1-0.3-0.3v-2.8c0-0.7-0.4-1.2-1.1-1.2
                                  c-0.7,0-1.2,0.5-1.3,0.9v3.1c0,0.2-0.1,0.3-0.4,0.3h-1c-0.1,0-0.3-0.1-0.3-0.3V386.9z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M621.5,383.6c0-0.5,0.4-1,1-1c0.5,0,1,0.4,1,1s-0.4,1-1,1C621.9,384.6,621.5,384.2,621.5,383.6z
                                   M621.6,386.9c0-0.2,0.1-0.3,0.3-0.3h1.2c0.1,0,0.3,0.1,0.3,0.3v5.1c0,0.1-0.1,0.3-0.3,0.3h-1.2c-0.1,0-0.3-0.1-0.3-0.3V386.9z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M625.3,388.1h-0.5c-0.1,0-0.3-0.1-0.3-0.3v-0.9c0-0.2,0.1-0.3,0.3-0.3h0.5V385c0-0.1,0.1-0.3,0.3-0.3
                                  l1.1,0c0.1,0,0.3,0.1,0.3,0.3v1.6h1.2c0.2,0,0.3,0.1,0.3,0.3v0.9c0,0.1-0.1,0.3-0.3,0.3H627v2.2c0,0.4,0.2,0.4,0.4,0.4
                                  c0.2,0,0.5-0.1,0.7-0.2s0.3,0,0.3,0.2l0.3,0.9c0.1,0.1,0,0.3-0.2,0.3c-0.1,0-1,0.4-1.7,0.4c-1.1,0-1.6-0.7-1.6-1.8V388.1z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M632.3,386.5c1.6,0,2.9,1.3,2.9,2.9c0,1.6-1.3,3-2.9,3c-1.6,0-2.9-1.3-2.9-3
                                  C629.4,387.8,630.7,386.5,632.3,386.5z M632.3,390.8c0.7,0,1.3-0.6,1.3-1.4c0-0.8-0.6-1.4-1.3-1.4c-0.8,0-1.3,0.6-1.3,1.4
                                  C630.9,390.2,631.5,390.8,632.3,390.8z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M636.4,386.9c0-0.2,0.1-0.3,0.3-0.3h0.5c0.1,0,0.2,0.1,0.3,0.2l0.2,0.5c0.2-0.3,0.7-0.9,1.6-0.9
                                  c0.7,0,1.2,0.2,1.1,0.6l-0.5,0.9c-0.1,0.1-0.2,0.2-0.3,0.1c0,0-0.3-0.1-0.4-0.1c-0.6,0-0.9,0.4-1,0.6v3.4c0,0.2-0.1,0.3-0.3,0.3
                                  h-1.1c-0.1,0-0.3-0.1-0.3-0.3V386.9z"
                                            />
                                        </g>
                                        <g>
                                            <path
                                                fill="#414141"
                                                d="M282.9,255.8l4.4-9.4c0-0.1,0.1-0.2,0.2-0.2h0.1c0.1,0,0.2,0.1,0.2,0.2l4.3,9.4c0.1,0.2,0,0.4-0.2,0.4
                                  h-1.2c-0.2,0-0.3-0.1-0.4-0.3l-0.7-1.5h-4.2l-0.7,1.5c-0.1,0.1-0.2,0.3-0.4,0.3h-1.2C283,256.2,282.8,256,282.9,255.8z M289,252.9
                                  l-1.4-3.1h0l-1.4,3.1H289z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M295.3,250.5c0.6,0,1,0.2,1.2,0.3v-4c0-0.1,0.1-0.3,0.3-0.3h1.2c0.1,0,0.3,0.1,0.3,0.3v9.3
                                  c0,0.1-0.1,0.3-0.3,0.3h-0.5c-0.1,0-0.2-0.1-0.3-0.3l-0.1-0.4c0,0-0.7,0.8-1.8,0.8c-1.5,0-2.7-1.3-2.7-2.9
                                  C292.5,251.8,293.6,250.5,295.3,250.5z M295.4,254.8c0.7,0,1.1-0.6,1.2-0.9v-1.6c0,0-0.4-0.3-1.1-0.3c-0.8,0-1.4,0.6-1.4,1.4
                                  C294.1,254.2,294.7,254.8,295.4,254.8z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M299.9,250.9c0-0.2,0.1-0.3,0.3-0.3h1.2c0.1,0,0.3,0.1,0.3,0.3v6.8c0,1.8-1.1,2.8-2.6,2.8
                                  c-0.7,0-1.4-0.3-1.7-0.5c-0.1-0.1-0.1-0.2-0.1-0.3l0.5-0.9c0.1-0.1,0.2-0.1,0.3-0.1c0.2,0.1,0.5,0.3,0.8,0.3c0.6,0,1-0.5,1-1.3
                                  V250.9z M299.8,247.6c0-0.5,0.4-1,1-1c0.5,0,1,0.4,1,1s-0.4,1-1,1C300.2,248.6,299.8,248.2,299.8,247.6z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M303.2,250.9c0-0.2,0.1-0.3,0.3-0.3h1.1c0.1,0,0.3,0.1,0.3,0.3v2.8c0,0.7,0.4,1.2,1,1.2
                                  c0.6,0,1-0.5,1.1-1.1v-2.9c0-0.2,0.1-0.3,0.3-0.3h1.1c0.1,0,0.3,0.1,0.3,0.3v5.1c0,0.1-0.1,0.3-0.3,0.3h-0.5
                                  c-0.1,0-0.2-0.1-0.3-0.2l-0.2-0.5c-0.3,0.2-0.8,0.8-1.9,0.8c-1.7,0-2.4-1.3-2.4-2.8V250.9z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M310.1,255.5l0.4-0.8c0.1-0.1,0.2-0.2,0.3-0.1c0,0,0.6,0.3,1.3,0.3c0.3,0,0.5-0.1,0.5-0.4
                                  c0-0.3-0.2-0.4-0.9-0.7c-1.1-0.4-1.6-1-1.6-1.8s0.6-1.7,2.1-1.7c0.8,0,1.4,0.2,1.7,0.4c0.1,0.1,0.2,0.2,0.1,0.4l-0.4,0.7
                                  c-0.1,0.1-0.2,0.1-0.3,0.1c0,0-0.6-0.3-1.2-0.3c-0.3,0-0.5,0.1-0.5,0.3c0,0.3,0.3,0.3,0.8,0.5c1.1,0.4,1.9,0.9,1.9,2
                                  c0,0.9-0.8,1.7-2.2,1.7c-0.9,0-1.6-0.3-1.9-0.5C310.1,255.8,310,255.6,310.1,255.5z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M315.8,252.1h-0.5c-0.1,0-0.3-0.1-0.3-0.3v-0.9c0-0.2,0.1-0.3,0.3-0.3h0.5V249c0-0.1,0.1-0.3,0.3-0.3
                                  l1.1,0c0.1,0,0.3,0.1,0.3,0.3v1.6h1.2c0.2,0,0.3,0.1,0.3,0.3v0.9c0,0.1-0.1,0.3-0.3,0.3h-1.2v2.2c0,0.4,0.2,0.4,0.4,0.4
                                  c0.2,0,0.5-0.1,0.7-0.2c0.2-0.1,0.3,0,0.3,0.2l0.3,0.9c0.1,0.1,0,0.3-0.2,0.3c-0.1,0-1,0.4-1.7,0.4c-1.1,0-1.6-0.7-1.6-1.8V252.1z
                                  "
                                            />
                                        </g>
                                        <g>
                                            <path
                                                fill="#414141"
                                                d="M180.2,192.5c0-0.1,0.1-0.2,0.3-0.2h0.2c0.1,0,0.2,0.1,0.2,0.1l3.1,6.6c0,0,0,0,0,0l3.1-6.6
                                  c0-0.1,0.1-0.1,0.2-0.1h0.2c0.2,0,0.2,0.1,0.3,0.2l1.7,9.4c0,0.2-0.1,0.3-0.3,0.3H188c-0.1,0-0.2-0.1-0.3-0.2l-0.8-5.3
                                  c0,0,0,0,0,0l-2.5,5.5c0,0.1-0.1,0.2-0.2,0.2h-0.3c-0.1,0-0.2-0.1-0.2-0.2l-2.5-5.5c0,0,0,0,0,0l-0.8,5.3c0,0.1-0.1,0.2-0.3,0.2
                                  h-1.3c-0.2,0-0.3-0.1-0.3-0.3L180.2,192.5z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M193.2,196.5c1.4,0,2.6,1.1,2.6,2.6c0,0.1,0,0.3,0,0.4c0,0.1-0.1,0.2-0.3,0.2H192c0,0.6,0.5,1.2,1.3,1.2
                                  c0.4,0,0.8-0.2,1.1-0.4c0.1-0.1,0.3-0.1,0.4,0l0.6,0.7c0.1,0.1,0.1,0.3,0,0.4c-0.5,0.4-1.2,0.8-2.1,0.8c-1.7,0-2.9-1.3-2.9-2.9
                                  C190.4,197.8,191.6,196.5,193.2,196.5z M194.2,198.7c0-0.5-0.5-1-1-1c-0.6,0-1,0.4-1.1,1H194.2z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M197,196.9c0-0.2,0.1-0.3,0.3-0.3h0.5c0.1,0,0.2,0.1,0.3,0.2l0.2,0.5c0.2-0.3,0.7-0.9,1.6-0.9
                                  c0.7,0,1.2,0.2,1.1,0.6l-0.5,0.9c-0.1,0.1-0.2,0.2-0.3,0.1c0,0-0.3-0.1-0.4-0.1c-0.6,0-0.9,0.4-1,0.6v3.4c0,0.2-0.1,0.3-0.3,0.3
                                  h-1.1c-0.1,0-0.3-0.1-0.3-0.3V196.9z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M202.1,200.2c0,0-0.4-0.5-0.4-1.3c0-1.3,1.1-2.4,2.4-2.4h2.5c0.2,0,0.3,0.1,0.3,0.3v0.5
                                  c0,0.1-0.1,0.2-0.2,0.2l-0.8,0.3c0,0,0.6,0.4,0.6,1.4c0,1.1-0.9,2.2-2.4,2.2c-0.5,0-0.9-0.1-1.1-0.1c-0.2,0-0.5,0.2-0.5,0.5
                                  c0,0.3,0.3,0.5,0.6,0.5h1.8c1.3,0,2.3,0.7,2.3,2c0,1.3-1,2.4-3.1,2.4c-2.1,0-2.9-1-2.9-1.9c0-0.8,0.7-1.2,0.8-1.3v0
                                  c-0.2-0.1-1.1-0.4-1.1-1.5C201,200.7,202.1,200.2,202.1,200.2z M204.1,205.1c0.8,0,1.3-0.4,1.3-0.9c0-0.3-0.2-0.7-1-0.7
                                  c-0.3,0-0.6,0-0.9,0.1c-0.2,0.1-0.6,0.3-0.6,0.7C202.8,204.8,203.3,205.1,204.1,205.1z M204.1,200c0.6,0,1-0.4,1-1s-0.4-1-1-1
                                  c-0.6,0-1,0.4-1,1S203.6,200,204.1,200z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M210.5,196.5c1.4,0,2.6,1.1,2.6,2.6c0,0.1,0,0.3,0,0.4c0,0.1-0.1,0.2-0.3,0.2h-3.6c0,0.6,0.5,1.2,1.3,1.2
                                  c0.4,0,0.8-0.2,1.1-0.4c0.1-0.1,0.3-0.1,0.4,0l0.6,0.7c0.1,0.1,0.1,0.3,0,0.4c-0.5,0.4-1.2,0.8-2.1,0.8c-1.7,0-2.9-1.3-2.9-2.9
                                  C207.7,197.8,208.9,196.5,210.5,196.5z M211.5,198.7c0-0.5-0.5-1-1-1c-0.6,0-1,0.4-1.1,1H211.5z"
                                            />
                                        </g>
                                        <g>
                                            <path
                                                fill="#414141"
                                                d="M537.2,250.7c0-0.1,0.1-0.3,0.3-0.3h5.7c0.2,0,0.3,0.1,0.3,0.3v1.1c0,0.1-0.1,0.3-0.3,0.3H539v2.3h3.5
                                  c0.1,0,0.3,0.1,0.3,0.3v1.2c0,0.2-0.1,0.3-0.3,0.3H539v2.5h4.1c0.2,0,0.3,0.1,0.3,0.3v1.1c0,0.1-0.1,0.3-0.3,0.3h-5.7
                                  c-0.2,0-0.3-0.1-0.3-0.3V250.7z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M544.3,259.8l1.8-2.5l-1.8-2.3c-0.1-0.2,0-0.4,0.2-0.4h1.2c0.2,0,0.3,0,0.5,0.2l0.9,1.3h0l0.9-1.3
                                  c0.1-0.2,0.2-0.2,0.4-0.2h1.3c0.2,0,0.3,0.2,0.2,0.4l-1.8,2.3l1.8,2.5c0.1,0.2,0.1,0.4-0.2,0.4h-1.2c-0.2,0-0.3-0.1-0.4-0.2
                                  l-1-1.4h0l-1.1,1.5c0,0.1-0.2,0.1-0.2,0.1h-1.3C544.2,260.2,544.2,260,544.3,259.8z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M552.9,264.1c0,0.2-0.1,0.3-0.3,0.3h-1.1c-0.1,0-0.3-0.1-0.3-0.3v-9.3c0-0.2,0.1-0.3,0.3-0.3h0.5
                                  c0.1,0,0.2,0.1,0.2,0.2l0.2,0.5c0.2-0.3,0.9-0.8,1.8-0.8c1.5,0,2.7,1.3,2.7,2.9c0,1.7-1.3,2.9-2.8,2.9c-0.5,0-1.1-0.2-1.2-0.3
                                  V264.1z M553.9,258.8c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.5-1.4-1.3-1.4c-0.7,0-1.1,0.6-1.1,0.9v1.7
                                  C552.8,258.5,553.2,258.8,553.9,258.8z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M560,256.7c0.6,0,1,0.2,1,0.2c0-0.7-0.2-1-0.7-1c-0.5,0-1.2,0.1-1.6,0.2c-0.2,0.1-0.3-0.1-0.3-0.3
                                  l-0.1-0.7c0-0.2,0.1-0.3,0.2-0.4c0.1,0,1-0.3,1.9-0.3c1.8,0,2.2,0.9,2.2,2.4v3c0,0.1-0.1,0.3-0.3,0.3h-0.5c-0.1,0-0.2,0-0.2-0.2
                                  l-0.2-0.4c-0.3,0.3-0.8,0.7-1.7,0.7c-1.1,0-1.9-0.7-1.9-1.9C557.7,257.4,558.5,256.7,560,256.7z M560,259.2c0.4,0,0.9-0.4,1-0.5
                                  v-0.8c0,0-0.4-0.2-0.8-0.2c-0.5,0-0.9,0.3-0.9,0.8C559.3,258.8,559.6,259.2,560,259.2z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M564.1,254.9c0-0.2,0.1-0.3,0.3-0.3h0.5c0.1,0,0.2,0.1,0.2,0.2l0.2,0.5c0.2-0.2,0.9-0.8,2-0.8
                                  c1.8,0,2.5,1.3,2.5,2.8v2.7c0,0.1-0.1,0.3-0.3,0.3h-1.1c-0.2,0-0.3-0.1-0.3-0.3v-2.8c0-0.7-0.4-1.2-1.1-1.2
                                  c-0.7,0-1.2,0.5-1.3,0.9v3.1c0,0.2-0.1,0.3-0.4,0.3h-1c-0.1,0-0.3-0.1-0.3-0.3V254.9z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M573.7,254.5c0.6,0,1,0.2,1.2,0.3v-4c0-0.1,0.1-0.3,0.3-0.3h1.2c0.1,0,0.3,0.1,0.3,0.3v9.3
                                  c0,0.1-0.1,0.3-0.3,0.3h-0.5c-0.1,0-0.2-0.1-0.3-0.3l-0.1-0.4c0,0-0.7,0.8-1.8,0.8c-1.5,0-2.7-1.3-2.7-2.9
                                  C571,255.8,572.1,254.5,573.7,254.5z M573.9,258.8c0.7,0,1.1-0.6,1.2-0.9v-1.6c0,0-0.4-0.3-1.1-0.3c-0.8,0-1.4,0.6-1.4,1.4
                                  C572.6,258.2,573.1,258.8,573.9,258.8z"
                                            />
                                        </g>
                                        <g>
                                            <path
                                                fill="#414141"
                                                d="M365.2,95.7c0-0.1,0.1-0.3,0.3-0.3h5.7c0.2,0,0.3,0.1,0.3,0.3v1.1c0,0.1-0.1,0.3-0.3,0.3H367v2.3h3.5
                                  c0.1,0,0.3,0.1,0.3,0.3v1.2c0,0.2-0.1,0.3-0.3,0.3H367v2.5h4.1c0.2,0,0.3,0.1,0.3,0.3v1.1c0,0.1-0.1,0.3-0.3,0.3h-5.7
                                  c-0.2,0-0.3-0.1-0.3-0.3V95.7z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M372.3,104.8l1.8-2.5l-1.8-2.3c-0.1-0.2,0-0.4,0.2-0.4h1.2c0.2,0,0.3,0,0.5,0.2l0.9,1.3h0l0.9-1.3
                                  c0.1-0.2,0.2-0.2,0.4-0.2h1.3c0.2,0,0.3,0.2,0.2,0.4l-1.8,2.3l1.8,2.5c0.1,0.2,0.1,0.4-0.2,0.4h-1.2c-0.2,0-0.3-0.1-0.4-0.2
                                  l-1-1.4h0l-1.1,1.5c0,0.1-0.2,0.1-0.2,0.1h-1.3C372.3,105.2,372.2,105,372.3,104.8z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M379.4,101.1H379c-0.1,0-0.3-0.1-0.3-0.3v-0.9c0-0.2,0.1-0.3,0.3-0.3h0.5V98c0-0.1,0.1-0.3,0.3-0.3l1.1,0
                                  c0.1,0,0.3,0.1,0.3,0.3v1.6h1.2c0.2,0,0.3,0.1,0.3,0.3v0.9c0,0.1-0.1,0.3-0.3,0.3h-1.2v2.2c0,0.4,0.2,0.4,0.4,0.4
                                  c0.2,0,0.5-0.1,0.7-0.2c0.2-0.1,0.3,0,0.3,0.2l0.3,0.9c0.1,0.1,0,0.3-0.2,0.3c-0.1,0-1,0.4-1.7,0.4c-1.1,0-1.6-0.7-1.6-1.8V101.1z
                                  "
                                            />
                                            <path
                                                fill="#414141"
                                                d="M384,99.9c0-0.2,0.1-0.3,0.3-0.3h0.5c0.1,0,0.2,0.1,0.3,0.2l0.2,0.5c0.2-0.3,0.7-0.9,1.6-0.9
                                  c0.7,0,1.2,0.2,1.1,0.6l-0.5,0.9c-0.1,0.1-0.2,0.2-0.3,0.1c0,0-0.3-0.1-0.4-0.1c-0.6,0-0.9,0.4-1,0.6v3.4c0,0.2-0.1,0.3-0.3,0.3
                                  h-1.1c-0.1,0-0.3-0.1-0.3-0.3V99.9z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M390.6,101.7c0.6,0,1,0.2,1,0.2c0-0.7-0.2-1-0.7-1c-0.5,0-1.2,0.1-1.6,0.2c-0.2,0.1-0.3-0.1-0.3-0.3
                                  l-0.1-0.7c0-0.2,0.1-0.3,0.2-0.4c0.1,0,1-0.3,1.9-0.3c1.8,0,2.2,0.9,2.2,2.4v3c0,0.1-0.1,0.3-0.3,0.3h-0.5c-0.1,0-0.2,0-0.2-0.2
                                  l-0.2-0.4c-0.3,0.3-0.8,0.7-1.7,0.7c-1.1,0-1.9-0.7-1.9-1.9C388.3,102.4,389.2,101.7,390.6,101.7z M390.6,104.2
                                  c0.4,0,0.9-0.4,1-0.5v-0.8c0,0-0.4-0.2-0.8-0.2c-0.5,0-0.9,0.3-0.9,0.8C389.9,103.8,390.2,104.2,390.6,104.2z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M397.3,99.5c0.9,0,1.5,0.4,2.1,1c0.1,0.1,0.1,0.3-0.1,0.4l-0.6,0.6c-0.1,0.1-0.3,0-0.4,0
                                  c-0.2-0.3-0.6-0.4-1-0.4c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4c0.6,0,0.9-0.3,1.1-0.5c0.1-0.1,0.2-0.1,0.4,0l0.6,0.5
                                  c0.1,0.1,0.2,0.3,0.1,0.4c-0.5,0.7-1.2,1.2-2.2,1.2c-1.6,0-3-1.3-3-2.9C394.3,100.7,395.7,99.5,397.3,99.5z"
                                            />
                                            <path
                                                fill="#414141"
                                                d="M401,101.1h-0.5c-0.1,0-0.3-0.1-0.3-0.3v-0.9c0-0.2,0.1-0.3,0.3-0.3h0.5V98c0-0.1,0.1-0.3,0.3-0.3l1.1,0
                                  c0.1,0,0.3,0.1,0.3,0.3v1.6h1.2c0.2,0,0.3,0.1,0.3,0.3v0.9c0,0.1-0.1,0.3-0.3,0.3h-1.2v2.2c0,0.4,0.2,0.4,0.4,0.4
                                  c0.2,0,0.5-0.1,0.7-0.2c0.2-0.1,0.3,0,0.3,0.2l0.3,0.9c0.1,0.1,0,0.3-0.2,0.3c-0.1,0-1,0.4-1.7,0.4c-1.1,0-1.6-0.7-1.6-1.8V101.1z
                                  "
                                            />
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <div className="col-xs-1" />
                        <div className="col-xs-5">
                            <div className="vAlign">
                                <h1>Dynamical odds pipeline</h1>
                                <ul>
                                    <li>
                                        <span>continuous import of odds from external providers</span>
                                    </li>
                                    <li>
                                        <span>
                                            extraction of odds model key variables (totals, supremacy, biases, etc.)
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            weighted average of provider OM key variables based on provider quality
                                        </span>
                                    </li>
                                    <li>
                                        <span>adjusting provider expectancies by program-inherent bookmaker risk</span>
                                    </li>
                                    <li>
                                        <span>calculation of complete sports betting program</span>
                                    </li>
                                    <li>
                                        <span>monitoring of risk based on customers betting behaviour and ratings</span>
                                    </li>
                                    <li>
                                        <span>
                                            transformation of risk to program-specific deviations in OM key variables
                                        </span>
                                    </li>
                                    <li>
                                        <span>harmonizing OM key variables by own risk-based adjustments</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" id="sportbook-riskManagement">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-6">
                            <h1>Risk Management</h1>
                            <ul>
                                <li>
                                    <span>
                                        odds model reversed - map risk from stakes distribution to odds model key
                                        variables
                                    </span>
                                </li>
                                <li>
                                    <span>industry comparison</span>
                                </li>
                            </ul>
                            <h2>Industry-based approach</h2>
                            <ul>
                                <li>
                                    <span>align own odds along industry standards</span>
                                </li>
                                <li>
                                    <span>avoid risk exposure on single markets</span>
                                </li>
                                <li>
                                    <span>customer attraction by odds accentuation</span>
                                </li>
                            </ul>
                            <p>vs</p>
                            <h2>Stakes-based approach</h2>
                            <ul>
                                <li>
                                    <span>
                                        monitor customers betting behaviour, weighted by level of expertise (based on
                                        customer ratings)
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        conceivably consider event popularity (regression model based on historical
                                        data)
                                    </span>
                                </li>
                                <li>
                                    <span>detect inaccuracies in model key variables via reversed odds model</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-xs-1" />
                        <div className="col-xs-5">
                            <div className="imgWrap">
                                <img src={graphOne} className="graphOne" alt="" />
                            </div>
                            <div className="imgWrap">
                                <img src={graphTwo} className="graphTwo" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" id="sportbook-customerProfiling">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-5">
                            <div className="imgWrap">
                                <img src={graphThree} className="graphThree" alt="" />
                            </div>
                            <div className="imgWrap">
                                <img src={graphFour} className="graphFour" alt="" />
                            </div>
                        </div>
                        <div className="col-xs-1" />
                        <div className="col-xs-6">
                            <div className="vAlign">
                                <h1>Customer Profiling/Rating</h1>
                                <p>Timely evaluation of customer specific metrics:</p>
                                <ul>
                                    <li>
                                        <span>
                                            stake allocation (e.g. first bet at maximum stake &#61;&#62; bonus hunter)
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            payment method (e.g. Skrill &amp; Netteller &#61;&#62; professional, paypal
                                            &#61;&#62; beginner)
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            negative or low theoretic margin &#61; player knows future odds level which
                                            is currently wrong &#61;&#62; very experienced value better
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            late bets in slow sports or failed bets in fast sports &#61;&#62; customer
                                            has faster feeds
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section" id="contact">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-7">
                            <BumperText>Need more information? Get in touch with us!</BumperText>
                        </div>
                        <div className="col-xs-5">
                            <div className="btnWrapper">
                                <button onClick={show}>Get In Touch</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Sportbook;
