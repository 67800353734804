import React, { Component } from 'react';

class NoMatch extends Component {
  render() {
    return (
      <div>
        Gibts nicht
      </div>
    );
  }
}

export default NoMatch;
