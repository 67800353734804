import React from 'react';
import ReactDOM from 'react-dom';
import {Router, Route, IndexRedirect, browserHistory} from 'react-router';

import App from './App';
import NoMatch from './NoMatch';

// Pages
import Start from './pages/start/Start';
import About from './pages/about/About';
import Casino from './pages/products/Casino';
import Platform from './pages/platform/Platform';
import Sportsbook from './pages/sportsbook/Sportsbook';
import Technology from './pages/technology/Technology';
import Contactus from './pages/contactus/Contactus';
import Customisation from './pages/customisation/Customisation';
import Retail from './pages/retail/Retail';
import Service from './pages/service/Service';

ReactDOM.render(
    <Router history={browserHistory}>
        <Route
            path="/"
            component={App}
            onChange={(prevState, nextState) => {
                if (nextState.location.action !== 'POP') window.scrollTo(0, 0);
            }}>
            <IndexRedirect to="/home" />
            <Route path="home" component={Start} />
            <Route path="about" component={About} />
            <Route path="products/platform" component={Platform} />
            <Route path="products/sportsbook" component={Sportsbook} />
            <Route path="products/casino" component={Casino} />
            <Route path="technology" component={Technology} />
            <Route path="contactus" component={Contactus} />
            {/* <Route path="customisation" component={Customisation} />
            <Route path="retail" component={Retail} />
            <Route path="service" component={Service} /> */}

            <Route path="*" component={NoMatch} />
        </Route>
    </Router>,
    document.getElementById('root')
);
