import React from 'react';
import useModal from '../../components/useModal';
import styled from 'styled-components';

const BumperText = styled.div`
    font-size: 24px;
    text-align: right;
`;

const Casino = React.memo(() => {
    const {show, RenderModal} = useModal();
    return (
        <div className="pageWrap">
            <RenderModal></RenderModal>
            <div className="section" id="casino">
                <div className="content">
                    <div className="row">
                        <div className="col-lg-7 col-sm-12 col-xs-12">
                            <div class="imgWrap">
                                <div class="casinoImg"></div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-sm-12 col-xs-12">
                            <h1>360˚ Complete Casino Solution</h1>
                            <ul>
                                <li>
                                    <span>Multi-functional online casino aggregator</span>
                                </li>
                                <li>
                                    <span>
                                        Seamless user experience across all devices, optimised for strong mobile
                                        performance
                                    </span>
                                </li>
                                <li>
                                    <span>Full suite of tools and features</span>
                                </li>
                                <li>
                                    <span>Regularly updated latest HTML5 fully-responsive content</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section" id="casino-technology">
                <div className="content">
                    <div className="row">
                        <div className="col-lg-7 col-sm-12 col-xs-12">
                            <h1>Latest Technology</h1>
                            <ul>
                                <li>
                                    <span>Multi-functional online casino aggregator</span>
                                </li>
                                <li>
                                    <span>
                                        Developed by experts with over twenty years of experience in igaming software
                                        development
                                    </span>
                                </li>
                                <li>
                                    <span>Intuitive interface</span>
                                </li>
                                <li>
                                    <span>Flexible API architecture</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-5 col-sm-12 col-xs-12">
                            <div className="imgWrap">
                                <div className="casinoTechnology" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section" id="casino-lobby">
                <div className="content">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12 col-xs-12">
                            <div className="imgWrap">
                                <div className="casinoLobby" />
                            </div>
                        </div>
                        <div className="col-xs-1" />
                        <div className="col-lg-5 col-sm-12 col-xs-12 sm-margin25">
                            <h1>Easy Content and Lobby Management</h1>
                            <ul>
                                <li>
                                    <span>Simple navigation panel</span>
                                </li>
                                <li>
                                    <span>Proprietary CMS</span>
                                </li>
                                <li>
                                    <span>User-friendly customisation tools</span>
                                </li>
                                <li>
                                    <span>Integrated BI for delivery of region-specific content</span>
                                </li>
                                <li>
                                    <span>Move games via Drag &amp; Drop</span>
                                </li>
                                <li>
                                    <span>Define own Grids per Category</span>
                                </li>
                                <li>
                                    <span>Categories automation by many different criteria</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section" id="casino-gamemanagement">
                <div className="content">
                    <div className="row">
                        <div className="col-lg-7 col-sm-12 col-xs-12">
                            <h1>Game Management</h1>
                            <ul>
                                <li>
                                    <span>Get an overview of all the available games</span>
                                </li>
                                <li>
                                    <span>Edit game details, tagging and assets</span>
                                </li>
                                <li>
                                    <span>Manage enabling/ disabling</span>
                                </li>
                                <li>
                                    <span>Bulk edits</span>
                                </li>
                                <li>
                                    <span>Own asset management</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-5 col-sm-12 col-xs-12">
                            <div className="imgWrap">
                                <div className="casinoGameManagement" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section" id="casino-reliability">
                <div className="content">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12 col-xs-12">
                            <div className="imgWrap">
                                <div className="casinoReliability" />
                            </div>
                        </div>
                        <div className="col-xs-1" />
                        <div className="col-lg-5 col-sm-12 col-xs-12 sm-margin25">
                            <h1>Reliability</h1>
                            <ul>
                                <li>
                                    <span>Multi-language</span>
                                </li>
                                <li>
                                    <span>Multi-currency</span>
                                </li>
                                <li>
                                    <span>MGA license</span>
                                </li>
                                <li>
                                    <span>Ongoing vendor management</span>
                                </li>
                                <li>
                                    <span>One-time simple API integration</span>
                                </li>
                                <li>
                                    <span>Ongoing support</span>
                                </li>
                                <li>
                                    <span>Free spins integration</span>
                                </li>
                                <li>
                                    <span>Real-time data</span>
                                </li>
                                <li>
                                    <span>Multi-brand capability</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section" id="contact">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-7">
                            <BumperText>Need more information? Get in touch with us!</BumperText>
                        </div>
                        <div className="col-xs-5">
                            <div className="btnWrapper">
                                <button onClick={show}>Get In Touch</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Casino;
