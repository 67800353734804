import React from "react";
import ReactDOM from 'react-dom'
import styled from 'styled-components';

type Props = {
  children: React.ReactChild;
  closeModal: () => void;
}
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);

`;
const ModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const IFrame = styled.iframe`
  width: 640px;
  height: 640px;
  max-height: calc(100vh - 86px);
  max-width: 100vw;
`;
const CloseBtn = styled.div`
  display: flex;
  cursor: pointer;
  width: 24px;
  height: 24px;
  fill: white;
  position: absolute;
  right: 0;
  &:hover {
    fill: #00a2c3;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`;

const Modal = React.memo(({ children, closeModal }: Props) => {
  const domEl = document.getElementById('modal-root')

  if (!domEl) return null
  
  // This is where the magic happens -> our modal div will be rendered into our 'modal-root' div, no matter where we
  // use this component inside our React tree
  return ReactDOM.createPortal(
    <Overlay>
    <ModalWrapper>
      <CloseBtn onClick={closeModal}>
      <svg version="1.1" viewBox="0 0 100 100"><g><polygon points="100,5.4 94.6,0 50,44.6 5.4,0 0,5.4 44.6,50 0,94.6 5.4,100 50,55.4 94.6,100 100,94.6 55.4,50"></polygon></g></svg>
      </CloseBtn>
      <IFrame
                    src="https://docs.google.com/forms/d/e/1FAIpQLSfFIn6tBqb24_GI2QXlsbPowX6xXafv81F6U1-sarLxtQXDnQ/viewform?embedded=true"
                    frameBorder="0"
                    marginHeight="0" 
                    marginWidth="0"
                >
                    Loading…
                </IFrame>
    </ModalWrapper>      
    </Overlay>,
    domEl
  )
})

export default Modal