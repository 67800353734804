import React from 'react';
import useModal from '../../components/useModal';
import styled from 'styled-components';

const IFrame = styled.iframe`
    width: 640px;
    width: 100%;
    height: 940px;
    max-height: calc(100vh - 86px);
    max-width: 100vw;
`;

const Contactus = React.memo(() => {
    const {show, RenderModal} = useModal();
    return (
        <div className="pageWrap">
            <RenderModal></RenderModal>

            <div className="section" id="contactus">
                <div className="content">
                    <div className="row top">
                        <div className="col-lg-3 col-sm-12">
                            <div class="impressum"></div>
                            <div class="wrapper">
                                <h3>Our contact details:</h3>
                                <p>
                                    Reactive Betting Ltd Level 2, St. Julian's Business Centre, Triq Elija Zammit, St.
                                    Julian's, STJ 3155, Malta
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-9 col-sm-12">
                            <a
                                target="_blank"
                                href="https://www.google.com/maps/place/St+Julians+Business+centre/@35.9229496,14.4858398,17z/data=!4m10!1m2!2m1!1sReactive+Betting+Ltd+Level+2,+St.+Julian's+Business+Centre,+Triq+Elija+Zammit,+St.+Julian's,+STJ+3155,+Malta!3m6!1s0x130e45f3051f657b:0x52c160c21759c6da!8m2!3d35.9229453!4d14.4884147!15sCmxSZWFjdGl2ZSBCZXR0aW5nIEx0ZCBMZXZlbCAyLCBTdC4gSnVsaWFuJ3MgQnVzaW5lc3MgQ2VudHJlLCBUcmlxIEVsaWphIFphbW1pdCwgU3QuIEp1bGlhbidzLCBTVEogMzE1NSwgTWFsdGGSARBjb3Jwb3JhdGVfb2ZmaWNl4AEA!16s%2Fg%2F11sy_5ngnm">
                                <div class="googlemap"></div>
                            </a>

                            {/* <IFrame
                                src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJe2UfBfNFDhMR2sZZF8JgwVI&key=..."
                                frameBorder="0"
                                marginHeight="0"
                                marginWidth="0"
                                allowfullscreen
                                loading="lazy">
                                loading..
                            </IFrame> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Contactus;
