import React from 'react';
import styled from 'styled-components';
import useModal from '../../components/useModal';

const BumperText = styled.div`
    font-size: 24px;
    text-align: right;
`;
const Technology = React.memo(() => {
    const {show, RenderModal} = useModal();
    return (
        <div className="pageWrap">
            <RenderModal></RenderModal>
            <div className="section" id="technology-intro">
                <div className="content">
                    {/* <div className="row">
                            <div className="col-xs-12">
                                <h1>Customer Centric Approach</h1>
                            </div>
                        </div> */}
                    <div className="row">
                        <div className="col-xs-4">
                            <h2 className="textRight">
                                Performance &amp;
                                <br /> High Availability
                            </h2>
                        </div>
                        <div className="col-xs-2" />
                        <div className="col-xs-5">
                            <h2 className="textLeft firstRight">Composable Components</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-5">
                            <h2 className="textRight secondLeft">Omni-channel</h2>
                        </div>
                        <div className="col-xs-5">
                            <h2 className="textLeft secondRight">SEO</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-4">
                            <h2 className="textRight thirdLeft">Cloud-based</h2>
                        </div>
                        <div className="col-xs-3" />
                        <div className="col-xs-4">
                            <h2 className="textLeft thirdRight">Scala based Technology</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-6">
                            <h2 className="textRight fourthLeft">Message-driven developer API</h2>
                        </div>
                        <div className="col-xs-5">
                            {/* <h2 className="textLeft fourthRight">Know Your Customer</h2> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="section" id="technology-Performance">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-6">
                            <div className="vAlign">
                                <h1>Performance &amp; High Availability</h1>
                                <p>Some figures per minute &amp; per server</p>
                                <ul>
                                    <li>
                                        <span>&#62; 10.000 market creations &amp; prize updates</span>
                                    </li>
                                    <li>
                                        <span>&#62; 50.000 bet settlements &amp; cash outs</span>
                                    </li>
                                    <li>
                                        <span>&#62; 5.000 payment processes</span>
                                    </li>
                                    <li>
                                        <span>
                                            &#126; 200 milliseconds latency from the trader to the customers browser
                                        </span>
                                    </li>
                                    <li>
                                        <span>&#60; 500 milliseconds failover time in case of a server outage</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xs-6"></div>
                    </div>
                </div>
            </div>

            <div className="section" id="technology-composeableComponents">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-5">
                            <div className="imgWrap">
                                <div className="composeableComponentsImg"></div>
                            </div>
                        </div>
                        <div className="col-xs-1"></div>
                        <div className="col-xs-6">
                            <h1>Composable Components</h1>
                            <ul>
                                <li>
                                    <span>choose between a full stack solution or single embeddable components</span>
                                </li>
                                <li>
                                    <span>
                                        almost all sportsbook and platform components can be integrated into your
                                        existing site or product
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        wallets can be linked seamlessly with each other via message driven
                                        communication
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        latest WebComponents technologies guarantee an edge-cutting method of
                                        integration
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" id="technology-oneClient">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-6">
                            <h1>Omni-Channel</h1>
                            <ul>
                                <li>
                                    <span>
                                        for us omni-channel means <i>one</i>
                                        -channel with exactly the same user experience on every device
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        responsive, multi-device compatible client with latest technology stack (ReactJS
                                        by Facebook, Cordova for hybrid mobile apps)
                                    </span>
                                </li>
                                <li>
                                    <span>fast integration of new features across devices</span>
                                </li>
                                <li>
                                    <span>Apple App Store &amp; Google Play Store ready</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-xs-6">
                            <div className="imgWrap">
                                <div className="allDevicesImg"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" id="technology-seo">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-4">
                            <div className="imgWrap">
                                <div className="seoImg"></div>
                            </div>
                        </div>
                        <div className="col-xs-1"></div>
                        <div className="col-xs-7">
                            <h1>SEO Optimization</h1>
                            <p>Every league or match is visible in the URL in every supported language, i.e.</p>
                            <ul>
                                <li>
                                    <span>/en/Sportbets/Football-England-Championship</span>
                                </li>
                                <li>
                                    <span>/en/Livebets/2017-01-27</span>
                                </li>
                                <li>
                                    <span>
                                        /de/Sportwetten/Fußball/Borussia.Dortmund/2017-01-29/1.FSV.Mainz.05-Borussia.Dortmund
                                    </span>
                                </li>
                            </ul>
                            <p>Generated Sitemap for the whole betting offer</p>
                            <p>Additional content can be placed on every site and manipulated via CMS</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" id="technology-cloudProduction">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-5">
                            <div className="imgWrap">
                                <div className="cloudInfographicImg"></div>
                            </div>
                        </div>
                        <div className="col-xs-1"></div>
                        <div className="col-xs-6">
                            <h1>Cloud Operations</h1>
                            <ul>
                                <li>
                                    <span>load-dependent elastic auto-scaling</span>
                                </li>
                                <li>
                                    <span>cost effective &amp; highly performant for very spiky loads</span>
                                </li>
                                <li>
                                    <span>
                                        self-monitoring &amp; self-healing infrastructure provisioned on public or
                                        private clouds
                                    </span>
                                </li>
                                <li>
                                    <span>low maintenance efforts, no 24x7 staff requirements</span>
                                </li>
                                <li>
                                    <span>support for multiple locations with full data synchronisation</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" id="technology-beReactive">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-5">
                            <div className="imgWrap">
                                <div className="architectureImg"></div>
                            </div>
                        </div>
                        <div className="col-xs-1"></div>
                        <div className="col-xs-6">
                            <div className="vAlign">
                                <h1>Be Reactive</h1>
                                <p>
                                    We apply the principles of the Reactive Manifesto. We use the following Scala based
                                    technology stack
                                </p>
                                <ul>
                                    <li>
                                        <span>Lightbend Akka as a reactive, actor-based, message-driven runtime</span>
                                    </li>
                                    <li>
                                        <span>RBMH Eventuate for event sourcing</span>
                                    </li>
                                    <li>
                                        <span>Datastax Cassandra as a highly scalable persistence layer</span>
                                    </li>
                                    <li>
                                        <span>
                                            Apache Spark for short-term machine learning and long-term analytics
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            ReactJS together with pure HTML5/CSS3 for a Mobile-first web-based client
                                        </span>
                                    </li>
                                    <li>
                                        <span>push-based low-overhead client-server Websockets only communication</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="imgWrap">
                                <div className="techLogos"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="section" id="technology-systemArchitecture">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-6">
                            <div className="iframeWrap">
                                <iframe
                                    id="iframe_container"
                                    border="0"
                                    webkitallowfullscreen=""
                                    mozallowfullscreen=""
                                    allowFullScreen=""
                                    width="100%"
                                    height="100%"
                                    src="https://prezi.com/embed/vwcsijzhhw9l/?bgcolor=ffffff&amp;lock_to_path=0&amp;autoplay=0&amp;autohide_ctrls=0&amp;landing_data=bHVZZmNaNDBIWnNjdEVENDRhZDFNZGNIUE43MHdLNWpsdFJLb2ZHanI0a1lZYjRBSUxTdGVpWFRXb0VuTTRaZGp3PT0&amp;landing_sign=smgXYU_pUasbzpPeLSlqazz8s6tYHx64PdE_U5vId7E"></iframe>
                            </div>
                        </div>
                        <div className="col-xs-1"></div>
                        <div className="col-xs-5">
                            <h1>System Architecture</h1>
                            <ul>
                                <li>
                                    <span>λ architecture to separate speed- and batch-layer</span>
                                </li>
                                <li>
                                    <span>
                                        highly concurrent asynchronous design for maximum throughput and minimal latency
                                    </span>
                                </li>
                                <li>
                                    <span>CQRS - writes decoupled from reads, which are completely in memory</span>
                                </li>
                                <li>
                                    <span>
                                        end-to-end push architecture - no polling - milliseconds instead of seconds of
                                        update delays
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        microservices architecture to separate modules (i.e. KYC, multiple payment
                                        gateways, etc
                                    </span>
                                </li>
                                <li>
                                    <span>strict separation of platform and product features</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="section" id="technology-developerApi">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-5">
                            <div className="vAlign">
                                <h1>Message driven developer API</h1>
                                <ul>
                                    <li>
                                        <span>
                                            async &amp; message driven - faster &amp; more reliable than traditional
                                            REST based APIs
                                        </span>
                                    </li>
                                    <li>
                                        <span>availability of all core functionality (already used by the client)</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xs-1"></div>
                        <div className="col-xs-6">
                            <div className="imgWrap">
                                <div className="websocketImg"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section" id="contact">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-7">
                            <BumperText>Need more information? Get in touch with us!</BumperText>
                        </div>
                        <div className="col-xs-5">
                            <div className="btnWrapper">
                                <button onClick={show}>Get In Touch</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Technology;
