import React, { useRef, useState } from "react"
import { Link } from "react-router";
import styled from 'styled-components';

const Linklist = styled.div`
    position: absolute;
    z-index: 1;
    background: white;
    display: flex;
    flex-direction: column;
    padding: 1em 2em;
    top: 52px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 0px 2rem 0px rgba(0, 0, 0, 0.32);
    &:after {
      content: '';
      position: absolute;
      top: -15px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 16px solid white;
    }
`;

const Dropdown:  React.FC = props => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const onClick = () => setIsActive(!isActive);

  return (
    <div className="tab dropdownMenu">
      <div className="link" onClick={onClick}>
        Products
      </div>
      {isActive && (
      <Linklist ref={dropdownRef}>
          <Link onClick={onClick} className="dropDownMenue_links" activeClassName="active" to="/products/platform">Platform</Link>
          <Link onClick={onClick} className="dropDownMenue_links" activeClassName="active" to="/products/sportsbook">Sportsbook</Link>
          <Link onClick={onClick} className="dropDownMenue_links" activeClassName="active" to="/products/casino">Casino</Link>
      </Linklist>
      )}
    </div>
  );
};
export default Dropdown;