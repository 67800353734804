import React, { Component } from 'react';

class Retail extends Component {
  render() {
    return (
        <div className="pageWrap">

            <div className="section" id="retail-customerCard">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-2"></div>
                        <div className="col-xs-2">
                            <div className="imgWrap">
                                <div className="nfcLogo"></div>
                            </div>
                            <div className="imgWrap">
                                <div className="qrCode"></div>
                            </div>
                            <div className="imgWrap">
                                <div className="walletLogo"></div>
                            </div>
                        </div>
                        <div className="col-xs-1"></div>
                        <div className="col-xs-6">
                            <div className="vAlign">
                                <h1>Customer Card</h1>
                                <p>The customer's branded NFC based card represents his account.
                                It can be configured as a completely blank account or with  
                                presets like country, affiliate ID (e.g. the shop or an agent) or
                                even bonuses.</p>
                                <p>Instead of the card you can also create Apple Wallet pass files or simple QR barcodes.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" id="retail-terminals">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-6">
                            <div className="vAlign">
                                <h1>Self service terminals</h1>
                                <p>We focus on self serving, no PDF printouts, no expensive OMR
                                (optical mark recognition) scanners. Even POS printers are optional.</p>
                                <h1>Infoscreens</h1>
                                <p>As infoscreens for the wall we provide an HDMI stick which connects to your
                                wifi and can be controlled remotely via a web interface.</p>
                            </div>
                        </div>
                        <div className="col-xs-1"></div>
                        <div className="col-xs-5">
                            <div className="imgWrap">
                                <div className="terminalsImg"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" id="retail-cashAndCards">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-1"></div>
                        <div className="col-xs-3">
                        <div className="imgWrap">
                            <div className="atmImg"></div>
                        </div>
                        </div>
                        <div className="col-xs-2"></div>
                        <div className="col-xs-6">
                            <div className="vAlign">
                                <h1>Cash &amp; card desposits &amp; withdrawals</h1>
                                <p>Deposits and withdrawals either can be done by a cashier
                                who scans your card with his mobile device or via an ATM.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

      </div>
    );
  }
}

export default Retail;
