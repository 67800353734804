import React, {Component} from 'react';
import Slider from 'react-slick';
import useModal from '../../components/useModal';
import styled from 'styled-components';

// import bonusoverview_eng from '../../assets/bonusoverview_eng.png';
// import deposit_eng from '../../assets/deposit_eng.png';

import bonus_1 from '../../assets/IPhoneX_Screens_Bonus_1.jpg';
import bonus_2 from '../../assets/IPhoneX_Screens_Bonus_2.jpg';
import bonus_3 from '../../assets/IPhoneX_Screens_Bonus_3.jpg';

import accountManagement_1 from '../../assets/IPhoneX_Screens_AccountManagement_1.jpg';
import accountManagement_2 from '../../assets/IPhoneX_Screens_AccountManagement_2.jpg';
import accountManagement_3 from '../../assets/IPhoneX_Screens_AccountManagement_3.jpg';
import accountManagement_4 from '../../assets/IPhoneX_Screens_AccountManagement_4.jpg';

import kyc_1 from '../../assets/kyc_new1.jpg';
import kyc_2 from '../../assets/kyc_new2.jpg';
import kyc_3 from '../../assets/kyc_new3.jpg';

import journal_1 from '../../assets/IPhoneX_Screens_Journal_1.jpg';
import journal_2 from '../../assets/IPhoneX_Screens_Journal_2.jpg';
import journal_3 from '../../assets/IPhoneX_Screens_Journal_3.jpg';
import journal_4 from '../../assets/IPhoneX_Screens_Journal_4.jpg';
import journal_5 from '../../assets/IPhoneX_Screens_Journal_5.jpg';

const BumperText = styled.div`
    font-size: 24px;
    text-align: right;
`;

const Platform = React.memo(() => {
    const {show, RenderModal} = useModal();
    return (
        <div className="pageWrap">
            <RenderModal></RenderModal>
            <div className="section" id="platform-intro">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-12">
                            <h1>Customer Centric Approach</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-4">
                            <h2 className="textRight">
                                Acquisition &amp;
                                <br /> Retention Marketing
                            </h2>
                        </div>
                        <div className="col-xs-2" />
                        <div className="col-xs-5">
                            <h2 className="textLeft firstRight">Account Management</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-5">
                            <h2 className="textRight secondLeft">Payments</h2>
                        </div>
                        <div className="col-xs-5">
                            <h2 className="textLeft secondRight">Fraud Protection</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-4">
                            <h2 className="textRight thirdLeft">Customer Journal</h2>
                        </div>
                        <div className="col-xs-3" />
                        <div className="col-xs-4">
                            <h2 className="textLeft thirdRight">Security</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-6">
                            <h2 className="textRight fourthLeft">Customer Support</h2>
                        </div>
                        <div className="col-xs-5">
                            <h2 className="textLeft fourthRight">Know Your Customer</h2>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" id="platform-aquisition">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="textCenter">
                                <h1>Acquisition &amp; Retention Marketing</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-5">
                            <div className="imgWrap circle">
                                <div className="crm_circle" />
                            </div>
                        </div>
                        <div className="col-xs-6">
                            <h2>Marketing automation</h2>
                            <p>
                                The very top of the marketing funnel is the most complex area for back office process
                                automation.
                            </p>
                            <ul>
                                <li>
                                    <span>
                                        full customer journey tracking from registration to reasons for customer churn
                                    </span>
                                </li>
                                <li>
                                    <span>real-time reports for campaign conversions</span>
                                </li>
                                <li>
                                    <span>A/B tests</span>
                                </li>
                                <li>
                                    <span>built-in adserver</span>
                                </li>
                            </ul>
                            <div className="imgWrap steps">
                                <div className="customerJourney" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" id="platform-bonusOfferings">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-1" />
                        <div className="col-xs-6">
                            <div className="vAlign">
                                <h2>Bonus Offerings</h2>
                                <ul>
                                    <li>
                                        <span>independent &amp; comprehensive</span>
                                    </li>
                                    <li>
                                        <span>real-time bonus offering, progress and distribution</span>
                                    </li>
                                    <li>
                                        <span>
                                            seamlessly embeddable into any process such as deposit or betslip delivery
                                        </span>
                                    </li>
                                </ul>
                                <h2>Loyalty System - "daily challenge"</h2>
                                <ul>
                                    <li>
                                        <span>retention and cross selling tool</span>
                                    </li>
                                    <li>
                                        <span>earn &amp; collect badges</span>
                                    </li>
                                    <li>
                                        <span>let customers choose which bonus they take</span>
                                    </li>
                                </ul>
                                <h2>Customers bring friends</h2>
                                <ul>
                                    <li>
                                        <span>"mirror" friends bonuses - get a win-win</span>
                                    </li>
                                    <li>
                                        <span>stay informed about friends' progress</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xs-5">
                            <div className="iphoneWrap">
                                <div className="iphone" />
                                <div className="slideStage">
                                    {/* <div className="iphone" /> */}
                                    <Slider
                                        {...{
                                            dots: true,
                                            arrows: true,
                                            autoplay: false
                                        }}>
                                        <div className="slide">
                                            <img src={bonus_1} alt="" />
                                        </div>
                                        <div className="slide">
                                            <img src={bonus_2} alt="" />
                                        </div>
                                        <div className="slide">
                                            <img src={bonus_3} alt="" />
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-1" />
                    </div>
                </div>
            </div>

            <div className="section" id="platform-bannerSystem">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-1" />
                        <div className="col-xs-3">
                            <div className="imgWrap">
                                <iframe className="bannerIframe" src="/iframeBanner/multiMatch_300x250.html" />
                            </div>
                        </div>
                        <div className="col-xs-1" />
                        <div className="col-xs-6">
                            <div className="vAlign">
                                <h2>Banner configurator</h2>
                                <ul>
                                    <li>
                                        <span>configuration of iFrame based banners</span>
                                    </li>
                                    <li>
                                        <span>choose between static bonus banners or dynamic league-based banners</span>
                                    </li>
                                    <li>
                                        <span>personal leagues (favorites the customer bets most on)</span>
                                    </li>
                                    <li>
                                        <span>direct interaction within the banner (betslip editor)</span>
                                    </li>
                                    <li>
                                        <span>own integrated ad server with arbitrary analysis</span>
                                    </li>
                                    <li>
                                        <span>integrated within optional affiliate system</span>
                                    </li>
                                    <li>
                                        <span>fully configured embeddable responsive iframes</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xs-1" />
                    </div>
                </div>
            </div>

            <div className="section" id="platform-affiliate">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-1" />
                        <div className="col-xs-4">
                            <div className="imgWrap">
                                <div className="affiliate" />
                            </div>
                        </div>
                        <div className="col-xs-1" />
                        <div className="col-xs-6">
                            <h2>Multi-Level Affiliates</h2>
                            <p>Several affiliate setups</p>
                            <ul>
                                <li>
                                    <span>classic affiliate - credit for recruited customers</span>
                                </li>
                                <li>
                                    <span>country manager - credit for customers in a certain country</span>
                                </li>
                                <li>
                                    <span>super-affiliate - define revenue sharing models for sub-affiliates</span>
                                </li>
                                <li>
                                    <span>easy ad administration with access to banner configurator</span>
                                </li>
                                <li>
                                    <span>real-time statistics</span>
                                </li>
                            </ul>
                            <p>Flexible commercials set-up</p>
                            <ul>
                                <li>
                                    <span>revenue share model (monthly, quarterly)</span>
                                </li>
                                <li>
                                    <span>
                                        base provision model with extra provisions per service (support, payment, ..)
                                    </span>
                                </li>
                                <li>
                                    <span>with or without negative carry-over</span>
                                </li>
                                <li>
                                    <span>
                                        pre-defined commission frames: (lifetime, specified period, per customer
                                        activity, volume-scaled)
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" id="platform-accountManagement">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-1" />
                        <div className="col-xs-5">
                            <div className="vAlign">
                                <h1>Account Management</h1>
                                <ul>
                                    <li>
                                        <span>
                                            full flexibility for multiple compliance levels (depending on licence)
                                        </span>
                                    </li>
                                    <li>
                                        <span>concise and personalised registration template</span>
                                    </li>
                                    <li>
                                        <span>
                                            set-up customisable registration for special cases (e.g. when customer wants
                                            to deposit &#62; 1000 EUR or wants to withdraw)
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xs-1" />
                        <div className="col-xs-4">
                            <div className="iphoneWrap">
                                <div className="iphone" />
                                <div className="slideStage">
                                    <Slider
                                        {...{
                                            dots: true,
                                            arrows: true,
                                            autoplay: false
                                        }}>
                                        <div className="slide">
                                            <img src={accountManagement_1} alt="" />
                                        </div>
                                        <div className="slide">
                                            <img src={accountManagement_2} alt="" />
                                        </div>
                                        <div className="slide">
                                            <img src={accountManagement_3} alt="" />
                                        </div>
                                        <div className="slide">
                                            <img src={accountManagement_4} alt="" />
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" id="platform-payment">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-7">
                            <div className="vAlign">
                                <h1>Payments</h1>
                                <ul>
                                    <li>
                                        <span>
                                            built-in anti money laundering tools with money tracking (transparent
                                            customer funds)
                                        </span>
                                    </li>
                                    <li>
                                        <span>source awareness - know the funds origins in every deposit/bonus</span>
                                    </li>
                                    <li>
                                        <span>
                                            different types of funds (withdrawable, non-withdrawable, bonus,
                                            reserved-for-withdrawal)
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            automated type of funds transformation on bonus/payment turnover or on
                                            withdrawal request and execution
                                        </span>
                                    </li>
                                    <li>
                                        <span>multi currency support, crypto currencies and play-money</span>
                                    </li>
                                    <li>
                                        <span>
                                            reconcile automations when PSPs are temporary not available with minimal
                                            backoffice operations
                                        </span>
                                    </li>
                                    <li>
                                        <span>each payment method is pluggable module</span>
                                    </li>
                                    <li>
                                        <span>direct support of multiple PSPs with no need for an aggregator</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xs-1" />
                        <div className="col-xs-4">
                            <div className="imgWrap">
                                <div className="paymentboxImg" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" id="platform-OneClickDeposit">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-1" />
                        <div className="col-xs-2">
                            <div className="imgWrap">
                                <div className="oneClick" />
                            </div>
                        </div>
                        <div className="col-xs-3" />
                        <div className="col-xs-6">
                            <div className="vAlign">
                                <h1>
                                    One Click Deposit &amp;
                                    <br />
                                    Automated Funds
                                </h1>

                                <p>
                                    Reload funds with one click or automatically when the customer configured an
                                    auto-reload-barrier. Possible for
                                </p>
                                <ul>
                                    <li>
                                        <span>credit card (depends on PCI compliance)</span>
                                    </li>
                                    <li>
                                        <span>Paypal</span>
                                    </li>
                                    <li>
                                        <span>SEPA debit advice</span>
                                    </li>
                                    <li>
                                        <span>Skrill 1-tap</span>
                                    </li>
                                    <li>
                                        <span>... more on request</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" id="platform-seamlessWallet">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-7">
                            <div className="vAlign">
                                <h1>Seamless Wallet</h1>
                                <ul>
                                    <li>
                                        <span>
                                            seamless integration of 3rd party products (casinos, virtual sports) with
                                            transparent customer funds and product independent bonuses
                                        </span>
                                    </li>
                                    <li>
                                        <span>configurable game-specific bonus turnover rules</span>
                                    </li>
                                    <li>
                                        <span>no need for casino aggregators</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-xs-4">
                            <div className="imgWrap">
                                <div className="seamlessWallet" />
                            </div>
                        </div>
                        <div className="col-xs-1" />
                    </div>
                </div>
            </div>

            <div className="section" id="platform-fraudProtection">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-4">
                            <div className="imgWrap">
                                <div className="fraudUser" />
                            </div>
                        </div>
                        <div className="col-xs-1" />
                        <div className="col-xs-7">
                            <div className="vAlign">
                                <h1>Fraud Protection</h1>
                                <ul>
                                    <li>
                                        <span>
                                            built-in fraud detection identifies customers device with precision (via
                                            evercookie &amp; browser fingerprinting)
                                        </span>
                                    </li>
                                    <li>
                                        <span>device/-customer-relation by interactions (login, bets, payments)</span>
                                    </li>
                                    <li>
                                        <span>
                                            cluster-graph networks between users and customers help identifying
                                            potential fraud
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" id="platform-journal">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-7">
                            <div className="vAlign">
                                <h1>Customer Journal</h1>
                                <ul>
                                    <li>
                                        <span>full event history of customer's account (like Facebook)</span>
                                    </li>
                                    <li>
                                        <span>content-based detail view (like Google Inbox)</span>
                                    </li>
                                    <li>
                                        <span>system entries appear unread (i.e. betslip evaluation)</span>
                                    </li>
                                    <li>
                                        <span>always informed with header status bubble</span>
                                    </li>
                                    <li>
                                        <span>infinite scrolling and jumping over time &amp; entry filters</span>
                                    </li>
                                    <li>
                                        <span>
                                            ready for backoffice use: private journal entries for backoffice insights
                                            (e.g. support agent comments)
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xs-1" />
                        <div className="col-xs-4">
                            <div className="iphoneWrap">
                                <div className="iphone" />
                                <div className="slideStage">
                                    <Slider
                                        {...{
                                            dots: true,
                                            arrows: true,
                                            autoplay: false
                                        }}>
                                        <div className="slide">
                                            <img src={journal_1} alt="" />
                                        </div>
                                        <div className="slide">
                                            <img src={journal_2} alt="" />
                                        </div>
                                        <div className="slide">
                                            <img src={journal_3} alt="" />
                                        </div>
                                        <div className="slide">
                                            <img src={journal_4} alt="" />
                                        </div>
                                        <div className="slide">
                                            <img src={journal_5} alt="" />
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" id="platform-security">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-6">
                            <div className="imgWrap vAlign">
                                <div className="security" />
                            </div>
                        </div>
                        <div className="col-xs-1" />
                        <div className="col-xs-5">
                            <h1>Security</h1>
                            <ul>
                                <li>
                                    <span>multiple authentication levels (token, PIN, fingerprint, password)</span>
                                </li>
                                <li>
                                    <span>optional PIN or fingerprint authentication once per day</span>
                                </li>
                                <li>
                                    <span>token auto-login - enter website in "read-only" mode</span>
                                </li>
                                <li>
                                    <span>request password or PIN for writing actions (similar to Amazon)</span>
                                </li>
                                <li>
                                    <span>
                                        flexible definition of required authentication level per action (i.e. withdrawal
                                        requires password authentication)
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" id="platform-support">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-7">
                            <h1>Customer Support</h1>
                            <ul>
                                <li>
                                    <span>better proactive customer support &amp; engagement</span>
                                </li>
                                <li>
                                    <span>built-in co-browsing mode</span>
                                </li>
                                <li>
                                    <span>
                                        hand-in-hand with CRM and KYC-support as first point of contact and sales
                                        channel
                                    </span>
                                </li>
                                <li>
                                    <span>backoffice sees live view of customer KPIs and journal</span>
                                </li>
                                <li>
                                    <span>
                                        Freshdesk integration for communication (Mail, LiveChat, WhatsApp, Facebook,
                                        Twitter, etc)
                                    </span>
                                </li>
                            </ul>
                            <div className="row">
                                <div className="col-xs-3">
                                    <div className="freshdesk" />
                                </div>
                                <div className="col-xs-3">
                                    <div className="intercom" />
                                </div>
                                <div className="col-xs-3">
                                    <div className="logrocket" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-5">
                            <div className="devicesWrap">
                                <div className="freshdeskDevices" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" id="platform-kyc">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-5">
                            <div className="iphoneWrap">
                                <div className="iphone" />
                                <div className="slideStage">
                                    <Slider
                                        {...{
                                            dots: true,
                                            arrows: true,
                                            autoplay: false
                                        }}>
                                        <div className="slide">
                                            <img src={kyc_1} alt="" />
                                        </div>
                                        <div className="slide">
                                            <img src={kyc_2} alt="" />
                                        </div>
                                        <div className="slide">
                                            <img src={kyc_3} alt="" />
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-1" />
                        <div className="col-xs-6">
                            <div className="vAlign">
                                <h1>KYC - Know Your Customer</h1>
                                <ul>
                                    <li>
                                        <span>mobile optimized document upload (direct camera access)</span>
                                    </li>
                                    <li>
                                        <span>email verification with inbox check</span>
                                    </li>
                                    <li>
                                        <span>
                                            phone number verification with phone service provider registrar lookup
                                        </span>
                                    </li>
                                    <li>
                                        <span>TAN based authentication</span>
                                    </li>
                                    <li>
                                        <span>rule-based definition of required KYC status</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" id="platform-compliance">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-6">
                            <h1>Compliance</h1>
                            <p>Licensed by Malta Gaming Authority</p>
                            <ul>
                                <li>
                                    <span>self limitation</span>
                                </li>
                                <li>
                                    <span>self deactivation (temporary or permanent)</span>
                                </li>
                                <li>
                                    <span>reality check</span>
                                </li>
                                <li>
                                    <span>KYC</span>
                                </li>
                            </ul>
                            <p>Already adapted license requirements for</p>
                            <ul>
                                <li>
                                    <span>Germany</span>
                                </li>
                                <li>
                                    <span>Schleswig-Holstein</span>
                                </li>
                                <li>
                                    <span>Denmark</span>
                                </li>
                                <li>
                                    <span>Italy</span>
                                </li>
                                <li>
                                    <span>Belgium</span>
                                </li>
                                <li>
                                    <span>Colombia</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-xs-6">
                            <div className="box">
                                <div className="row">
                                    <div className="col-xs-4">
                                        <div className="imgWrap">
                                            <div className="mga" />
                                        </div>
                                    </div>
                                    <div className="col-xs-4">
                                        <div className="imgWrap">
                                            <div className="germany" />
                                        </div>
                                    </div>
                                    <div className="col-xs-4">
                                        <div className="imgWrap">
                                            <div className="sh" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-3">
                                        <div className="imgWrap vAlign">
                                            <div className="colombia" />
                                        </div>
                                    </div>
                                    <div className="col-xs-3">
                                        <div className="imgWrap vAlign">
                                            <div className="dk" />
                                        </div>
                                    </div>
                                    <div className="col-xs-3">
                                        <div className="imgWrap vAlign">
                                            <div className="it" />
                                        </div>
                                    </div>
                                    <div className="col-xs-3">
                                        <div className="imgWrap vAlign">
                                            <div className="be" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section" id="contact">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-7">
                            <BumperText>Need more information? Get in touch with us!</BumperText>
                        </div>
                        <div className="col-xs-5">
                            <div className="btnWrapper">
                                <button onClick={show}>Get In Touch</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Platform;
