import React, {Component} from 'react';
import {Link} from 'react-router';
import useModal from '../../components/useModal';
import customerImg from '../../assets/customer.svg';
import casinoImg from '../../assets/casino.svg';
import sportbookImg from '../../assets/soccerBall.svg';

const Start = React.memo(() => {
    const {show, RenderModal} = useModal();
    return (
        <div className="pageWrap">
            <RenderModal></RenderModal>

            <div className="section" id="start">
                <div className="content">
                    <div className="row">
                        <div className="col-lg-7 col-sm-12">
                            <div class="imgWrap">
                                <div class="soccerImg"></div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-sm-12">
                            <div className="logoWrap">
                                <svg
                                    className="rbpLogo"
                                    version="1.1"
                                    id="Ebene_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 185.1 70">
                                    <g>
                                        <path
                                            d="M137.3,12.3V1.9c0-1,0.8-1.9,1.9-1.9h23.5c12.3,0,22.4,10,22.4,22.1
                                            c0,12.4-10.1,22.5-22.3,22.5h-12.5v23.5c0,1-0.9,1.9-1.9,1.9h-9.2c-1.1,0-1.9-0.9-1.9-1.9V32.4h24.6c5.6,0,10.3-4.5,10.3-10.4
                                            c0-5.5-4.7-9.7-10.3-9.7H137.3z M71.6,58.2v9.9c0,1,0.8,1.9,1.9,1.9h24.2c12.2,0,21.8-8.1,21.8-19.3c0-9.2-7.7-14.6-13-16.3
                                            c4.7-2.2,11-8.2,11-15.9C117.5,8,108.3,0,96.4,0H73.5c-1.1,0-1.9,0.9-1.9,1.9v10h23.6c5.1,0,8.6,3.5,8.6,8.5s-3.5,9-8.6,9H71.6v11
                                            h23.8c5.2,0,10.2,3.7,10.2,8.7c0,5.2-4,9.1-9,9.1H71.6z M0,12V1.9C0,0.9,0.8,0,1.9,0h28.6c12,0,21.8,9.6,21.8,21.5
                                            c0,9.2-6.1,16.7-14.8,20.2l13.7,25.4c0.7,1.3,0,2.9-1.7,2.9H39c-0.9,0-1.4-0.5-1.6-0.9L24.1,42.6H13v25.5c0,1-0.9,1.9-1.9,1.9H1.9
                                            C0.8,70,0,69.1,0,68.1V31.9h29.4c5.3,0,9.9-4.6,9.9-10.2c0-5.3-4.6-9.7-9.9-9.7H0z"
                                        />
                                    </g>
                                </svg>
                                <div className="title">REACTIVE BETTING PLATFORM</div>
                            </div>
                            <p className="shortDesc">
                                Product designers + architects + developers + mathematicians + statisticians + QAs +
                                DevOps + 150 years of joined industry expertise = cutting-edge agile iGaming solutions
                                with unparalleled performance.
                            </p>
                            <div className="btnWrapper">
                                <button onClick={show}>Get In Touch</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section" id="startProducts">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-4">
                            <div className="productList">
                                <div className="imageWrap">
                                    <img src={customerImg} alt="Logo" />
                                </div>
                                <h3>Platform</h3>
                                <p>
                                    A turnkey scalable and modular solution for 360˚ management of your iGaming
                                    business.
                                </p>
                                <Link className="dropDownMenue_links" activeClassName="active" to="/products/platform">
                                    <div className="button">Learn More</div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-xs-4">
                            <div className="productList">
                                <div className="imageWrap">
                                    <img src={sportbookImg} alt="Logo" />
                                </div>
                                <h3>Sportsbook</h3>
                                <p>
                                    AI powered navigation, advanced match overview, live statistics, bespoke Bet Matrix,
                                    live cashouts, market analytics and more!
                                </p>
                                <div className="btnWrapper">
                                    <Link
                                        className="dropDownMenue_links"
                                        activeClassName="active"
                                        to="/products/sportsbook">
                                        <div className="button">Learn More</div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-4">
                            <div className="productList">
                                <div className="imageWrap">
                                    <img src={casinoImg} alt="Logo" />
                                </div>
                                <h3>Casino</h3>
                                <p>
                                    Casino and Live Casino games from world leading providers with a cutting-edge
                                    back-end technology for maximum performance.
                                </p>
                                <div className="btnWrapper">
                                    <Link
                                        className="dropDownMenue_links"
                                        activeClassName="active"
                                        to="/products/casino">
                                        <div className="button">Discover</div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Start;
